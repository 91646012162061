import "../CSS/Normalnput.css"
import React,{useState, Component} from "react";
import Chexbox from "./Chexbox";

class InputSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Val: "",
            Anim: false,
            load: "",
            err: undefined,
            Filtres: false,
            fitres: [
                {
                    Text: "Fakutra",
                    ID: 1,
                    Checked: false
                }
            ]
        }
        this.wrapper = React.createRef()
        this.InputRef = React.createRef()
        this.HandleClick = this.HandleOutside.bind(this)
        this.state.err = this.props.err
    }

    HandleOutside(event) {
        if (this.wrapper && !this.wrapper.current.contains(event.target)) {
            this.state.Filtres = false
            this.forceUpdate()
        }
    }

    componentDidMount() {
        if (this.props.Filtres !== undefined) {
            this.state.fitres = []
            this.props.Filtres.forEach(e => {
                this.state.fitres.push({
                    ...e,
                    Checked: false
                })
            })
        }
        document.addEventListener("mousedown", this.HandleClick)
    }

    componentWillUnmount() {
        document.removeEventListener("mouseleave", this.HandleClick)
    }

    TextChange(val) {
        this.state.Val = val
        this.state.Anim = val !== ""
        this.state.load = "InputNormal_Box_DeActive"
        this.forceUpdate()
    }

    StartSerach(event) {
        const MakeQueryFiltres = () => {
            let id = ""
            this.state.fitres.forEach(
                e => {
                    if (e.Checked) id += e.ID +"_"
                }
            )
            return id.slice(0, -1)
        }
        let id = MakeQueryFiltres()
        if (event.key == "Enter" && id !== "") {
            this.state.Filtres = false
            if (this.InputRef.current !== null) this.state.Val = this.InputRef.current.value
            if (this.props.OnSearch !== undefined) this.props.OnSearch(this.state.Val, id)
            console.log("Output", this.state.Val)
            this.forceUpdate()
        }
    }

    LoadTextChange(val) {
        if (val !== "") this.TextChange(val)
    }

    ClickShow() {
        this.state.Filtres = true
        this.forceUpdate()
    }

    ShowFiltres() {
        return (
            <div className="InputNormal_Box_Search_filtres_box">
                {
                    (
                        () => {
                            const tab = []
                            this.state.fitres.forEach(
                                e => {
                                    tab.push(
                                        <div>
                                            <Chexbox.CheckboxBt checked={e.Checked} onChange={d => e.Checked = d.target.checked} className="InputNormal_Box_Search_filtres_box_checkbox" text={e.Text}/>
                                        </div>
                                    )
                                }
                            )
                            return tab
                        }
                    )()
                }
            </div>
        )
    }

    render() {
        return (
            <div ref={this.wrapper} style={this.props.style} className={"InputNormal_Box InputNormal_Box_Search " + this.props.className }>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_89_1106)">
                    <path d="M26.0937 24.3253L21.0937 19.3193C20.9318 19.1533 20.7515 19.0063 20.5562 18.8813L19.3062 18.0177C21.8774 14.8325 21.9013 10.2887 19.3637 7.07656C16.8262 3.86439 12.4045 2.84105 8.71637 4.61239C5.02825 6.38373 3.05804 10.477 3.97216 14.4689C4.88628 18.4607 8.44076 21.2858 12.5312 21.2716C14.5163 21.2722 16.4426 20.597 17.9937 19.3568L18.9312 20.6083C19.0424 20.7695 19.168 20.9203 19.3062 21.0589L24.3062 26.0649C24.4236 26.1834 24.5833 26.25 24.75 26.25C24.9166 26.25 25.0764 26.1834 25.1937 26.0649L26.0687 25.1888C26.306 24.9539 26.317 24.5737 26.0937 24.3253ZM12.5312 18.7686C9.07947 18.7686 6.28125 15.967 6.28125 12.5111C6.28125 9.05514 9.07947 6.25355 12.5312 6.25355C15.983 6.25355 18.7812 9.05514 18.7812 12.5111C18.7812 14.1707 18.1228 15.7623 16.9507 16.9358C15.7786 18.1093 14.1888 18.7686 12.5312 18.7686Z" fill="#23A287"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_89_1106">
                    <rect width="30" height="30" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                <label className={this.state.Anim ? " InputNormal_Box_Active" : this.state.load}>{ this.state.err !== undefined ? this.state.err  : this.props.text}</label>
                {
                    (this.state.Val === "" && this.state.err != undefined) && <label className={"InputNormal_err_label"}>{this.state.err}</label>            
                }
                <input ref={this.InputRef}  type={this.props.types}
                    onFocus={() => {
                        this.state.err = undefined
                        this.state.Anim = true
                        this.forceUpdate()
                    }}
                    onBlur={e => this.TextChange(e.target.value)}
                    onChange={e => this.LoadTextChange(e.target.value)}
                    onKeyDown={e => this.StartSerach(e)}
                    />
                <button onClick={this.ClickShow.bind(this)}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_95_628)">
                        <path d="M25.8875 4.2625L25.7375 4.1125C25.498 3.88408 25.181 3.75461 24.85 3.75H14.9125C14.2462 3.74947 13.6073 4.01495 13.1375 4.4875L4.11251 13.5125C3.62786 14 3.62786 14.7875 4.11251 15.275L14.725 25.8875C15.2126 26.3722 16 26.3722 16.4875 25.8875L25.5125 16.8625C25.9851 16.3928 26.2505 15.7538 26.25 15.0875V5.15C26.2454 4.81904 26.1159 4.50204 25.8875 4.2625ZM20 12.5C18.6193 12.5 17.5 11.3807 17.5 10C17.5 8.61929 18.6193 7.5 20 7.5C21.3807 7.5 22.5 8.61929 22.5 10C22.5 10.663 22.2366 11.2989 21.7678 11.7678C21.2989 12.2366 20.6631 12.5 20 12.5Z" fill="#23A287"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_95_628">
                        <rect width="30" height="30" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    filtry
                </button>
                {
                    this.state.Filtres && this.ShowFiltres()
                }
            </div>
        )
    }

}

export default InputSearch