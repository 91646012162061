import React from 'react';
import { Page, Text, View, Document, StyleSheet, usePDF, Image, Font} from '@react-pdf/renderer';
import imageMabra from '../Images/image1.png'
import Buttons from '../Inputs/Buttons';
import Poppins from "../Fonts/Poppins-Regular.ttf";
import PoppinsBold from "../Fonts/Poppins-Bold.ttf";

Font.register({ family: 'Poppins', src: Poppins , format: "truetype"});
Font.register({ family: 'PoppinsBold', src: PoppinsBold, format: "truetype"});

const styles = StyleSheet.create({
    page: {

        flexDirection: 'column',
        backgroundColor: '#fff',
        alignContent: "center"
    },
    Logo: {
        margin: "0 auto",
        width: "200px",
        height: "60px",
        flexGrow: 1
    },
    Section: {
        fontFamily: "Poppins",
        margin: "0 auto",
        borderBottom: "1px black solid",
        fontSize: "20px"
    },
    Table: {
        width: "85%",
        display: "table",
        // margin: "0 auto",
        // flexDirection: "row",
        // justifyContent: "center",
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        fontFamily: "Poppins",
        margin: "20px auto 30px auto",
        // left: "50%",
        // transform: "translate(-50%, 0)",
        // position: "relative",
        fontSize: '12px'
    },
    TableRow: {
        display: "flex",
        flexDirection: "row",
        margin: "auto",
    },
    TableData: {
        width: "20%",
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    TableDatas: {
        textAlign: "center",
        
    }
});

const MarbaPage = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <Image 
                    style={styles.Logo}
                    src={imageMabra}
                /> 
            </View>
            <View>
                <Text style={styles.Section}>Potwierdzenie wysłania dokumentu</Text>
            </View>
            <View style={{position: "relative"}}>
                <View style={styles.Table}>
                    <View style={styles.TableRow}>
                        <View style={[styles.TableData, {backgroundColor: '#d9d9d9'}]}>
                            <Text style={styles.TableDatas}>Numer dokumentu</Text>
                        </View>
                        <View style={[styles.TableData, {backgroundColor: '#d9d9d9'}]}>
                            <Text style={styles.TableDatas}>Wartość dokumentu</Text>
                        </View>
                        <View style={[styles.TableData, {backgroundColor: '#d9d9d9'}]}>
                            <Text style={styles.TableDatas}>Waluta</Text>
                        </View>
                        <View style={[styles.TableData, {backgroundColor: '#d9d9d9'}]}>
                            <Text style={styles.TableDatas}>Użytkownik</Text>
                        </View>
                        <View style={[styles.TableData, {backgroundColor: '#d9d9d9'}]}>
                            <Text style={styles.TableDatas}>Data wysłania</Text>
                        </View>
                    </View>
                    <View style={styles.TableRow}>
                        <View style={styles.TableData}>
                            <Text style={styles.TableDatas}>{props.data.NumberInovice}</Text>
                        </View>
                        <View style={styles.TableData}>
                            <Text style={styles.TableDatas}>{props.data.GrossAmountInovice}</Text>
                        </View>
                        <View style={styles.TableData}>
                            <Text style={styles.TableDatas}>{props.data.CurrencyInovice}</Text>
                        </View>
                        <View style={styles.TableData}>
                            <Text style={styles.TableDatas}>{props.data.Name + " " +  props.data.Surrname}</Text>
                        </View>
                        <View style={styles.TableData}>
                            <Text style={styles.TableDatas}>{props.data.Added.split(" ")[0]}</Text>
                        </View>
                    </View>
                </View>
                <Text
                    style={{
                        
                        left: "10%",
                        fontSize: "12px",
                        fontFamily: "PoppinsBold"
                    }}
                >Satus obiegu: poprawny</Text>
            </View>
            <View
                style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%"
                }}
            >
                <Text
                    style={{
                        margin: "0 auto",
                        fontSize: "10px",
                        fontFamily: "PoppinsBold",
                    }}  
                >
                    Dokument generowany poprzez zewnętrzny system obiegu dokumentów MARBA faktury.emarba.com
                </Text>
            </View>
        </Page>
    </Document>
)

const ShowConfirmation = (props) => {       
    
    // if (instance.loading) console.log("loading")
    // else console.log("nie loading", instance.error)
    // console.log('b')
    // if (instance.error) console.log("errr.")
    // console.log(instance.url)
    const [instance, updateInstance] = usePDF({ document: <MarbaPage data={props.data}/> });
    return (
        <Buttons.Redbutton 
            onClick={() => {
            // console.log(1)
                console.log(instance.url)
                window.open(instance.url, "_blank")
            }} 
            style={{
                position: "relative", float: "right", padding: "0 15px 0 15px"
            }} 
            text={"Potwierdzenie wysłania"}
        />
    )
}

export default ShowConfirmation