import React, {Component} from "react";
import InputNormal from "../Inputs/InputNormal";
import Chexbox from "../Inputs/Chexbox";
import Buttons from "../Inputs/Buttons";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {Link} from "react-router-dom";
import Validate from "../Utilis/Validate";
import serverPath from "../Utilis/ServerPath";
import GusApi from "../Utilis/SoapGUS";

import "../CSS/register.css"

class RegisterPage extends Component {

    constructor(props) {
        super()
        this.state = {
            "Step": 1,
            Output: {
                Login: '',
                Password: '',
                rPassword: '',
                Name: '',
                Surrname: '',
                Email: '',
                NIP: '',
                CompanyName: '',
                CompanyRoad: '',
                CompanyRoadNumber: '',
                CompanyCity: '',
                CompanyZipCode: '',
                CompanyRoad: ''
            },
            Page_1_err: {
                Name: React.createRef(),
                Surrname: React.createRef(),
                Login: React.createRef(),
                Email: React.createRef(),
                Password: React.createRef(),
                rPassword: React.createRef(),
                Agreed: false,
            },
            Page_2_err: {
                NIP: React.createRef(),
                CompanyName: React.createRef(),
                CompanyRoad: React.createRef(),
                CompanyRoadNumber: React.createRef(),
                CompanyCity: React.createRef(),
                CompanyZipCode: React.createRef(),
            },
        }

    }

    async CheckNipFromGUS() {
        const isNip = (Number) => {
            try {
                if (Number.toString().length >= 10) {
                    let x = parseInt(Number)
                    return true
                } else return false
            } catch (E) {
                return false
            }
        }
        // alert("Output")
        if (isNip(this.state.Output.NIP)) {
            let apig = new GusApi()
            let Output = true
            await apig.DoLogin().then(
                () => {
                    apig.DoSerach(this.state.Output.NIP).then(
                        data => {
                            console.log(data)
                            if (data.Miejscowosc !== undefined && data.KodPocztowy !== undefined && data.Nazwa !== undefined) {
                                Output = true
                            } else Output = false
                            // alert(Output)
                            // console.log(this.state.Company)
                            apig.DoLogout()
                        }
                    )
                }
            )
            
            return Output
        } else return false

    }

    DownloadFromGUS() {
        const isNip = (Number) => {
            try {
                if (Number.toString().length >= 10) {
                    let x = parseInt(Number)
                    return true
                } else return false
            } catch (E) {
                return false
            }
        }
        if (isNip(this.state.Output.NIP)) {
            let apig = new GusApi()
            apig.DoLogin().then(
                () => {
                    apig.DoSerach(this.state.Output.NIP).then(
                        data => {
                        
                            if (data.Miejscowosc !== undefined && data.Miejscowosc !== "") {
                                this.state.Output.CompanyCity = data.Miejscowosc
                                if (this.state.Page_2_err.CompanyCity.current !== undefined) {
                                    this.state.Page_2_err.CompanyCity.current.state.input.current.value = data.Miejscowosc
                                    this.state.Page_2_err.CompanyCity.current.TextChange(data.Miejscowosc)
                                }
                            }
                            
                            if (data.KodPocztowy !== undefined && data.KodPocztowy !== "") {
                                this.state.Output.CompanyZipCode = data.KodPocztowy
                                if (this.state.Page_2_err.CompanyZipCode.current !== undefined) {
                                    this.state.Page_2_err.CompanyZipCode.current.state.input.current.value = data.KodPocztowy
                                    this.state.Page_2_err.CompanyZipCode.current.TextChange(data.KodPocztowy)
                                }
                            }

                            if (data.NrNieruchomosci !== undefined && data.NrNieruchomosci !== "") {
                                this.state.Output.CompanyRoadNumber = data.NrNieruchomosci
                                if (this.state.Page_2_err.CompanyRoadNumber.current !== undefined) {
                                    this.state.Page_2_err.CompanyRoadNumber.current.state.input.current.value = data.NrNieruchomosci
                                    this.state.Page_2_err.CompanyRoadNumber.current.TextChange(data.NrNieruchomosci)
                                }
                            }

                            if (data.Ulica !== undefined && data.Ulica !== "") {
                                this.state.Output.CompanyRoad = data.Ulica
                                if (this.state.Page_2_err.CompanyRoad.current !== undefined) {
                                    this.state.Page_2_err.CompanyRoad.current.state.input.current.value = data.Ulica
                                    this.state.Page_2_err.CompanyRoad.current.TextChange(data.Ulica)
                                }
                            }

                            if (data.Nazwa !== undefined && data.Nazwa !== "") {
                                this.state.Output.CompanyName = data.Nazwa
                                if (this.state.Page_2_err.CompanyName.current !== undefined) {
                                    this.state.Page_2_err.CompanyName.current.state.input.current.value = data.Nazwa
                                    this.state.Page_2_err.CompanyName.current.TextChange(data.Nazwa)
                                }
                            }
                            this.SaveCompanyData()
                            // this.state.CompanyMode = false
                            this.forceUpdate()
                            // console.log(this.state.Company)
                            apig.DoLogout()
                        }
                    )
                }
            )
            }

    }


    StepText() {
        if (this.state.Step === 1) {
            return <div className={"RegisterBackgroundText"}>
                        <h1>REJESTRACJA</h1>
                        <b>Krok 1 - Proil użytkownika</b>
                        <p>Uzupełnij dane w formularzu, niezbędne do utworzenia konta użytkownika, a następnie przejdź dalej do kolejnego kroku.</p>
                        <b>Login</b>
                        <p>Nazwa może się składać wyłącznie z liter alfabetu łacińskiego oraz cyfr, bez spacji.</p>
                        <b>Hasło</b>
                        <p>Hasło musi zawierać co najmniej 8 znaków. Dozwolone jest stosowanie małych i wielkich liter oraz znaków specialnych.</p>
                    </div>
        } else if (this.state.Step === 2) {
            return <div className={"RegisterBackgroundText"}>
                    <h1>REJESTRACJA</h1>
                    <b>Krok 2 - Dane firmy</b>
                    <p>Uzupełnij formularz rejestracji o dane firmy lub wpisz numer NIP i pobierz potrzebne dane bezpośrenio z bazy danych GUS.</p>
                </div>
        }

    }

    StepForm() {
        if (this.state.Step === 1) {
            return <div className={"RegisterWorkForm"}>
                        <Link to={'/'} className={'RegisterWorkFormCloseBt'}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 3.02143L26.9786 0L15 11.9786L3.02143 0L0 3.02143L11.9786 15L0 26.9786L3.02143 30L15 18.0214L26.9786 30L30 26.9786L18.0214 15L30 3.02143Z" fill="#23A287"/>
                            </svg>
                            zamknij
                        </Link>
                        <h2>Krok 1</h2>
                        <h1>Profil użytkownika</h1>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "65px"}} Value={this.state.Output.Name} ref={this.state.Page_1_err.Name} onChange={e => this.state.Output.Name = e} text={"imie"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "27px"}} Value={this.state.Output.Surrname} ref={this.state.Page_1_err.Surrname} onChange={e => this.state.Output.Surrname = e} text={"nazwisko"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "27px"}} Value={this.state.Output.Login} ref={this.state.Page_1_err.Login} onChange={e => this.state.Output.Login = e} text={"login"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "27px"}} Value={this.state.Output.Email} ref={this.state.Page_1_err.Email} onChange={e => this.state.Output.Email = e} types={'email'} text={"e-mail"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "27px"}} Value={this.state.Output.Password} ref={this.state.Page_1_err.Password} onChange={e => this.state.Output.Password = e} types={'password'} text={"hasło"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "27px"}} Value={this.state.Output.rPassword} ref={this.state.Page_1_err.rPassword} onChange={e => this.state.Output.rPassword = e} types={'password'} text={"powtórz hasło"}/>
                        <Chexbox.CheckboxBt className="RegisterFormchecbox" style={{marginTop: '30px', color: '#23A287', marginLeft: "25px"}} onChange={e => this.state.Page_1_err.Agreed = e.target.checked} text={<a>akceptuje <u onClick={() => {window.open("https://awizacja.emarba.com/regulamin", "_blank")}}>Regulamin</u> oraz <u onClick={() => {window.open("https://awizacja.emarba.com/polityka", "_blank")}}>Politykę Prywatności</u></a>}/>
                        <Buttons.RedbuttonNext style={{float: 'right', marginTop: '31px'}} onClick={() => {this.StepNext()}} text={"DALEJ"}/>
                    </div>
        } else if (this.state.Step === 2) {
            return <div className={"RegisterWorkForm"}>
                        <Link to={'/'} className={'RegisterWorkFormCloseBt'}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 3.02143L26.9786 0L15 11.9786L3.02143 0L0 3.02143L11.9786 15L0 26.9786L3.02143 30L15 18.0214L26.9786 30L30 26.9786L18.0214 15L30 3.02143Z" fill="#23A287"/>
                            </svg>
                            zamknij
                        </Link>
                        <h2>Krok 2</h2>
                        <h1>Dane firmy</h1>
                        <InputNormal 
                            className={"RegisterFormInputsShort"} 
                            style={{marginTop: "65px"}} 
                            Value={this.state.Output.NIP}
                            ref={this.state.Page_2_err.NIP} 
                            onChange={e => {
                                this.state.Output.NIP = e.replaceAll(/[\s~`!@#$%^&*()\-_=+[\]{}\\|;:'",.<>\/?]+/g, "")
                                if (this.state.Page_2_err.NIP.current) {
                                    this.state.Page_2_err.NIP.current.TextChange(this.state.Output.NIP)
                                    if (this.state.Page_2_err.NIP.current.state.input.current)
                                        this.state.Page_2_err.NIP.current.state.input.current.value = this.state.Output.NIP
                                }
                            }} 
                            text={"NIP"}
                        />
                        <Buttons.Redbutton onClick={this.DownloadFromGUS.bind(this)} className={"RegisterGusBt"} text={"Pobierz dane z GUS"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "37px"}} Value={this.state.Output.CompanyName} ref={this.state.Page_2_err.CompanyName} onChange={e => this.state.Output.CompanyName = e} text={"nazwa"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "37px"}} Value={this.state.Output.CompanyRoad} ref={this.state.Page_2_err.CompanyRoad} onChange={e => this.state.Output.CompanyRoad = e} text={"ulica"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "37px"}} Value={this.state.Output.CompanyRoadNumber} ref={this.state.Page_2_err.CompanyRoadNumber} onChange={e => this.state.Output.CompanyRoadNumber = e} text={"numer lokalu"}/>
                        <InputNormal className={"RegisterFormInputsNormal"} style={{marginTop: "37px"}} Value={this.state.Output.CompanyCity} ref={this.state.Page_2_err.CompanyCity} onChange={e => this.state.Output.CompanyCity = e} text={"miasto"}/>
                        <InputNormal  className={"RegisterFormInputsNormal"} style={{marginTop: "37px"}} Value={this.state.Output.CompanyZipCode}  ref={this.state.Page_2_err.CompanyZipCode} onChange={e => this.state.Output.CompanyZipCode = e} text={"kod pocztowy"}/>
                        <button onClick={() => {this.StepPrev()}} className={'RegisterWorkFormBack'}>
                            <svg width="34" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M33.0435 12.1826V14.0574C33.0435 14.5751 32.6785 14.9948 32.2283 14.9948H5.86416L13.1196 23.3192C13.2739 23.4952 13.3607 23.7348 13.3607 23.9847C13.3607 24.2347 13.2739 24.4743 13.1196 24.6503L11.962 25.9627C11.8089 26.1402 11.6006 26.24 11.3832 26.24C11.1658 26.24 10.9574 26.1402 10.8044 25.9627L0.79351 14.4699C0.56412 14.2064 0.4351 13.849 0.434814 13.4762V12.7638C0.438571 12.3918 0.567069 12.0358 0.79351 11.7701L10.8044 0.277288C10.9574 0.0998222 11.1658 0 11.3832 0C11.6006 0 11.8089 0.0998222 11.962 0.277288L13.1196 1.60843C13.273 1.78122 13.3594 2.01773 13.3594 2.26463C13.3594 2.51153 13.273 2.74803 13.1196 2.92082L5.86416 11.2452H19.0462H32.2283C32.6785 11.2452 33.0435 11.6649 33.0435 12.1826Z" fill="#23A287"/>
                            </svg>
                            wstecz
                        </button>
                        <Buttons.Redbutton style={{float: 'right', marginTop: '50px'}} onClick={() => {this.StepNext()}} text={"UTWÓRZ KONTO"}/>
                    </div>
        }
    }

    StepPrev() {
        if (this.state.Step != 1) {
            this.state.Step -= 1
            this.forceUpdate()
        }
    }

    async CheckData() {
        const resp = await fetch(
            serverPath() + "api/register/check", 
            {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify({
                    Login: this.state.Output.Login,
                    Email: this.state.Output.Email,
                })
            }
        ).then(data => data.json()).catch(null)
        
        if (resp !== null) {
            if (resp["CODE"] == "OK") return true
            else return false
        }
        else return false
    }

    async SendData() {
        // console.log(JSON.stringify(this.state.Output))
        const resp = await fetch(
            serverPath() + "api/register", 
            {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify(this.state.Output)
            }
        ).then(data => data.json()).catch(null)
        
        if (resp !== null) return true
        else return false
    }

    StepNext() {
        const page_1 = [
            this.state.Page_1_err.Name,
            this.state.Page_1_err.Surrname,
            this.state.Page_1_err.Login,
            this.state.Page_1_err.Email,
            this.state.Page_1_err.Password,
            this.state.Page_1_err.rPassword
        ]

        const page_2 = [
            this.state.Page_2_err.NIP,
            this.state.Page_2_err.CompanyName,
            this.state.Page_2_err.CompanyRoad,
            this.state.Page_2_err.CompanyRoadNumber,
            this.state.Page_2_err.CompanyCity,
            this.state.Page_2_err.CompanyZipCode
        ]

        const ClearAllInputs = () => {
            // if (this.state.Step == 1) {
                page_1.forEach(element => {
                    element.current.clearInputs()
                });
            // } 
            // else if (this.state.Step == 2) {
            //     page_2.forEach(element => {
            //         element.current.clearInputs()
            //     });
            // }            
        }


        let erorr = false
        if (this.state.Step === 1) {
            page_1.forEach(element => {
                if (element.current.state.Val.replaceAll(" ", "") == "") {
                    element.current.SetError("Pole nie moze byc puste!")
                    erorr = true
                    return
                } else if (element.current.state.Val.indexOf(' ') >= 0) {
                    element.current.SetError("Pole nie moze zawierać spacji!")
                    erorr = true
                    return
                }
            });
            if (erorr) {
                this.forceUpdate()
                return
            }
            if (!Validate.ValidateEmail(this.state.Page_1_err.Email.current.state.Val)) {
                this.state.Page_1_err.Email.current.SetError("Nie poprawny adres e-mail!")
                erorr = true
            }
            if (this.state.Page_1_err.Password.current.state.Val.length < 8) {
                this.state.Page_1_err.Password.current.SetError("Hasło musi mieć co najmniej 8 znaków!")
                erorr = true
            }
            if (erorr) {
                this.forceUpdate()
                return
            }
            if (this.state.Page_1_err.rPassword.current.state.Val !== this.state.Page_1_err.Password.current.state.Val) {
                this.state.Page_1_err.Password.current.SetError("Hasła nie są takie same!")
                this.state.Page_1_err.rPassword.current.SetError("Hasła nie są takie same!")
                erorr = true
            }
            if (erorr) {
                this.forceUpdate()
                return
            }
            if (this.state.Page_1_err.Agreed) {
                this.CheckData().then(
                    e => {
                        if (e) {
                            this.state.Step += 1
                            ClearAllInputs()
                            this.forceUpdate()
                        } else {
                            this.state.Page_1_err.Email.current.SetError("Email bądź login już istnieje!")
                            this.state.Page_1_err.Login.current.SetError("Email bądź login już istnieje!")
                            this.forceUpdate()
                        }
                    }
                )
            }
        } else if (this.state.Step === 2) {
            page_2.forEach(element => {
                if (element.current.state.Val.replaceAll(" ", "") == "") {
                    element.current.SetError("Pole nie moze byc puste!")
                    erorr = true
                    return
                }
            });

            if (erorr) {
                this.forceUpdate()
                return
            }
            if (this.state.Page_2_err.NIP.current.state.Val.length >= 10) {
                try {
                    parseInt(this.state.Page_2_err.NIP.current.state.Val)
                } catch(e) {
                    this.state.Page_2_err.NIP.current.SetError("Nie poprawna struktura numeru NIP!")
                    this.forceUpdate()
                    return
                }
                this.CheckNipFromGUS().then(
                    resp => {
                        if (resp) {
                            this.SendData().then(
                                () => {
                                    this.state.Step += 1
                                    this.forceUpdate()
                                }
                            )
                        } else {
                            // alert(this.state.Page_2_err.NIP.current)
                            this.state.Page_2_err.NIP.current.SetError("Numer NIP nie istnieje!")
                            // this.state.Page_2_err.NIP.current.forceUpdate()
                            this.forceUpdate()
                            return
                        }
                    }
                )
            } else {
                this.state.Page_2_err.NIP.current.SetError("Nie poprawna struktura numeru NIP!")
                this.forceUpdate()
                return
            }
        }   
    }

    render() {

            return (<SimpleBar autoHide={false} style={{width: "100%", height: "100vh"}}>
                <div className={"Register_BackGround"}>
                    {
                        this.state.Step === 3 && <div className={'RegisterBackgroundFinal'}>
                            <svg width="1306" height="1117" viewBox="0 0 1306 1117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.75">
                                    <path opacity="0.090604" d="M127.685 33.5C24.6566 14.3672 -185.181 -193.648 -200.306 -872.647C-215.431 -1551.65 -559.377 -1738.93 -729.459 -1747.7" stroke="white" stroke-width="0.362416"/>
                                    <path opacity="0.0956376" d="M133.101 75.9306C30.7956 53.1933 -171.755 -161.829 -163.51 -840.022C-155.265 -1518.21 -492.553 -1717.29 -662.228 -1732.05" stroke="white" stroke-width="0.38255"/>
                                    <path opacity="0.100671" d="M137.244 118.163C35.7842 91.8488 -159.25 -129.922 -127.708 -806.492C-96.1664 -1483.06 -426.4 -1693.69 -595.46 -1714.43" stroke="white" stroke-width="0.402685"/>
                                    <path opacity="0.105705" d="M140.119 160.153C39.6289 130.294 -147.668 -97.9577 -92.9308 -772.092C-38.194 -1446.23 -360.985 -1668.15 -529.222 -1694.84" stroke="white" stroke-width="0.422819"/>
                                    <path opacity="0.110738" d="M141.737 201.856C42.3378 168.488 -137.01 -65.9703 -59.2075 -736.863C18.5952 -1407.76 -296.373 -1640.7 -463.582 -1673.31" stroke="white" stroke-width="0.442953"/>
                                    <path opacity="0.115772" d="M142.108 243.228C43.9202 206.392 -127.277 -33.9922 -26.5658 -700.841C74.1459 -1367.69 -232.629 -1611.38 -398.605 -1649.88" stroke="white" stroke-width="0.463087"/>
                                    <path opacity="0.120805" d="M141.244 284.225C44.3867 243.965 -118.469 -2.05655 4.96782 -664.069C128.404 -1326.08 -169.817 -1580.23 -334.357 -1624.55" stroke="white" stroke-width="0.483221"/>
                                    <path opacity="0.125839" d="M139.159 324.804C43.7497 281.17 -110.582 29.8043 35.3687 -626.585C181.319 -1282.98 -107.998 -1547.27 -270.901 -1597.37" stroke="white" stroke-width="0.503356"/>
                                    <path opacity="0.130872" d="M135.868 364.922C42.0223 317.966 -103.613 61.5572 64.6132 -588.433C232.839 -1238.42 -47.2344 -1512.55 -208.3 -1568.37" stroke="white" stroke-width="0.52349"/>
                                    <path opacity="0.135906" d="M131.388 404.538C39.2194 354.317 -97.5583 93.17 92.6793 -549.654C282.917 -1192.48 12.4152 -1476.11 -146.615 -1537.58" stroke="white" stroke-width="0.543624"/>
                                    <path opacity="0.14094" d="M125.737 443.609C35.3572 390.184 -92.4122 124.61 119.547 -510.292C331.506 -1145.19 70.8933 -1437.99 -85.9081 -1505.03" stroke="white" stroke-width="0.563758"/>
                                    <path opacity="0.145973" d="M118.934 482.095C30.453 425.531 -88.1678 155.845 145.197 -470.39C378.563 -1096.62 128.144 -1398.24 -26.2366 -1470.76" stroke="white" stroke-width="0.583893"/>
                                    <path opacity="0.151007" d="M111.001 519.955C24.5253 460.321 -84.8177 186.844 169.613 -429.992C424.044 -1046.83 184.111 -1356.89 32.3416 -1434.82" stroke="white" stroke-width="0.604027"/>
                                    <path opacity="0.15604" d="M101.959 557.151C17.5944 494.519 -82.3524 217.574 192.779 -389.145C467.91 -995.864 238.745 -1314.01 89.7709 -1397.24" stroke="white" stroke-width="0.624161"/>
                                    <path opacity="0.161074" d="M91.8328 593.643C9.68081 528.089 -80.7626 248.005 214.679 -347.893C510.121 -943.791 291.991 -1269.64 145.996 -1358.08" stroke="white" stroke-width="0.644295"/>
                                    <path opacity="0.166107" d="M80.6469 629.394C0.807327 560.997 -80.0368 278.106 235.303 -306.282C550.644 -890.67 343.803 -1223.83 200.965 -1317.36" stroke="white" stroke-width="0.66443"/>
                                    <path opacity="0.171141" d="M68.4276 664.367C-9.00286 593.21 -80.1631 307.845 254.64 -264.359C589.443 -836.563 394.132 -1176.64 254.627 -1275.15" stroke="white" stroke-width="0.684564"/>
                                    <path opacity="0.176174" d="M55.2025 698.526C-19.725 624.696 -81.1282 337.194 272.679 -222.17C626.487 -781.533 442.934 -1128.12 306.931 -1231.49" stroke="white" stroke-width="0.704698"/>
                                    <path opacity="0.181208" d="M41.0003 731.837C-31.3335 655.422 -82.918 366.123 289.415 -179.762C661.747 -725.646 490.165 -1078.33 357.832 -1186.44" stroke="white" stroke-width="0.724832"/>
                                    <path opacity="0.186242" d="M25.8512 764.265C-43.8012 685.359 -85.5169 394.602 304.84 -137.182C695.197 -668.966 535.783 -1027.33 407.282 -1140.03" stroke="white" stroke-width="0.744966"/>
                                    <path opacity="0.191275" d="M9.78607 795.777C-57.1006 714.476 -88.909 422.603 318.951 -94.4777C726.811 -611.559 579.751 -975.168 455.239 -1092.34" stroke="white" stroke-width="0.765101"/>
                                    <path opacity="0.196309" d="M-7.16282 826.343C-71.2026 742.744 -93.0768 450.099 331.745 -51.6961C756.567 -553.491 622.031 -921.918 501.66 -1043.41" stroke="white" stroke-width="0.785235"/>
                                    <path opacity="0.201342" d="M-24.9621 855.931C-86.0775 770.137 -98.0023 477.062 343.222 -8.88476C784.446 -494.832 662.588 -867.637 546.505 -993.296" stroke="white" stroke-width="0.805369"/>
                                    <path opacity="0.206376" d="M-43.5776 884.512C-101.695 796.626 -103.666 503.466 353.382 33.9094C810.43 -435.647 701.39 -812.387 589.738 -942.063" stroke="white" stroke-width="0.825503"/>
                                    <path opacity="0.211409" d="M-62.9738 912.058C-118.022 822.187 -110.049 529.284 362.228 76.6387C834.505 -376.007 738.406 -756.234 631.322 -889.767" stroke="white" stroke-width="0.845638"/>
                                    <path opacity="0.216443" d="M-83.1144 938.542C-135.027 846.795 -117.129 554.493 369.764 119.257C856.656 -315.979 773.609 -699.242 671.224 -836.469" stroke="white" stroke-width="0.865772"/>
                                    <path opacity="0.221477" d="M-103.962 963.939C-152.676 870.427 -124.884 579.066 375.996 161.716C876.875 -255.634 806.973 -641.478 709.412 -782.231" stroke="white" stroke-width="0.885906"/>
                                    <path opacity="0.22651" d="M-125.479 988.225C-170.936 893.061 -133.293 602.982 380.931 203.97C895.155 -195.041 838.475 -583.007 745.858 -727.113" stroke="white" stroke-width="0.90604"/>
                                    <path opacity="0.231544" d="M-147.627 1011.38C-189.771 914.676 -142.331 626.216 384.578 245.973C911.488 -134.27 868.094 -523.897 780.533 -671.18" stroke="white" stroke-width="0.926175"/>
                                    <path opacity="0.236577" d="M-170.365 1033.37C-209.145 935.252 -151.975 648.748 386.949 287.679C925.874 -73.3896 895.811 -464.216 813.414 -614.496" stroke="white" stroke-width="0.946309"/>
                                    <path opacity="0.241611" d="M-193.654 1054.19C-229.023 954.772 -162.199 670.557 388.056 329.043C938.311 -12.4701 921.61 -404.032 844.479 -557.124" stroke="white" stroke-width="0.966443"/>
                                    <path opacity="0.246644" d="M-217.452 1073.81C-249.368 973.217 -172.978 691.621 387.912 370.02C948.801 48.4184 945.479 -343.416 873.706 -499.132" stroke="white" stroke-width="0.986577"/>
                                    <path opacity="0.251678" d="M-241.717 1092.23C-270.141 990.572 -184.285 711.922 386.533 410.565C957.351 109.207 967.404 -282.434 901.078 -440.585" stroke="white" stroke-width="1.00671"/>
                                    <path opacity="0.256711" d="M-266.408 1109.41C-291.306 1006.82 -196.094 731.442 383.935 450.634C963.965 169.826 987.378 -221.158 926.581 -381.549" stroke="white" stroke-width="1.02685"/>
                                    <path opacity="0.261745" d="M-291.482 1125.36C-312.823 1021.96 -208.376 750.164 380.139 490.186C968.654 230.208 1005.39 -159.657 950.199 -322.092" stroke="white" stroke-width="1.04698"/>
                                    <path opacity="0.266779" d="M-316.895 1140.05C-334.654 1035.96 -221.104 768.071 375.163 529.177C971.43 290.283 1021.45 -98.0001 971.924 -262.28" stroke="white" stroke-width="1.06711"/>
                                    <path opacity="0.271812" d="M-342.604 1153.47C-356.759 1048.83 -234.249 785.147 369.029 567.566C972.307 349.985 1035.54 -36.258 991.746 -202.182" stroke="white" stroke-width="1.08725"/>
                                    <path opacity="0.276846" d="M-368.565 1165.62C-379.099 1060.54 -247.781 801.38 361.76 605.313C971.301 409.245 1047.67 25.5001 1009.66 -141.864" stroke="white" stroke-width="1.10738"/>
                                    <path opacity="0.281879" d="M-394.734 1176.48C-401.634 1071.11 -261.671 816.755 353.381 642.377C968.432 467.999 1057.84 87.2045 1025.66 -81.3953" stroke="white" stroke-width="1.12752"/>
                                    <path opacity="0.286913" d="M-421.066 1186.06C-424.324 1080.51 -275.888 831.261 343.916 678.72C963.72 526.178 1066.06 148.786 1039.75 -20.8428" stroke="white" stroke-width="1.14765"/>
                                    <path opacity="0.291946" d="M-447.516 1194.34C-447.128 1088.74 -290.402 844.886 333.394 714.304C957.19 583.721 1072.33 210.176 1051.92 39.7256" stroke="white" stroke-width="1.16779"/>
                                    <path opacity="0.29698" d="M-474.04 1201.32C-470.006 1095.8 -305.183 857.621 321.842 749.091C948.866 640.562 1076.66 271.305 1062.19 100.243" stroke="white" stroke-width="1.18792"/>
                                    <path opacity="0.302013" d="M-500.592 1207.01C-492.918 1101.69 -320.197 869.457 309.29 783.048C938.776 696.639 1079.08 332.106 1070.55 160.641" stroke="white" stroke-width="1.20805"/>
                                    <path opacity="0.307047" d="M-527.128 1211.39C-515.822 1106.41 -335.415 880.386 295.768 816.138C926.952 751.89 1079.59 392.511 1077.01 220.853" stroke="white" stroke-width="1.22819"/>
                                    <path opacity="0.312081" d="M-553.602 1214.47C-538.679 1109.96 -350.804 890.401 281.31 848.328C913.424 806.255 1078.21 452.455 1081.59 280.814" stroke="white" stroke-width="1.24832"/>
                                    <path opacity="0.317114" d="M-579.97 1216.26C-561.447 1112.33 -366.332 899.497 265.948 879.586C898.228 859.674 1074.97 511.869 1084.3 340.456" stroke="white" stroke-width="1.26846"/>
                                    <path opacity="0.322148" d="M-606.186 1216.76C-584.087 1113.55 -381.967 907.67 249.716 909.88C881.398 912.09 1069.87 570.691 1085.15 399.715" stroke="white" stroke-width="1.28859"/>
                                    <path opacity="0.327181" d="M-632.207 1215.97C-606.558 1113.6 -397.677 914.917 232.649 939.182C862.975 963.446 1062.96 628.855 1084.16 458.526" stroke="white" stroke-width="1.30872"/>
                                    <path opacity="0.332215" d="M-657.988 1213.91C-628.819 1112.5 -413.429 921.236 214.784 967.462C842.997 1013.69 1054.25 686.298 1081.35 516.825" stroke="white" stroke-width="1.32886"/>
                                    <path opacity="0.337248" d="M-683.484 1210.58C-650.832 1110.25 -429.191 926.625 196.158 994.694C821.507 1062.76 1043.77 742.958 1076.73 574.548" stroke="white" stroke-width="1.34899"/>
                                    <path opacity="0.342282" d="M-708.653 1205.99C-672.557 1106.87 -444.93 931.086 176.809 1020.85C798.549 1110.62 1031.56 798.775 1070.35 631.634" stroke="white" stroke-width="1.36913"/>
                                    <path opacity="0.347315" d="M-733.45 1200.15C-693.954 1102.37 -460.615 934.619 156.776 1045.91C774.167 1157.2 1017.64 853.688 1062.21 688.021" stroke="white" stroke-width="1.38926"/>
                                    <path opacity="0.352349" d="M-757.833 1193.09C-714.985 1096.75 -476.211 937.227 136.099 1069.85C748.41 1202.47 1002.06 907.639 1052.35 743.649" stroke="white" stroke-width="1.4094"/>
                                    <path opacity="0.357383" d="M-781.76 1184.81C-735.612 1090.03 -491.688 938.915 114.819 1092.64C721.326 1246.37 984.846 960.571 1040.79 798.459" stroke="white" stroke-width="1.42953"/>
                                    <path opacity="0.362416" d="M-805.189 1175.32C-755.796 1082.23 -507.013 939.686 92.976 1114.27C692.966 1288.86 966.039 1012.43 1027.58 852.392" stroke="white" stroke-width="1.44966"/>
                                    <path opacity="0.36745" d="M-828.078 1164.66C-775.501 1073.36 -522.155 939.547 70.6128 1134.72C663.381 1329.89 945.682 1063.16 1012.74 905.393" stroke="white" stroke-width="1.4698"/>
                                    <path opacity="0.372483" d="M-850.387 1152.84C-794.689 1063.44 -537.081 938.506 47.7717 1153.97C632.624 1369.44 923.815 1112.7 996.304 957.405" stroke="white" stroke-width="1.48993"/>
                                    <path opacity="0.377517" d="M-872.076 1139.88C-813.326 1052.49 -551.76 936.569 24.4953 1172.01C600.751 1407.45 900.483 1161.02 978.317 1008.37" stroke="white" stroke-width="1.51007"/>
                                    <path opacity="0.38255" d="M-893.107 1125.8C-831.374 1040.53 -566.162 933.748 0.828015 1188.82C567.818 1443.88 875.733 1208.05 958.817 1058.25" stroke="white" stroke-width="1.5302"/>
                                    <path opacity="0.387584" d="M-913.44 1110.63C-848.801 1027.57 -580.255 930.052 -23.1874 1204.39C533.88 1478.72 849.611 1253.75 937.843 1106.98" stroke="white" stroke-width="1.55034"/>
                                    <path opacity="0.392617" d="M-933.039 1094.39C-865.571 1013.65 -594.009 925.493 -47.5055 1218.7C498.998 1511.92 822.167 1298.08 915.439 1154.51" stroke="white" stroke-width="1.57047"/>
                                    <path opacity="0.397651" d="M-951.866 1077.1C-881.651 998.79 -607.394 920.083 -72.0821 1231.76C463.229 1543.44 793.452 1340.99 891.65 1200.8" stroke="white" stroke-width="1.5906"/>
                                    <path opacity="0.402685" d="M-969.887 1058.81C-897.01 983.007 -620.381 913.837 -96.8728 1243.56C426.635 1573.28 763.518 1382.44 866.521 1245.8" stroke="white" stroke-width="1.61074"/>
                                    <path opacity="0.407718" d="M-987.066 1039.53C-911.616 966.331 -632.94 906.768 -121.832 1254.08C389.276 1601.38 732.417 1422.38 840.099 1289.47" stroke="white" stroke-width="1.63087"/>
                                    <path opacity="0.412752" d="M-1003.37 1019.29C-925.439 948.789 -645.044 898.894 -146.914 1263.32C351.215 1627.74 700.206 1460.79 812.435 1331.76" stroke="white" stroke-width="1.65101"/>
                                    <path opacity="0.417785" d="M-1018.77 998.131C-938.448 930.409 -656.664 890.23 -172.075 1271.28C312.514 1652.33 666.939 1497.62 783.577 1372.63" stroke="white" stroke-width="1.67114"/>
                                    <path opacity="0.422819" d="M-1033.22 976.081C-950.616 911.221 -667.773 880.794 -197.269 1277.96C273.236 1675.13 632.673 1532.84 753.579 1412.04" stroke="white" stroke-width="1.69128"/>
                                    <path opacity="0.427852" d="M-1046.71 953.173C-961.915 891.254 -678.345 870.605 -222.45 1283.37C233.446 1696.13 597.468 1566.41 722.492 1449.96" stroke="white" stroke-width="1.71141"/>
                                    <path opacity="0.432886" d="M-1059.2 929.444C-972.319 870.539 -688.354 859.683 -247.573 1287.49C193.207 1715.3 561.382 1598.32 690.372 1486.35" stroke="white" stroke-width="1.73154"/>
                                    <path opacity="0.437919" d="M-1070.67 904.927C-981.802 849.109 -697.774 848.048 -272.594 1290.35C152.586 1732.65 524.475 1628.53 657.272 1521.18" stroke="white" stroke-width="1.75168"/>
                                    <path opacity="0.442953" d="M-1081.08 879.66C-990.34 826.996 -706.581 835.721 -297.467 1291.93C111.646 1748.14 486.808 1657.01 623.25 1554.41" stroke="white" stroke-width="1.77181"/>
                                    <path opacity="0.447987" d="M-1090.41 853.68C-997.909 804.234 -714.751 822.725 -322.148 1292.26C70.4536 1761.79 448.443 1683.74 588.363 1586.02" stroke="white" stroke-width="1.79195"/>
                                    <path opacity="0.45302" d="M-1098.65 827.026C-1004.49 780.858 -722.261 809.083 -346.593 1291.33C29.074 1773.57 409.443 1708.7 552.668 1615.99" stroke="white" stroke-width="1.81208"/>
                                    <path opacity="0.458054" d="M-1105.75 799.737C-1010.06 756.903 -729.089 794.82 -370.758 1289.16C-12.4269 1783.5 369.869 1731.88 516.226 1644.27" stroke="white" stroke-width="1.83221"/>
                                    <path opacity="0.463087" d="M-1111.71 771.852C-1014.6 732.403 -735.214 779.957 -394.599 1285.76C-53.9837 1791.57 329.786 1753.25 479.094 1670.86" stroke="white" stroke-width="1.85235"/>
                                    <path opacity="0.468121" d="M-1116.51 743.413C-1018.09 707.398 -740.616 764.524 -418.074 1281.15C-95.5313 1797.77 289.258 1772.8 441.334 1695.73" stroke="white" stroke-width="1.87248"/>
                                    <path opacity="0.473154" d="M-1120.12 714.461C-1020.51 681.923 -745.274 748.544 -441.139 1275.33C-137.003 1802.12 248.349 1790.52 403.007 1718.87" stroke="white" stroke-width="1.89262"/>
                                    <path opacity="0.478188" d="M-1122.53 685.038C-1021.86 656.017 -749.171 732.046 -463.753 1268.33C-178.335 1804.62 207.123 1806.4 364.175 1740.25" stroke="white" stroke-width="1.91275"/>
                                    <path opacity="0.483221" d="M-1123.72 655.186C-1022.11 629.716 -752.289 715.055 -485.875 1260.17C-219.462 1805.28 165.646 1820.43 324.898 1759.86" stroke="white" stroke-width="1.93289"/>
                                    <path opacity="0.488255" d="M-1123.68 624.949C-1021.25 603.062 -754.61 697.601 -507.465 1250.86C-260.32 1804.11 123.982 1832.61 285.24 1777.7" stroke="white" stroke-width="1.95302"/>
                                    <path opacity="0.493289" d="M-1122.39 594.37C-1019.27 576.091 -756.12 679.711 -528.482 1240.42C-300.844 1801.12 82.1967 1842.93 245.262 1793.74" stroke="white" stroke-width="1.97315"/>
                                    <path opacity="0.498322" d="M-1119.86 563.493C-1016.16 548.845 -756.804 661.415 -548.888 1228.87C-340.972 1796.33 40.3546 1851.39 205.028 1807.99" stroke="white" stroke-width="1.99329"/>
                                    <path opacity="0.503356" d="M-1116.05 532.362C-1011.92 521.363 -756.648 642.743 -568.644 1216.25C-380.641 1789.76 -1.47933 1858.01 164.601 1820.44" stroke="white" stroke-width="2.01342"/>
                                    <path opacity="0.508389" d="M-1110.98 501.023C-1006.52 493.686 -755.64 623.725 -587.714 1202.57C-419.788 1781.42 -43.2405 1862.77 124.043 1831.09" stroke="white" stroke-width="2.03356"/>
                                    <path opacity="0.513423" d="M-1104.62 469.519C-999.979 465.854 -753.767 604.39 -606.061 1187.86C-458.354 1771.33 -84.8646 1865.69 83.4167 1839.93" stroke="white" stroke-width="2.05369"/>
                                    <path opacity="0.518456" d="M-1096.98 437.897C-992.276 437.906 -751.021 584.771 -623.649 1172.15C-496.277 1759.53 -126.288 1866.77 42.7858 1846.97" stroke="white" stroke-width="2.07383"/>
                                    <path opacity="0.52349" d="M-1088.06 406.201C-983.413 409.886 -747.39 564.898 -640.444 1155.47C-533.499 1746.04 -167.446 1866.03 2.21237 1852.21" stroke="white" stroke-width="2.09396"/>
                                    <path opacity="0.538591" d="M-1053.54 311.124C-949.85 325.79 -731.119 504.076 -685.748 1099.89C-640.378 1695.7 -288.706 1853.01 -118.541 1857.19" stroke="white" stroke-width="2.15436"/>
                                    <path opacity="0.543624" d="M-1039.47 279.587C-936.341 297.884 -723.882 483.507 -699.052 1079.63C-674.223 1675.74 -328.182 1845.13 -158.265 1855.32" stroke="white" stroke-width="2.1745"/>
                                    <path opacity="0.548658" d="M-1024.11 248.203C-921.677 270.108 -715.731 462.845 -711.409 1058.55C-707.087 1654.25 -367.085 1835.52 -197.624 1851.69" stroke="white" stroke-width="2.19463"/>
                                    <path opacity="0.553691" d="M-1007.48 217.017C-905.862 242.504 -706.664 442.122 -722.791 1036.7C-738.918 1631.27 -405.356 1824.19 -236.559 1846.33" stroke="white" stroke-width="2.21477"/>
                                    <path opacity="0.558725" d="M-989.578 186.073C-888.904 215.111 -696.678 421.371 -733.172 1014.1C-769.665 1606.84 -442.936 1811.18 -275.009 1839.26" stroke="white" stroke-width="2.2349"/>
                                    <path opacity="0.563758" d="M-970.421 155.417C-870.81 187.971 -685.775 400.624 -742.528 990.808C-799.28 1580.99 -479.768 1796.51 -312.917 1830.5" stroke="white" stroke-width="2.25503"/>
                                    <path opacity="0.568792" d="M-950.018 125.092C-851.589 161.121 -673.953 379.914 -750.835 966.846C-827.716 1553.78 -515.795 1780.22 -350.225 1820.07" stroke="white" stroke-width="2.27517"/>
                                    <path opacity="0.573825" d="M-928.379 95.1407C-831.254 134.603 -661.216 359.273 -758.071 942.256C-854.925 1525.24 -550.964 1762.32 -386.877 1807.99" stroke="white" stroke-width="2.2953"/>
                                    <path opacity="0.578859" d="M-905.52 65.6079C-809.815 108.455 -647.567 338.735 -764.216 917.08C-880.864 1495.42 -585.22 1742.87 -422.817 1794.3" stroke="white" stroke-width="2.31544"/>
                                    <path opacity="0.583893" d="M-881.454 36.5355C-787.286 82.715 -633.009 318.33 -769.25 891.356C-905.49 1464.38 -618.51 1721.89 -457.99 1779.02" stroke="white" stroke-width="2.33557"/>
                                    <path opacity="0.588926" d="M-856.2 7.96646C-763.682 57.4226 -617.548 298.093 -773.155 865.125C-928.763 1432.16 -650.783 1699.43 -492.343 1762.18" stroke="white" stroke-width="2.3557"/>
                                    <path opacity="0.59396" d="M-829.774 -20.0582C-739.019 32.6146 -601.19 278.054 -775.917 838.43C-950.643 1398.81 -681.991 1675.51 -525.824 1743.82" stroke="white" stroke-width="2.37584"/>
                                    <path opacity="0.598993" d="M-802.197 -47.4978C-713.314 8.32776 -583.943 258.245 -777.518 811.311C-971.093 1364.38 -712.083 1650.19 -558.381 1723.96" stroke="white" stroke-width="2.39597"/>
                                    <path opacity="0.604027" d="M-773.489 -74.3119C-686.587 -15.4012 -565.816 238.698 -777.948 783.811C-990.079 1328.92 -741.014 1623.5 -589.965 1702.65" stroke="white" stroke-width="2.41611"/>
                                    <path opacity="0.60906" d="M-743.672 -100.461C-658.856 -38.537 -546.818 219.444 -777.193 755.972C-1007.57 1292.5 -768.739 1595.49 -620.528 1679.92" stroke="white" stroke-width="2.43624"/>
                                    <path opacity="0.614094" d="M-712.772 -125.907C-630.144 -61.0444 -526.96 200.513 -775.243 727.839C-1023.53 1255.16 -795.214 1566.21 -650.022 1655.81" stroke="white" stroke-width="2.45638"/>
                                    <path opacity="0.619128" d="M-680.811 -150.612C-600.472 -82.8897 -506.253 181.935 -772.09 699.453C-1037.93 1216.97 -820.397 1535.69 -678.403 1630.37" stroke="white" stroke-width="2.47651"/>
                                    <path opacity="0.624161" d="M-647.817 -174.538C-569.865 -104.038 -484.712 163.742 -767.726 670.86C-1050.74 1177.98 -844.248 1504 -705.625 1603.62" stroke="white" stroke-width="2.49664"/>
                                    <path opacity="0.629195" d="M-613.818 -197.652C-538.345 -124.459 -462.35 145.961 -762.147 642.102C-1061.94 1138.24 -866.729 1471.18 -731.647 1575.63" stroke="white" stroke-width="2.51678"/>
                                    <path opacity="0.634228" d="M-578.841 -219.916C-505.94 -144.12 -439.181 128.623 -755.347 613.224C-1071.51 1097.83 -887.804 1437.28 -756.428 1546.43" stroke="white" stroke-width="2.53691"/>
                                    <path opacity="0.639262" d="M-542.917 -241.297C-472.677 -162.99 -415.223 111.755 -747.326 584.27C-1079.43 1056.79 -907.437 1402.35 -779.928 1516.07" stroke="white" stroke-width="2.55705"/>
                                    <path opacity="0.644295" d="M-506.078 -261.763C-438.583 -181.039 -390.491 95.3849 -738.08 555.284C-1085.67 1015.18 -925.596 1366.45 -802.111 1484.59" stroke="white" stroke-width="2.57718"/>
                                    <path opacity="0.649329" d="M-468.354 -281.283C-403.687 -198.239 -365.004 79.5397 -727.612 526.31C-1090.22 973.08 -942.251 1329.63 -822.94 1452.05" stroke="white" stroke-width="2.59732"/>
                                    <path opacity="0.654362" d="M-429.78 -299.824C-368.018 -214.563 -338.781 64.2464 -715.924 497.392C-1093.07 930.537 -957.372 1291.94 -842.382 1418.5" stroke="white" stroke-width="2.61745"/>
                                    <path opacity="0.659396" d="M-390.39 -317.359C-331.609 -229.983 -311.841 49.5303 -703.018 468.573C-1094.2 887.616 -970.933 1253.45 -860.404 1383.99" stroke="white" stroke-width="2.63758"/>
                                    <path opacity="0.66443" d="M-350.22 -333.859C-294.49 -244.474 -284.205 35.4162 -688.902 439.897C-1093.6 844.379 -982.909 1214.21 -876.977 1348.56" stroke="white" stroke-width="2.65772"/>
                                    <path opacity="0.669463" d="M-309.304 -349.296C-256.694 -258.011 -255.894 21.9297 -673.581 411.41C-1091.27 800.89 -993.277 1174.27 -892.071 1312.28" stroke="white" stroke-width="2.67785"/>
                                    <path opacity="0.674497" d="M-267.682 -363.647C-218.254 -270.572 -226.932 9.09284 -657.063 383.151C-1087.19 757.209 -1002.02 1133.7 -905.661 1275.2" stroke="white" stroke-width="2.69799"/>
                                    <path opacity="0.67953" d="M-225.39 -376.886C-179.206 -282.134 -197.341 -3.07126 -639.36 355.165C-1081.38 713.401 -1009.11 1092.56 -917.722 1237.36" stroke="white" stroke-width="2.71812"/>
                                    <path opacity="0.684564" d="M-182.468 -388.99C-139.582 -292.676 -167.144 -14.5397 -620.481 327.494C-1073.82 669.528 -1014.54 1050.9 -928.231 1198.84" stroke="white" stroke-width="2.73826"/>
                                    <path opacity="0.689597" d="M-138.955 -399.939C-99.4201 -302.18 -136.368 -25.2931 -600.44 300.179C-1064.51 625.652 -1018.29 1008.79 -937.168 1159.68" stroke="white" stroke-width="2.75839"/>
                                    <path opacity="0.694631" d="M-94.8922 -409.713C-58.7556 -310.627 -105.036 -35.3115 -579.252 273.262C-1053.47 581.835 -1020.35 966.285 -944.514 1119.94" stroke="white" stroke-width="2.77852"/>
                                    <path opacity="0.699664" d="M-50.3198 -418.291C-17.6255 -317.998 -73.1759 -44.5732 -556.932 246.784C-1040.69 538.141 -1020.71 923.445 -950.252 1079.68" stroke="white" stroke-width="2.79866"/>
                                    <path opacity="0.704698" d="M-5.27969 -425.657C23.9326 -324.279 -40.8136 -53.062 -533.497 220.785C-1026.18 494.632 -1019.36 880.333 -954.369 1038.95" stroke="white" stroke-width="2.81879"/>
                                    <path opacity="0.709732" d="M40.1858 -431.796C65.8808 -329.456 -7.97663 -60.7598 -508.966 195.304C-1009.96 451.368 -1016.3 837.01 -956.852 997.822" stroke="white" stroke-width="2.83893"/>
                                    <path opacity="0.714765" d="M86.0341 -436.694C108.181 -333.515 25.3071 -67.6514 -483.359 170.379C-992.025 408.41 -1011.52 793.536 -957.69 956.345" stroke="white" stroke-width="2.85906"/>
                                    <path opacity="0.719799" d="M132.222 -440.337C150.793 -336.446 59.0092 -73.7208 -456.697 146.049C-972.403 365.819 -1005.03 749.974 -956.875 914.58" stroke="white" stroke-width="2.87919"/>
                                    <path opacity="0.724832" d="M178.706 -442.714C193.679 -338.236 93.1003 -78.9534 -429.003 122.351C-951.105 323.656 -996.811 706.384 -954.401 872.585" stroke="white" stroke-width="2.89933"/>
                                    <path opacity="0.729866" d="M225.441 -443.817C236.799 -338.878 127.551 -83.336 -400.3 99.3212C-928.151 281.978 -986.88 662.828 -950.262 830.421" stroke="white" stroke-width="2.91946"/>
                                    <path opacity="0.734899" d="M272.384 -443.636C280.112 -338.364 162.332 -86.8569 -370.614 76.9944C-903.56 240.846 -975.238 619.367 -944.459 788.146" stroke="white" stroke-width="2.9396"/>
                                    <path opacity="0.739933" d="M319.491 -442.165C323.58 -336.687 197.412 -89.5046 -339.971 55.4054C-877.354 200.315 -961.893 576.06 -936.989 745.819" stroke="white" stroke-width="2.95973"/>
                                    <path opacity="0.744966" d="M366.716 -439.4C367.161 -333.844 232.761 -91.2694 -308.399 34.5872C-849.559 160.444 -946.854 532.969 -927.856 703.499" stroke="white" stroke-width="2.97987"/>
                                    <path opacity="0.75" d="M414.014 -435.337C410.815 -329.831 268.348 -92.1423 -275.926 14.5722C-820.2 121.287 -930.131 490.152 -917.063 661.245" stroke="white" stroke-width="3"/>
                                </g>
                            </svg>
                            <h1>DZIEKUJEMY</h1>
                            <h2>Krok 3 - poczekaj na wiadomość</h2>
                            <p>
                                Rejestracja przebiegła pomyślnie. Poczekaj na wiadomość e-mail z potwierdzeniem rejestracji.
                            </p>
                            <Buttons.DarkGreenLink style={{marginTop: '39px'}} to={'/'} text={'ZALOGUJ'}/>
                        </div>
                     }
                    {
                        this.state.Step !== 3 && <div className={"RegisterBackground"}>
                            <svg width="1306" height="1117" viewBox="0 0 1306 1117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.75">
                                    <path opacity="0.090604" d="M127.685 33.5C24.6566 14.3672 -185.181 -193.648 -200.306 -872.647C-215.431 -1551.65 -559.377 -1738.93 -729.459 -1747.7" stroke="white" stroke-width="0.362416"/>
                                    <path opacity="0.0956376" d="M133.101 75.9306C30.7956 53.1933 -171.755 -161.829 -163.51 -840.022C-155.265 -1518.21 -492.553 -1717.29 -662.228 -1732.05" stroke="white" stroke-width="0.38255"/>
                                    <path opacity="0.100671" d="M137.244 118.163C35.7842 91.8488 -159.25 -129.922 -127.708 -806.492C-96.1664 -1483.06 -426.4 -1693.69 -595.46 -1714.43" stroke="white" stroke-width="0.402685"/>
                                    <path opacity="0.105705" d="M140.119 160.153C39.6289 130.294 -147.668 -97.9577 -92.9308 -772.092C-38.194 -1446.23 -360.985 -1668.15 -529.222 -1694.84" stroke="white" stroke-width="0.422819"/>
                                    <path opacity="0.110738" d="M141.737 201.856C42.3378 168.488 -137.01 -65.9703 -59.2075 -736.863C18.5952 -1407.76 -296.373 -1640.7 -463.582 -1673.31" stroke="white" stroke-width="0.442953"/>
                                    <path opacity="0.115772" d="M142.108 243.228C43.9202 206.392 -127.277 -33.9922 -26.5658 -700.841C74.1459 -1367.69 -232.629 -1611.38 -398.605 -1649.88" stroke="white" stroke-width="0.463087"/>
                                    <path opacity="0.120805" d="M141.244 284.225C44.3867 243.965 -118.469 -2.05655 4.96782 -664.069C128.404 -1326.08 -169.817 -1580.23 -334.357 -1624.55" stroke="white" stroke-width="0.483221"/>
                                    <path opacity="0.125839" d="M139.159 324.804C43.7497 281.17 -110.582 29.8043 35.3687 -626.585C181.319 -1282.98 -107.998 -1547.27 -270.901 -1597.37" stroke="white" stroke-width="0.503356"/>
                                    <path opacity="0.130872" d="M135.868 364.922C42.0223 317.966 -103.613 61.5572 64.6132 -588.433C232.839 -1238.42 -47.2344 -1512.55 -208.3 -1568.37" stroke="white" stroke-width="0.52349"/>
                                    <path opacity="0.135906" d="M131.388 404.538C39.2194 354.317 -97.5583 93.17 92.6793 -549.654C282.917 -1192.48 12.4152 -1476.11 -146.615 -1537.58" stroke="white" stroke-width="0.543624"/>
                                    <path opacity="0.14094" d="M125.737 443.609C35.3572 390.184 -92.4122 124.61 119.547 -510.292C331.506 -1145.19 70.8933 -1437.99 -85.9081 -1505.03" stroke="white" stroke-width="0.563758"/>
                                    <path opacity="0.145973" d="M118.934 482.095C30.453 425.531 -88.1678 155.845 145.197 -470.39C378.563 -1096.62 128.144 -1398.24 -26.2366 -1470.76" stroke="white" stroke-width="0.583893"/>
                                    <path opacity="0.151007" d="M111.001 519.955C24.5253 460.321 -84.8177 186.844 169.613 -429.992C424.044 -1046.83 184.111 -1356.89 32.3416 -1434.82" stroke="white" stroke-width="0.604027"/>
                                    <path opacity="0.15604" d="M101.959 557.151C17.5944 494.519 -82.3524 217.574 192.779 -389.145C467.91 -995.864 238.745 -1314.01 89.7709 -1397.24" stroke="white" stroke-width="0.624161"/>
                                    <path opacity="0.161074" d="M91.8328 593.643C9.68081 528.089 -80.7626 248.005 214.679 -347.893C510.121 -943.791 291.991 -1269.64 145.996 -1358.08" stroke="white" stroke-width="0.644295"/>
                                    <path opacity="0.166107" d="M80.6469 629.394C0.807327 560.997 -80.0368 278.106 235.303 -306.282C550.644 -890.67 343.803 -1223.83 200.965 -1317.36" stroke="white" stroke-width="0.66443"/>
                                    <path opacity="0.171141" d="M68.4276 664.367C-9.00286 593.21 -80.1631 307.845 254.64 -264.359C589.443 -836.563 394.132 -1176.64 254.627 -1275.15" stroke="white" stroke-width="0.684564"/>
                                    <path opacity="0.176174" d="M55.2025 698.526C-19.725 624.696 -81.1282 337.194 272.679 -222.17C626.487 -781.533 442.934 -1128.12 306.931 -1231.49" stroke="white" stroke-width="0.704698"/>
                                    <path opacity="0.181208" d="M41.0003 731.837C-31.3335 655.422 -82.918 366.123 289.415 -179.762C661.747 -725.646 490.165 -1078.33 357.832 -1186.44" stroke="white" stroke-width="0.724832"/>
                                    <path opacity="0.186242" d="M25.8512 764.265C-43.8012 685.359 -85.5169 394.602 304.84 -137.182C695.197 -668.966 535.783 -1027.33 407.282 -1140.03" stroke="white" stroke-width="0.744966"/>
                                    <path opacity="0.191275" d="M9.78607 795.777C-57.1006 714.476 -88.909 422.603 318.951 -94.4777C726.811 -611.559 579.751 -975.168 455.239 -1092.34" stroke="white" stroke-width="0.765101"/>
                                    <path opacity="0.196309" d="M-7.16282 826.343C-71.2026 742.744 -93.0768 450.099 331.745 -51.6961C756.567 -553.491 622.031 -921.918 501.66 -1043.41" stroke="white" stroke-width="0.785235"/>
                                    <path opacity="0.201342" d="M-24.9621 855.931C-86.0775 770.137 -98.0023 477.062 343.222 -8.88476C784.446 -494.832 662.588 -867.637 546.505 -993.296" stroke="white" stroke-width="0.805369"/>
                                    <path opacity="0.206376" d="M-43.5776 884.512C-101.695 796.626 -103.666 503.466 353.382 33.9094C810.43 -435.647 701.39 -812.387 589.738 -942.063" stroke="white" stroke-width="0.825503"/>
                                    <path opacity="0.211409" d="M-62.9738 912.058C-118.022 822.187 -110.049 529.284 362.228 76.6387C834.505 -376.007 738.406 -756.234 631.322 -889.767" stroke="white" stroke-width="0.845638"/>
                                    <path opacity="0.216443" d="M-83.1144 938.542C-135.027 846.795 -117.129 554.493 369.764 119.257C856.656 -315.979 773.609 -699.242 671.224 -836.469" stroke="white" stroke-width="0.865772"/>
                                    <path opacity="0.221477" d="M-103.962 963.939C-152.676 870.427 -124.884 579.066 375.996 161.716C876.875 -255.634 806.973 -641.478 709.412 -782.231" stroke="white" stroke-width="0.885906"/>
                                    <path opacity="0.22651" d="M-125.479 988.225C-170.936 893.061 -133.293 602.982 380.931 203.97C895.155 -195.041 838.475 -583.007 745.858 -727.113" stroke="white" stroke-width="0.90604"/>
                                    <path opacity="0.231544" d="M-147.627 1011.38C-189.771 914.676 -142.331 626.216 384.578 245.973C911.488 -134.27 868.094 -523.897 780.533 -671.18" stroke="white" stroke-width="0.926175"/>
                                    <path opacity="0.236577" d="M-170.365 1033.37C-209.145 935.252 -151.975 648.748 386.949 287.679C925.874 -73.3896 895.811 -464.216 813.414 -614.496" stroke="white" stroke-width="0.946309"/>
                                    <path opacity="0.241611" d="M-193.654 1054.19C-229.023 954.772 -162.199 670.557 388.056 329.043C938.311 -12.4701 921.61 -404.032 844.479 -557.124" stroke="white" stroke-width="0.966443"/>
                                    <path opacity="0.246644" d="M-217.452 1073.81C-249.368 973.217 -172.978 691.621 387.912 370.02C948.801 48.4184 945.479 -343.416 873.706 -499.132" stroke="white" stroke-width="0.986577"/>
                                    <path opacity="0.251678" d="M-241.717 1092.23C-270.141 990.572 -184.285 711.922 386.533 410.565C957.351 109.207 967.404 -282.434 901.078 -440.585" stroke="white" stroke-width="1.00671"/>
                                    <path opacity="0.256711" d="M-266.408 1109.41C-291.306 1006.82 -196.094 731.442 383.935 450.634C963.965 169.826 987.378 -221.158 926.581 -381.549" stroke="white" stroke-width="1.02685"/>
                                    <path opacity="0.261745" d="M-291.482 1125.36C-312.823 1021.96 -208.376 750.164 380.139 490.186C968.654 230.208 1005.39 -159.657 950.199 -322.092" stroke="white" stroke-width="1.04698"/>
                                    <path opacity="0.266779" d="M-316.895 1140.05C-334.654 1035.96 -221.104 768.071 375.163 529.177C971.43 290.283 1021.45 -98.0001 971.924 -262.28" stroke="white" stroke-width="1.06711"/>
                                    <path opacity="0.271812" d="M-342.604 1153.47C-356.759 1048.83 -234.249 785.147 369.029 567.566C972.307 349.985 1035.54 -36.258 991.746 -202.182" stroke="white" stroke-width="1.08725"/>
                                    <path opacity="0.276846" d="M-368.565 1165.62C-379.099 1060.54 -247.781 801.38 361.76 605.313C971.301 409.245 1047.67 25.5001 1009.66 -141.864" stroke="white" stroke-width="1.10738"/>
                                    <path opacity="0.281879" d="M-394.734 1176.48C-401.634 1071.11 -261.671 816.755 353.381 642.377C968.432 467.999 1057.84 87.2045 1025.66 -81.3953" stroke="white" stroke-width="1.12752"/>
                                    <path opacity="0.286913" d="M-421.066 1186.06C-424.324 1080.51 -275.888 831.261 343.916 678.72C963.72 526.178 1066.06 148.786 1039.75 -20.8428" stroke="white" stroke-width="1.14765"/>
                                    <path opacity="0.291946" d="M-447.516 1194.34C-447.128 1088.74 -290.402 844.886 333.394 714.304C957.19 583.721 1072.33 210.176 1051.92 39.7256" stroke="white" stroke-width="1.16779"/>
                                    <path opacity="0.29698" d="M-474.04 1201.32C-470.006 1095.8 -305.183 857.621 321.842 749.091C948.866 640.562 1076.66 271.305 1062.19 100.243" stroke="white" stroke-width="1.18792"/>
                                    <path opacity="0.302013" d="M-500.592 1207.01C-492.918 1101.69 -320.197 869.457 309.29 783.048C938.776 696.639 1079.08 332.106 1070.55 160.641" stroke="white" stroke-width="1.20805"/>
                                    <path opacity="0.307047" d="M-527.128 1211.39C-515.822 1106.41 -335.415 880.386 295.768 816.138C926.952 751.89 1079.59 392.511 1077.01 220.853" stroke="white" stroke-width="1.22819"/>
                                    <path opacity="0.312081" d="M-553.602 1214.47C-538.679 1109.96 -350.804 890.401 281.31 848.328C913.424 806.255 1078.21 452.455 1081.59 280.814" stroke="white" stroke-width="1.24832"/>
                                    <path opacity="0.317114" d="M-579.97 1216.26C-561.447 1112.33 -366.332 899.497 265.948 879.586C898.228 859.674 1074.97 511.869 1084.3 340.456" stroke="white" stroke-width="1.26846"/>
                                    <path opacity="0.322148" d="M-606.186 1216.76C-584.087 1113.55 -381.967 907.67 249.716 909.88C881.398 912.09 1069.87 570.691 1085.15 399.715" stroke="white" stroke-width="1.28859"/>
                                    <path opacity="0.327181" d="M-632.207 1215.97C-606.558 1113.6 -397.677 914.917 232.649 939.182C862.975 963.446 1062.96 628.855 1084.16 458.526" stroke="white" stroke-width="1.30872"/>
                                    <path opacity="0.332215" d="M-657.988 1213.91C-628.819 1112.5 -413.429 921.236 214.784 967.462C842.997 1013.69 1054.25 686.298 1081.35 516.825" stroke="white" stroke-width="1.32886"/>
                                    <path opacity="0.337248" d="M-683.484 1210.58C-650.832 1110.25 -429.191 926.625 196.158 994.694C821.507 1062.76 1043.77 742.958 1076.73 574.548" stroke="white" stroke-width="1.34899"/>
                                    <path opacity="0.342282" d="M-708.653 1205.99C-672.557 1106.87 -444.93 931.086 176.809 1020.85C798.549 1110.62 1031.56 798.775 1070.35 631.634" stroke="white" stroke-width="1.36913"/>
                                    <path opacity="0.347315" d="M-733.45 1200.15C-693.954 1102.37 -460.615 934.619 156.776 1045.91C774.167 1157.2 1017.64 853.688 1062.21 688.021" stroke="white" stroke-width="1.38926"/>
                                    <path opacity="0.352349" d="M-757.833 1193.09C-714.985 1096.75 -476.211 937.227 136.099 1069.85C748.41 1202.47 1002.06 907.639 1052.35 743.649" stroke="white" stroke-width="1.4094"/>
                                    <path opacity="0.357383" d="M-781.76 1184.81C-735.612 1090.03 -491.688 938.915 114.819 1092.64C721.326 1246.37 984.846 960.571 1040.79 798.459" stroke="white" stroke-width="1.42953"/>
                                    <path opacity="0.362416" d="M-805.189 1175.32C-755.796 1082.23 -507.013 939.686 92.976 1114.27C692.966 1288.86 966.039 1012.43 1027.58 852.392" stroke="white" stroke-width="1.44966"/>
                                    <path opacity="0.36745" d="M-828.078 1164.66C-775.501 1073.36 -522.155 939.547 70.6128 1134.72C663.381 1329.89 945.682 1063.16 1012.74 905.393" stroke="white" stroke-width="1.4698"/>
                                    <path opacity="0.372483" d="M-850.387 1152.84C-794.689 1063.44 -537.081 938.506 47.7717 1153.97C632.624 1369.44 923.815 1112.7 996.304 957.405" stroke="white" stroke-width="1.48993"/>
                                    <path opacity="0.377517" d="M-872.076 1139.88C-813.326 1052.49 -551.76 936.569 24.4953 1172.01C600.751 1407.45 900.483 1161.02 978.317 1008.37" stroke="white" stroke-width="1.51007"/>
                                    <path opacity="0.38255" d="M-893.107 1125.8C-831.374 1040.53 -566.162 933.748 0.828015 1188.82C567.818 1443.88 875.733 1208.05 958.817 1058.25" stroke="white" stroke-width="1.5302"/>
                                    <path opacity="0.387584" d="M-913.44 1110.63C-848.801 1027.57 -580.255 930.052 -23.1874 1204.39C533.88 1478.72 849.611 1253.75 937.843 1106.98" stroke="white" stroke-width="1.55034"/>
                                    <path opacity="0.392617" d="M-933.039 1094.39C-865.571 1013.65 -594.009 925.493 -47.5055 1218.7C498.998 1511.92 822.167 1298.08 915.439 1154.51" stroke="white" stroke-width="1.57047"/>
                                    <path opacity="0.397651" d="M-951.866 1077.1C-881.651 998.79 -607.394 920.083 -72.0821 1231.76C463.229 1543.44 793.452 1340.99 891.65 1200.8" stroke="white" stroke-width="1.5906"/>
                                    <path opacity="0.402685" d="M-969.887 1058.81C-897.01 983.007 -620.381 913.837 -96.8728 1243.56C426.635 1573.28 763.518 1382.44 866.521 1245.8" stroke="white" stroke-width="1.61074"/>
                                    <path opacity="0.407718" d="M-987.066 1039.53C-911.616 966.331 -632.94 906.768 -121.832 1254.08C389.276 1601.38 732.417 1422.38 840.099 1289.47" stroke="white" stroke-width="1.63087"/>
                                    <path opacity="0.412752" d="M-1003.37 1019.29C-925.439 948.789 -645.044 898.894 -146.914 1263.32C351.215 1627.74 700.206 1460.79 812.435 1331.76" stroke="white" stroke-width="1.65101"/>
                                    <path opacity="0.417785" d="M-1018.77 998.131C-938.448 930.409 -656.664 890.23 -172.075 1271.28C312.514 1652.33 666.939 1497.62 783.577 1372.63" stroke="white" stroke-width="1.67114"/>
                                    <path opacity="0.422819" d="M-1033.22 976.081C-950.616 911.221 -667.773 880.794 -197.269 1277.96C273.236 1675.13 632.673 1532.84 753.579 1412.04" stroke="white" stroke-width="1.69128"/>
                                    <path opacity="0.427852" d="M-1046.71 953.173C-961.915 891.254 -678.345 870.605 -222.45 1283.37C233.446 1696.13 597.468 1566.41 722.492 1449.96" stroke="white" stroke-width="1.71141"/>
                                    <path opacity="0.432886" d="M-1059.2 929.444C-972.319 870.539 -688.354 859.683 -247.573 1287.49C193.207 1715.3 561.382 1598.32 690.372 1486.35" stroke="white" stroke-width="1.73154"/>
                                    <path opacity="0.437919" d="M-1070.67 904.927C-981.802 849.109 -697.774 848.048 -272.594 1290.35C152.586 1732.65 524.475 1628.53 657.272 1521.18" stroke="white" stroke-width="1.75168"/>
                                    <path opacity="0.442953" d="M-1081.08 879.66C-990.34 826.996 -706.581 835.721 -297.467 1291.93C111.646 1748.14 486.808 1657.01 623.25 1554.41" stroke="white" stroke-width="1.77181"/>
                                    <path opacity="0.447987" d="M-1090.41 853.68C-997.909 804.234 -714.751 822.725 -322.148 1292.26C70.4536 1761.79 448.443 1683.74 588.363 1586.02" stroke="white" stroke-width="1.79195"/>
                                    <path opacity="0.45302" d="M-1098.65 827.026C-1004.49 780.858 -722.261 809.083 -346.593 1291.33C29.074 1773.57 409.443 1708.7 552.668 1615.99" stroke="white" stroke-width="1.81208"/>
                                    <path opacity="0.458054" d="M-1105.75 799.737C-1010.06 756.903 -729.089 794.82 -370.758 1289.16C-12.4269 1783.5 369.869 1731.88 516.226 1644.27" stroke="white" stroke-width="1.83221"/>
                                    <path opacity="0.463087" d="M-1111.71 771.852C-1014.6 732.403 -735.214 779.957 -394.599 1285.76C-53.9837 1791.57 329.786 1753.25 479.094 1670.86" stroke="white" stroke-width="1.85235"/>
                                    <path opacity="0.468121" d="M-1116.51 743.413C-1018.09 707.398 -740.616 764.524 -418.074 1281.15C-95.5313 1797.77 289.258 1772.8 441.334 1695.73" stroke="white" stroke-width="1.87248"/>
                                    <path opacity="0.473154" d="M-1120.12 714.461C-1020.51 681.923 -745.274 748.544 -441.139 1275.33C-137.003 1802.12 248.349 1790.52 403.007 1718.87" stroke="white" stroke-width="1.89262"/>
                                    <path opacity="0.478188" d="M-1122.53 685.038C-1021.86 656.017 -749.171 732.046 -463.753 1268.33C-178.335 1804.62 207.123 1806.4 364.175 1740.25" stroke="white" stroke-width="1.91275"/>
                                    <path opacity="0.483221" d="M-1123.72 655.186C-1022.11 629.716 -752.289 715.055 -485.875 1260.17C-219.462 1805.28 165.646 1820.43 324.898 1759.86" stroke="white" stroke-width="1.93289"/>
                                    <path opacity="0.488255" d="M-1123.68 624.949C-1021.25 603.062 -754.61 697.601 -507.465 1250.86C-260.32 1804.11 123.982 1832.61 285.24 1777.7" stroke="white" stroke-width="1.95302"/>
                                    <path opacity="0.493289" d="M-1122.39 594.37C-1019.27 576.091 -756.12 679.711 -528.482 1240.42C-300.844 1801.12 82.1967 1842.93 245.262 1793.74" stroke="white" stroke-width="1.97315"/>
                                    <path opacity="0.498322" d="M-1119.86 563.493C-1016.16 548.845 -756.804 661.415 -548.888 1228.87C-340.972 1796.33 40.3546 1851.39 205.028 1807.99" stroke="white" stroke-width="1.99329"/>
                                    <path opacity="0.503356" d="M-1116.05 532.362C-1011.92 521.363 -756.648 642.743 -568.644 1216.25C-380.641 1789.76 -1.47933 1858.01 164.601 1820.44" stroke="white" stroke-width="2.01342"/>
                                    <path opacity="0.508389" d="M-1110.98 501.023C-1006.52 493.686 -755.64 623.725 -587.714 1202.57C-419.788 1781.42 -43.2405 1862.77 124.043 1831.09" stroke="white" stroke-width="2.03356"/>
                                    <path opacity="0.513423" d="M-1104.62 469.519C-999.979 465.854 -753.767 604.39 -606.061 1187.86C-458.354 1771.33 -84.8646 1865.69 83.4167 1839.93" stroke="white" stroke-width="2.05369"/>
                                    <path opacity="0.518456" d="M-1096.98 437.897C-992.276 437.906 -751.021 584.771 -623.649 1172.15C-496.277 1759.53 -126.288 1866.77 42.7858 1846.97" stroke="white" stroke-width="2.07383"/>
                                    <path opacity="0.52349" d="M-1088.06 406.201C-983.413 409.886 -747.39 564.898 -640.444 1155.47C-533.499 1746.04 -167.446 1866.03 2.21237 1852.21" stroke="white" stroke-width="2.09396"/>
                                    <path opacity="0.538591" d="M-1053.54 311.124C-949.85 325.79 -731.119 504.076 -685.748 1099.89C-640.378 1695.7 -288.706 1853.01 -118.541 1857.19" stroke="white" stroke-width="2.15436"/>
                                    <path opacity="0.543624" d="M-1039.47 279.587C-936.341 297.884 -723.882 483.507 -699.052 1079.63C-674.223 1675.74 -328.182 1845.13 -158.265 1855.32" stroke="white" stroke-width="2.1745"/>
                                    <path opacity="0.548658" d="M-1024.11 248.203C-921.677 270.108 -715.731 462.845 -711.409 1058.55C-707.087 1654.25 -367.085 1835.52 -197.624 1851.69" stroke="white" stroke-width="2.19463"/>
                                    <path opacity="0.553691" d="M-1007.48 217.017C-905.862 242.504 -706.664 442.122 -722.791 1036.7C-738.918 1631.27 -405.356 1824.19 -236.559 1846.33" stroke="white" stroke-width="2.21477"/>
                                    <path opacity="0.558725" d="M-989.578 186.073C-888.904 215.111 -696.678 421.371 -733.172 1014.1C-769.665 1606.84 -442.936 1811.18 -275.009 1839.26" stroke="white" stroke-width="2.2349"/>
                                    <path opacity="0.563758" d="M-970.421 155.417C-870.81 187.971 -685.775 400.624 -742.528 990.808C-799.28 1580.99 -479.768 1796.51 -312.917 1830.5" stroke="white" stroke-width="2.25503"/>
                                    <path opacity="0.568792" d="M-950.018 125.092C-851.589 161.121 -673.953 379.914 -750.835 966.846C-827.716 1553.78 -515.795 1780.22 -350.225 1820.07" stroke="white" stroke-width="2.27517"/>
                                    <path opacity="0.573825" d="M-928.379 95.1407C-831.254 134.603 -661.216 359.273 -758.071 942.256C-854.925 1525.24 -550.964 1762.32 -386.877 1807.99" stroke="white" stroke-width="2.2953"/>
                                    <path opacity="0.578859" d="M-905.52 65.6079C-809.815 108.455 -647.567 338.735 -764.216 917.08C-880.864 1495.42 -585.22 1742.87 -422.817 1794.3" stroke="white" stroke-width="2.31544"/>
                                    <path opacity="0.583893" d="M-881.454 36.5355C-787.286 82.715 -633.009 318.33 -769.25 891.356C-905.49 1464.38 -618.51 1721.89 -457.99 1779.02" stroke="white" stroke-width="2.33557"/>
                                    <path opacity="0.588926" d="M-856.2 7.96646C-763.682 57.4226 -617.548 298.093 -773.155 865.125C-928.763 1432.16 -650.783 1699.43 -492.343 1762.18" stroke="white" stroke-width="2.3557"/>
                                    <path opacity="0.59396" d="M-829.774 -20.0582C-739.019 32.6146 -601.19 278.054 -775.917 838.43C-950.643 1398.81 -681.991 1675.51 -525.824 1743.82" stroke="white" stroke-width="2.37584"/>
                                    <path opacity="0.598993" d="M-802.197 -47.4978C-713.314 8.32776 -583.943 258.245 -777.518 811.311C-971.093 1364.38 -712.083 1650.19 -558.381 1723.96" stroke="white" stroke-width="2.39597"/>
                                    <path opacity="0.604027" d="M-773.489 -74.3119C-686.587 -15.4012 -565.816 238.698 -777.948 783.811C-990.079 1328.92 -741.014 1623.5 -589.965 1702.65" stroke="white" stroke-width="2.41611"/>
                                    <path opacity="0.60906" d="M-743.672 -100.461C-658.856 -38.537 -546.818 219.444 -777.193 755.972C-1007.57 1292.5 -768.739 1595.49 -620.528 1679.92" stroke="white" stroke-width="2.43624"/>
                                    <path opacity="0.614094" d="M-712.772 -125.907C-630.144 -61.0444 -526.96 200.513 -775.243 727.839C-1023.53 1255.16 -795.214 1566.21 -650.022 1655.81" stroke="white" stroke-width="2.45638"/>
                                    <path opacity="0.619128" d="M-680.811 -150.612C-600.472 -82.8897 -506.253 181.935 -772.09 699.453C-1037.93 1216.97 -820.397 1535.69 -678.403 1630.37" stroke="white" stroke-width="2.47651"/>
                                    <path opacity="0.624161" d="M-647.817 -174.538C-569.865 -104.038 -484.712 163.742 -767.726 670.86C-1050.74 1177.98 -844.248 1504 -705.625 1603.62" stroke="white" stroke-width="2.49664"/>
                                    <path opacity="0.629195" d="M-613.818 -197.652C-538.345 -124.459 -462.35 145.961 -762.147 642.102C-1061.94 1138.24 -866.729 1471.18 -731.647 1575.63" stroke="white" stroke-width="2.51678"/>
                                    <path opacity="0.634228" d="M-578.841 -219.916C-505.94 -144.12 -439.181 128.623 -755.347 613.224C-1071.51 1097.83 -887.804 1437.28 -756.428 1546.43" stroke="white" stroke-width="2.53691"/>
                                    <path opacity="0.639262" d="M-542.917 -241.297C-472.677 -162.99 -415.223 111.755 -747.326 584.27C-1079.43 1056.79 -907.437 1402.35 -779.928 1516.07" stroke="white" stroke-width="2.55705"/>
                                    <path opacity="0.644295" d="M-506.078 -261.763C-438.583 -181.039 -390.491 95.3849 -738.08 555.284C-1085.67 1015.18 -925.596 1366.45 -802.111 1484.59" stroke="white" stroke-width="2.57718"/>
                                    <path opacity="0.649329" d="M-468.354 -281.283C-403.687 -198.239 -365.004 79.5397 -727.612 526.31C-1090.22 973.08 -942.251 1329.63 -822.94 1452.05" stroke="white" stroke-width="2.59732"/>
                                    <path opacity="0.654362" d="M-429.78 -299.824C-368.018 -214.563 -338.781 64.2464 -715.924 497.392C-1093.07 930.537 -957.372 1291.94 -842.382 1418.5" stroke="white" stroke-width="2.61745"/>
                                    <path opacity="0.659396" d="M-390.39 -317.359C-331.609 -229.983 -311.841 49.5303 -703.018 468.573C-1094.2 887.616 -970.933 1253.45 -860.404 1383.99" stroke="white" stroke-width="2.63758"/>
                                    <path opacity="0.66443" d="M-350.22 -333.859C-294.49 -244.474 -284.205 35.4162 -688.902 439.897C-1093.6 844.379 -982.909 1214.21 -876.977 1348.56" stroke="white" stroke-width="2.65772"/>
                                    <path opacity="0.669463" d="M-309.304 -349.296C-256.694 -258.011 -255.894 21.9297 -673.581 411.41C-1091.27 800.89 -993.277 1174.27 -892.071 1312.28" stroke="white" stroke-width="2.67785"/>
                                    <path opacity="0.674497" d="M-267.682 -363.647C-218.254 -270.572 -226.932 9.09284 -657.063 383.151C-1087.19 757.209 -1002.02 1133.7 -905.661 1275.2" stroke="white" stroke-width="2.69799"/>
                                    <path opacity="0.67953" d="M-225.39 -376.886C-179.206 -282.134 -197.341 -3.07126 -639.36 355.165C-1081.38 713.401 -1009.11 1092.56 -917.722 1237.36" stroke="white" stroke-width="2.71812"/>
                                    <path opacity="0.684564" d="M-182.468 -388.99C-139.582 -292.676 -167.144 -14.5397 -620.481 327.494C-1073.82 669.528 -1014.54 1050.9 -928.231 1198.84" stroke="white" stroke-width="2.73826"/>
                                    <path opacity="0.689597" d="M-138.955 -399.939C-99.4201 -302.18 -136.368 -25.2931 -600.44 300.179C-1064.51 625.652 -1018.29 1008.79 -937.168 1159.68" stroke="white" stroke-width="2.75839"/>
                                    <path opacity="0.694631" d="M-94.8922 -409.713C-58.7556 -310.627 -105.036 -35.3115 -579.252 273.262C-1053.47 581.835 -1020.35 966.285 -944.514 1119.94" stroke="white" stroke-width="2.77852"/>
                                    <path opacity="0.699664" d="M-50.3198 -418.291C-17.6255 -317.998 -73.1759 -44.5732 -556.932 246.784C-1040.69 538.141 -1020.71 923.445 -950.252 1079.68" stroke="white" stroke-width="2.79866"/>
                                    <path opacity="0.704698" d="M-5.27969 -425.657C23.9326 -324.279 -40.8136 -53.062 -533.497 220.785C-1026.18 494.632 -1019.36 880.333 -954.369 1038.95" stroke="white" stroke-width="2.81879"/>
                                    <path opacity="0.709732" d="M40.1858 -431.796C65.8808 -329.456 -7.97663 -60.7598 -508.966 195.304C-1009.96 451.368 -1016.3 837.01 -956.852 997.822" stroke="white" stroke-width="2.83893"/>
                                    <path opacity="0.714765" d="M86.0341 -436.694C108.181 -333.515 25.3071 -67.6514 -483.359 170.379C-992.025 408.41 -1011.52 793.536 -957.69 956.345" stroke="white" stroke-width="2.85906"/>
                                    <path opacity="0.719799" d="M132.222 -440.337C150.793 -336.446 59.0092 -73.7208 -456.697 146.049C-972.403 365.819 -1005.03 749.974 -956.875 914.58" stroke="white" stroke-width="2.87919"/>
                                    <path opacity="0.724832" d="M178.706 -442.714C193.679 -338.236 93.1003 -78.9534 -429.003 122.351C-951.105 323.656 -996.811 706.384 -954.401 872.585" stroke="white" stroke-width="2.89933"/>
                                    <path opacity="0.729866" d="M225.441 -443.817C236.799 -338.878 127.551 -83.336 -400.3 99.3212C-928.151 281.978 -986.88 662.828 -950.262 830.421" stroke="white" stroke-width="2.91946"/>
                                    <path opacity="0.734899" d="M272.384 -443.636C280.112 -338.364 162.332 -86.8569 -370.614 76.9944C-903.56 240.846 -975.238 619.367 -944.459 788.146" stroke="white" stroke-width="2.9396"/>
                                    <path opacity="0.739933" d="M319.491 -442.165C323.58 -336.687 197.412 -89.5046 -339.971 55.4054C-877.354 200.315 -961.893 576.06 -936.989 745.819" stroke="white" stroke-width="2.95973"/>
                                    <path opacity="0.744966" d="M366.716 -439.4C367.161 -333.844 232.761 -91.2694 -308.399 34.5872C-849.559 160.444 -946.854 532.969 -927.856 703.499" stroke="white" stroke-width="2.97987"/>
                                    <path opacity="0.75" d="M414.014 -435.337C410.815 -329.831 268.348 -92.1423 -275.926 14.5722C-820.2 121.287 -930.131 490.152 -917.063 661.245" stroke="white" stroke-width="3"/>
                                </g>
                            </svg>
                            {
                                this.StepText()
                            }
                        </div>
                    }
                    {
                        this.state.Step !== 3 && <SimpleBar className="RegisterFormScroll" autoHide={false} ><div className={"RegisterForm"}>
                            {
                                this.StepForm()
                            }
                        </div></SimpleBar>
                    }
                </div></SimpleBar>
            )
        }
}

export default RegisterPage