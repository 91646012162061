import "../CSS/Normalnput.css"
import React,{Component} from "react";

class TextNormal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Val: "",
            Anim: false,
            load: "",
            err: undefined,
            input: React.createRef(),
            heghit: "44px"
        }
        this.Input = React.createRef() 
        this.state.err = this.props.err
       
    }

    componentDidMount() {
        if (this.props.Value !== undefined) this.TextChange(this.props.Value)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.Value !== undefined) {
            if (this.props.Value != prevProps.Value) {
                this.state.input.current.value = this.props.Value
                this.TextChange(this.props.Value)
            }
        }
    }

    clearInputs() {
        this.state.input.current.value = ""
        this.state.Val = ""
        this.state.Anim = false
        this.state.load = ""
        this.state.err = undefined
        this.forceUpdate()
    }

    TextChange(val) {
        // if (val !== "") {
            this.state.Val = val
            this.state.Anim = val !== ""
            this.state.load = "InputNormal_Box_DeActive"
            this.forceUpdate()
        // }

    }

    keyUpCheck(ev) {
        // this.state.heghit = '0'
        // this.forceUpdate()
        ev.target.parentElement.style.height = '0'
        ev.target.parentElement.style.height = ev.target.scrollHeight + "px"
        this.forceUpdate()
    }

    LoadTextChange(val, event) {
        if (val !== "") this.TextChange(val) 
        if (this.props.onChange !== undefined) this.props.onChange(val)
    }

    render() {
        return (
            <div style={this.props.style !== undefined ? {...this.props.style, height: this.state.heghit} : {height: this.state.heghit}} className={"InputNormal_Box " + this.props.className }>
                <label style={{color: (this.state.err !== undefined && this.state.Val !== "") && "rgb(217, 79, 91)"}} className={this.state.Anim ? " InputNormal_Box_Active" : this.state.load}>{ (this.state.err !== undefined && this.state.Val !== "")? this.state.err  : this.props.text}</label>
                {
                    (this.state.Val === "" && this.state.err != undefined) && <label className={"InputNormal_err_label"}>{this.state.err}</label>            
                }
                <textarea ref={this.state.input}
                    defaultValue={this.props.Value || ""}
                    onKeyUp={this.keyUpCheck.bind(this)}
                    onFocus={e => {
                        if (this.props.Blocked) e.target.blur()
                        else {
                            this.state.err = undefined
                            this.state.Anim = true
                            this.forceUpdate()
                        }
                    }}
                    onBlur={e =>  !this.props.Blocked && this.TextChange(e.target.value)}
                    onChange={e => {if (!this.props.Blocked)  this.LoadTextChange(e.target.value, e)}}
                    readOnly={(this.props.readOnly || this.state.Blocked) ? true : false }  
                    />
            </div>
        )
    }

}

export default TextNormal