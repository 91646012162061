import React, { useEffect } from "react";
import VirtualTab from "../Utilis/VirtualTab";
import serverPath from "../../Utilis/ServerPath";
import Buttons from "../../Inputs/Buttons";
import { Collapse } from "react-collapse";
import CodeEditor from '@uiw/react-textarea-code-editor';

const ErrorTables = {
    "0" : "Wystapił bład ogólny (nr 0)", 
    "8" : "Brak kontrachenta w bazie danych",
    "7" : "Brak rodzaju obiegu",
    "6" : "Brak identyfikatora waluty",
    "9" : "Zamknięty obieg dokumentów",
    "5" : "Numer faktury juz istnieje w obiegu",
}

function RowQueue(props) {
    const [IsOpen, SetOpened] = React.useState(false)
    const [JsonData, SetJsonData] = React.useState(JSON.stringify(JSON.parse(props.Data.Data), null, 4) )
    
    useEffect(() => {
        SetOpened(false)
    }, [props])
    
    const UpdateData = React.useCallback(() => {
        fetch(
            serverPath() + "api/queue/invoice/edit/data",
            {
                method:"POST",
                headers: {
                    'Access-Control-Allow-Origin':'*'
                },
                credentials: 'include',
                body: JSON.stringify({
                    ID: props.Data.ID,
                    Data: JSON.stringify(JSON.parse(JsonData))
                })
            }
        )
        SetOpened(false)
    }, [JsonData])

    const SendData = React.useCallback(() => {
        fetch(
            serverPath() + "api/queue/invoice/edit/data",
            {
                method:"POST",
                headers: {
                    'Access-Control-Allow-Origin':'*'
                },
                credentials: 'include',
                body: JSON.stringify({
                    ID: props.Data.ID,
                    Data: JSON.stringify(JSON.parse(JsonData))
                })
            }
        ).then(resp => resp.text())
        .catch(e => null)
        .then(resp => {
            if (resp == "OK") {
                fetch(
                    serverPath() + "api/queue/invoice/send/one",
                    {
                        method:"POST",
                        headers: {
                            'Access-Control-Allow-Origin':'*'
                        },
                        credentials: 'include',
                        body: JSON.stringify({
                            ID: props.Data.ID,
                        })
                    }
                ).then(() => {
                    // props.refresh()
                })
            }
        })
        SetOpened(false)
    }, [JsonData])

    return (
        <>
            <tr
                key={`VL_${props.skey}`}
                style={{
                    backgroundColor: props.skey % 2 === 1 ? " rgba(255, 255, 255, 0.1)": ""
                }}
            >
                <td>
                    <a>
                        {props.Data.CompanyName}
                    </a>
                </td>
                <td>
                    <a>
                        {props.Data.NumberInovice}
                    </a>
                </td>
                <td>
                    <a>
                        {props.Data.Added}
                    </a>
                </td>
                <td>
                    <svg 
                        onClick={() => SetOpened((val) => !val)}
                        fill="#23A287" width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_97_680)">
                        <path d="M22.5 2.5H7.5C6.11929 2.5 5 3.61929 5 5V25C5 26.3807 6.11929 27.5 7.5 27.5H15.95C16.9442 27.4991 17.8974 27.1035 18.6 26.4L23.9 21.1C24.6035 20.3974 24.9991 19.4442 25 18.45V5C25 3.61929 23.8807 2.5 22.5 2.5ZM7.5 5H22.5V17.5H16.25C15.5596 17.5 15 18.0596 15 18.75V25H7.5V5Z"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_97_680">
                        <rect width="30" height="30"/>
                        </clipPath>
                        </defs>
                    </svg>
                </td>
            </tr>   
            <tr 
                className="VL_wrapper_table_noIndexed"
                style={{
                    height: "unset",
                    position: "relative",
                }}>
                    <td 
                        colSpan={"4"}
                        
                        style={{
                            backgroundColor: props.skey % 2 === 0 ? "hsla(167, 64%, 39%, 0.1)": "",
                            width: "100%",
                            position: "relative"
                        }}
                    >
                        <Collapse
                            isOpened={IsOpen}
                            style={{
                                position: "relative", width: "100%",
                            }}
                            
                        >
                            <div style={{
                                
                                position: "relative",
                            }}
                                className="VL_wrapper_table_main_collapse"
                            >
                                <p
                                    style={{whiteSpace: "pre"}}
                                >
                                    <b>Rodzaj Błędu:</b>
                                    <br></br>
                                    {
                                        ErrorTables[props.Data.ErrorType] != undefined && ErrorTables[props.Data.ErrorType]
                                    }
                                    &nbsp;(Błąd nr {props.Data.ErrorType})
                                </p>
                                <div className="VL_wrapper_table_main_collapse_form">
                                    <CodeEditor
                                        style={{
                                            width: "300px",
                                            position: "relative"
                                        }}
                                        language="json"
                                        value={JsonData}
                                        onChange={(e) => SetJsonData(e.target.value)}
                                    />                                    
                                </div>
                                <div className="VL_wrapper_table_main_collapse_submit">
                                    <Buttons.RedbuttonSave 
                                        text={"Zapisz"}
                                        onClick={() => UpdateData()}
                                    ></Buttons.RedbuttonSave>
                                    <Buttons.AcceptButton 
                                        text={"Wyslij"}
                                        onClick={() => SendData()}
                                    ></Buttons.AcceptButton>
                                </div>
                            </div>
                        </Collapse>                           
                    </td>
              
            </tr>     
        </>

    )
}

class QueueInvoices extends React.Component {

    SendData = () => {
        fetch(
            serverPath() + "api/queue/invoice/send/all",
            {
                method:"POST",
                headers: {
                    'Access-Control-Allow-Origin':'*'
                },
                credentials: 'include'
            }
        ).then(() => {
            this.forceUpdate()
        })
    }

    render() {
        return (
            <div style={{height: "70vh"}} className="WorkViewNormal">
                <div 
                    className="WorkViewNormal_header"
                    style={{
                        marginBottom: "20px"
                    }}
                >
                    <h1>Kolejka dokumentów</h1>
                    {/* <div className="DocumentSended_counters">
                        <a>{this.state.Counts.adds}</a>
                        <span style={{marginRight: "55px"}}>dokumentów ogółem</span>
                        <a>{this.state.Counts.last}</a>
                        <span>dokumentów w 30 dniach</span>
                    </div> */}
                    <Buttons.Redbutton onClick={this.SendData.bind(this)} text={'Wyślij wszystko'}/>
                </div>
                <VirtualTab
                    style={{
                        height: "80%",
                        position: "relative"
                    }}
                    Headers={[
                        {
                            Name: "Kontrachent",
                            SortValue: "Kontrachent",
                            width: "20%",
                        },
                        {
                            Name: "Numer faktury",
                            SortValue: "Kontrachent",
                        },
                        {
                            Name: "Data dodania",
                            SortValue: "Kontrachent",
                            width: "20%",
                        },
                        {
                            Name: "",
                            SortValue: "",
                            width: "4%",
                        },
                    ]}
                    RenderRow={
                        (...prop) => <RowQueue Data={prop[0]} skey={prop[1]} refresh={prop[2]}/>
                    }
                    DataPath={
                        (Page) => serverPath() + `api/queue/invoice/all?page=${Page}`
                    }
                    CuntPath={
                        () => serverPath() + `api/queue/invoice/count/all`
                    }
                />
            </div>
        )
    }

}

export default QueueInvoices;