import React, { Component } from "react";
import "../../CSS/Views/mainview.css"
import Buttons from "../../Inputs/Buttons";
import Chexbox from "../../Inputs/Chexbox";
import InputNormal from "../../Inputs/InputNormal";
import InputSelect from "../../Inputs/InputSelec";
import StaticInput from "../../Inputs/StaticInput";
import serverPath from "../../Utilis/ServerPath";
import Validate from "../../Utilis/Validate";
import "../../CSS/Responsive/r_user.css"
import GusApi from "../../Utilis/SoapGUS";

class UserProfile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            View: 0,
            ShowAdd: false,
            SortType: [0,0,0,0],
            Items: [
                
            ],
            Company: {
                NIP: "",
                CompanyName: "",
                CompanyRoad: "",
                CompanyRoadCity: "",
                CompanyZipCode: "", 
                CompanyRoadNumber: ""
            },
            Bank: {
                BankName: "",
                BankNumber: "",
                BankCurrency: "PLN",
                DefaultBank:false
            },
            PasswordRestet: {
                ActualPassword:"",
                Password:"",
                rPassword:"",
            },
            EmailReset: {
                Email:"",
                rEmail:"",
            },
            PasswordMode: false,
            EmailMode: false,
            CompanyMode: false
        }

        this.BankNumberInput = React.createRef()
        this.BankNameInput = React.createRef()
        
        this.APasswordInput = React.createRef()
        this.PasswordInput = React.createRef()
        this.RPasswordInput = React.createRef()
        
        this.EmailInput = React.createRef()
        this.REmailInput = React.createRef()

        this.NIP_input = React.createRef()
        this.CompanyName_input = React.createRef()
        this.CompanyRoad_input = React.createRef()
        this.CompanyRoadCity_input = React.createRef()
        this.CompanyZipCode_input = React.createRef()
        this.CompanyRoadNumber_input = React.createRef()

    }

    componentDidMount() {
        this.state.Company = {
            NIP: this.props.UserData.NIP,
            CompanyName: this.props.UserData.CompanyName,
            CompanyRoad: this.props.UserData.CompanyRoad,
            CompanyRoadCity: this.props.UserData.CompanyRoadCity,
            CompanyZipCode: this.props.UserData.CompanyZipCode, 
            CompanyRoadNumber: this.props.UserData.CompanyRoadNumber
        }
        this.GetBankAccount()
    }

    ShowAddMenu(event) {
        this.state.Bank = {
            BankName: "",
            BankNumber: "",
            BankCurrency: "PLN",
            DefaultBank:false
        }
        this.state.ShowAdd = true
        this.forceUpdate()
    }

    CloseAddMenu() {
        this.state.Bank = {
            BankName: "",
            BankNumber: "",
            BankCurrency: "PLN",
            DefaultBank:false
        }
        this.state.ShowAdd = false
        this.forceUpdate()
    }

    ChangeView(event) {
        if (event.target.classList.contains("WorkViewNormal_header_user_right")) {
            if (event.target.style.opacity !== "") {
                event.target.parentElement.querySelectorAll("h1").forEach(element => {
                    if (element.style.opacity === "") element.style.opacity = ".5"
                });
                event.target.style.opacity = ""
                this.state.View = 1
                this.forceUpdate()
            }
        } else {
            if (event.target.style.opacity !== "") {
                event.target.parentElement.querySelectorAll("h1").forEach(element => {
                    if (element.style.opacity === "") element.style.opacity = ".5"
                });
                event.target.style.opacity = ""
                this.state.View = 0
                this.forceUpdate()
            }
        }

    }

    SortItems(id) {
        switch (id) {
            case 1:
                if (this.state.SortType[0] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.BankName.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.BankName.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                            return -1;
                            }
                            if (nameA > nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[0] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.BankName.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.BankName.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                            return -1;
                            }
                            if (nameA < nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[0] = 1
                }
                this.forceUpdate()
                break
            case 2:
                if (this.state.SortType[1] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  parseFloat(b.BankNumber.replaceAll(" ", "").replaceAll(",", ".")) - parseFloat(a.BankNumber.replaceAll(" ", "").replaceAll(",", "."))
                    )
                    this.state.SortType[1] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => parseFloat(a.BankNumber.replaceAll(" ", "").replaceAll(",", ".")) - parseFloat(b.BankNumber.replaceAll(" ", "").replaceAll(",", "."))
                    )
                    this.state.SortType[1] = 1
                }
                this.forceUpdate()
                break
            case 3:
                if (this.state.SortType[2] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.Currency.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Currency.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                            return -1;
                            }
                            if (nameA > nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[2] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.Currency.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Currency.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                            return -1;
                            }
                            if (nameA < nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[2] = 1
                }
                this.forceUpdate()
                break
            case 4:
                if (this.state.SortType[3] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.Default ? "TAK" : "NIE"; // ignore upper and lowercase
                            var nameB = b.Default ? "TAK" : "NIE";
                            if (nameA < nameB) {
                            return -1;
                            }
                            if (nameA > nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[3] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.Default ? "TAK" : "NIE"; // ignore upper and lowercase
                            var nameB = b.Default ? "TAK" : "NIE"; // ignore upper and lowercase
                            if (nameA > nameB) {
                            return -1;
                            }
                            if (nameA < nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[3] = 1
                }
                this.forceUpdate()
                break                
        }
    }

    ShowMore(event, id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .classList.add("WorkViewNormal_selected")
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll("tr")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                } else {
                    e.style.display = "none"
                }
            })
        // document.querySelector("#SendedDocuments_row_id_" + id.toString())
        //     .querySelectorAll(".WorkViewNormal_selected_hide_bt")
        //     .forEach( e => {
        //         if (e.style.display === "none") {
        //             e.style.display = "table-row"
        //         }
        //     })
    }
    
    Hide(event,id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .classList.remove("WorkViewNormal_selected")
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll("tr")
            .forEach( e => {
                if (e.style.display === "table-row") {
                    e.style.display = "none"
                } else {
                    e.style.display = "table-row"
                }
            })
        // document.querySelector("#SendedDocuments_row_id_" + id.toString())
        //     .querySelectorAll(".WorkViewNormal_selected_hide_bt")
        //     .forEach( e => {
        //         if (e.style.display === "table-row") {
        //             e.style.display = "none"
        //         }
        //     })
    }

    RenderAcoutns() {
        const tab = []
        for(let i = 0; i < this.state.Items.length; i++) {
            tab.push(
                <tr id={"SendedDocuments_row_id_" + i.toString()} className="">
                <th style={{transition: "1s"}} colSpan={"5"}>
                    <table tyle={{transition: "5s"}} style={{width: "100%"}}>
                        <tbody>
                            <tr colSpan="5">
                                <th>
                                    <a style={{textAlign: "left", paddingLeft: "11px"}}>
                                        {
                                            this.state.Items[i].BankName
                                        }
                                    </a>
                                </th>
                                <th>
                                    <a style={{textAlign: "left", paddingLeft: "11px"}}>
                                        {
                                            this.state.Items[i].BankNumber
                                        }
                                    </a>
                                </th>
                                <th>
                                    <a>
                                        {
                                            this.state.Items[i].BankCurrency
                                        }
                                    </a>
                                </th>
                                <th>
                                    <a>
                                        {
                                            this.state.Items[i].DefaultBank == '1' ? "TAK" : "NIE"
                                        }
                                    </a>
                                </th>   
                                <th onClick={e => this.ShowMore(e, i)} style={{width: "180px"}}>
                                    <Buttons.TransparentbuttonGear text={"EDYTUJ"}/>
                                </th>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}} colSpan="5">
                                <td style={{paddingLeft: "11px", paddingTop: "15px"}} className="WorkViewNormal_selected_types" colSpan={"5"}>
                                    <InputNormal className="UserDate_inputNormal" style={{width: "400px", display: "inline-block", marginRight: "60px"}} onChange={e => this.state.Bank.BankName = e} Value={this.state.Items[i].BankName}  text={"Nazwa banku"}/>
                                    <InputNormal className="UserDate_inputNormal" style={{width: "400px", display: "inline-block"}} onChange={e => this.state.Bank.BankNumber = e} Value={this.state.Items[i].BankNumber} text={"Numer rachunku"}/>
                                </td>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}} colSpan="5">
                                <td style={{paddingLeft: "11px"}} className="WorkViewNormal_selected_types" colSpan={"5"}>
                                    <InputSelect ItemClass={"UserDate_inputNormal_item_select"} className={"UserDate_inputNormal"} items={["PLN", "EUR", "USD"]} onChange={e => this.state.Bank.BankCurrency = e} style={{marginTop: "27px", width: '250px', display: "inline-block",  marginRight: "213px"}} text={this.state.Items[i].BankCurrency}/>
                                    <Chexbox.CheckboxBt style={{display: "inline-block", transform: "unset", top: "10px"}} className="InputNormal_Box_Search_filtres_box_checkbox" onChange={e => this.state.Bank.DefaultBank = e.target.checked}  checked={this.state.Items[i].DefaultBank == '1'} text={"Ustaw rachunek jako domyślny"}/>
                                </td>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}} colSpan="5">
                                <td style={{paddingLeft: "11px"}} className="WorkViewNormal_selected_types" colSpan={"5"}>
                                    <Buttons.RedbuttonSave style={{display: "inline-block", float: "right", marginTop: "10px"}} onClick={this.EditBankAccount.bind(this, this.state.Items[i], i)}  text={"ZAPISZ"}/>
                                    <Buttons.TransparentbuttonClose style={{color: "white", display: "inline-block", float: "right", marginTop: "10px", marginRight: "26px", paddingLeft: '30px'}} className={"UserDate_transparent_buttons"} onClick={e => this.Hide(e , i)} text={"ZAMKNIJ"}/>
                                </td>      
                            </tr>
                        </tbody>
                    </table>
                </th>
            </tr>
            )
        }

        if (tab.length == 0) {
            tab.push(<tr className="">
                <th colSpan={"5"}> 
                    <h2 style={{textAlign: "center", marginTop: "20px"}}>Brak danych</h2>
                </th>
            </tr>)
        }

        return tab
    }

    async SaveCompanyData() {
        const resp = await fetch(
            serverPath() + "api/profile/edit/company", 
            {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify(this.state.Company)
            }
        ).then(data => data.json()).catch(null)

        if (resp !== null) {
            this.props.UserData.NIP = this.state.Company.NIP
            this.props.UserData.CompanyName = this.state.Company.CompanyName
            this.props.UserData.CompanyRoad = this.state.Company.CompanyRoad
            this.props.UserData.CompanyRoadCity = this.state.Company.CompanyRoadCity
            this.props.UserData.CompanyZipCode = this.state.Company.CompanyZipCode
            this.props.UserData.CompanyRoadNumber = this.state.Company.CompanyRoadNumber
            console.log("zapisano")
        }
    }

    refreshBankAccounts() {
        this.state.Bank = {
            BankName: "",
            BankNumber: "",
            BankCurrency: "PLN",
            DefaultBank:false
        }
        this.state.Items = []
        if (this.BankNameInput.current != null) this.BankNameInput.current.clearInputs()
        if (this.BankNumberInput.current != null) this.BankNumberInput.current.clearInputs()
        this.GetBankAccount()
    }

    async GetBankAccount() {

        const resp = await fetch(
            serverPath() + "api/profile/bank", 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)

        if (resp !== null) this.state.Items = resp
        this.forceUpdate()
    }

    async AddBankAccount() {
        
        if (
            this.state.Bank.BankName.replaceAll(" ", "") != "" &&
            this.state.Bank.BankNumber.replaceAll(" ", "") != "" &&
            this.state.Bank.BankCurrency.replaceAll(" ", "") != ""
        ) { 
            this.state.Bank.DefaultBank = this.state.Bank.DefaultBank ? "1" : "0" 
            const resp = await fetch(
                serverPath() + "api/profile/bank/add", 
                {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                    },
                    credentials: 'include',
                    body: JSON.stringify(this.state.Bank)
                }
            ).then(data => data.json()).catch(null)

            this.refreshBankAccounts()
            this.CloseAddMenu()
        }
    }

    async ResetPasword() {
        if (
            this.state.PasswordRestet.ActualPassword.replaceAll(" ", "") != "" &&
            this.state.PasswordRestet.Password.replaceAll(" ", "") != "" &&
            this.state.PasswordRestet.rPassword.replaceAll(" ", "") != ""
        ) { 
            if (this.state.PasswordRestet.Password !== this.state.PasswordRestet.rPassword) {
                if (this.RPasswordInput.current !== undefined && this.PasswordInput.current !== undefined) {
                    this.RPasswordInput.current.state.err = "Hasła nie są takie same!"
                    this.PasswordInput.current.state.err = "Hasła nie są takie same!"
                }
                this.forceUpdate()
                return
            }

            if (this.state.PasswordRestet.Password.length < 8) {
                if (this.PasswordInput.current !== undefined) {
                    this.PasswordInput.current.state.err = "Hasło musi miec co najmniej 8 znaków!"
                }
                this.forceUpdate()
                return
            }

            if (this.state.PasswordRestet.rPassword.length < 8) {
                if (this.RPasswordInput.current !== undefined) {
                    this.RPasswordInput.current.state.err = "Hasło musi miec co najmniej 8 znaków!"
                }
                this.forceUpdate()
                return
            }

            const resp = await fetch(
                serverPath() + "api/profile/password", 
                {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                    },
                    credentials: 'include',
                    body: JSON.stringify(this.state.PasswordRestet)
                }
            ).then(data => data.json()).catch(null)

            if (resp !== null) {
                if (resp['CODE'] == 'OK') {
                    this.APasswordInput.current.clearInputs()
                    this.RPasswordInput.current.clearInputs()
                    this.PasswordInput.current.clearInputs()
                    this.state.PasswordMode = false
                    this.state.PasswordRestet = {
                        ActualPassword:"",
                        Password:"",
                        rPassword:"",
                    }
                    this.forceUpdate()
                } else {
                    this.APasswordInput.current.err = "Hasło nie pasuje do istniejącego!"
                    this.forceUpdate()
                }
            } else {
                this.APasswordInput.current.err = "Hasło nie pasuje do istniejącego!"
                this.forceUpdate()
            } 
        } else {
            this.state.PasswordMode = false
            this.forceUpdate()
        }
    }

    async ResetEmail() {
        if (
            this.state.EmailReset.Email.replaceAll(" ", "") != "" &&
            this.state.EmailReset.rEmail.replaceAll(" ", "") != ""
        ) {  
            if (this.state.EmailReset.Email !== this.state.EmailReset.rEmail) {
                if (this.EmailInput.current !== undefined && this.REmailInput.current !== undefined) {
                    this.EmailInput.current.state.err = "Adresy nie są takie same!"
                    this.REmailInput.current.state.err = "Adresy nie są takie same!"
                }
                this.forceUpdate()
                return
            }

            if (!Validate.ValidateEmail(this.state.EmailReset.Email)) {
                if (this.EmailInput.current !== undefined) {
                    this.EmailInput.current.state.err = "Zła struktura adresu!"
                }
                this.forceUpdate()
                return
            }

            if (!Validate.ValidateEmail(this.state.EmailReset.rEmail)) {
                if (this.REmailInput.current !== undefined) {
                    this.REmailInput.current.state.err = "Zła struktura adresu!"
                }
                this.forceUpdate()
                return
            }

            const resp = await fetch(
                serverPath() + "api/profile/email", 
                {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                    },
                    credentials: 'include',
                    body: JSON.stringify(this.state.EmailReset)
                }
            ).then(data => data.json()).catch(null)

            if (resp !== null) {
                if (resp['CODE'] == 'OK') {
                    this.REmailInput.current.clearInputs()
                    this.EmailInput.current.clearInputs()
                    this.state.EmailMode = false
                    this.state.EmailReset = {
                        Email:"",
                        rEmail:"",
                    }
                    this.forceUpdate()
                }
            }
        } else {
            this.state.EmailMode = false
            this.forceUpdate()
        }
    }

    async EditBankAccount(id, i) {
        if (this.state.Bank.BankName.replaceAll(" ", "") == "") {
            this.state.Bank.BankName = id.BankName
        }
        if (this.state.Bank.BankNumber.replaceAll(" ", "") == "") {
            this.state.Bank.BankNumber = id.BankNumber
        }
        if (
            this.state.Bank.BankName.replaceAll(" ", "") != "" &&
            this.state.Bank.BankNumber.replaceAll(" ", "") != "" &&
            this.state.Bank.BankCurrency.replaceAll(" ", "") != ""
        ) { 
            this.state.Bank.DefaultBank = this.state.Bank.DefaultBank ? "1" : "0" 
            const resp = await fetch(
                serverPath() + "api/profile/bank/edit", 
                {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ID: id.ID , ...this.state.Bank})
                }
            ).then(data => data.json()).catch(null)
            this.Hide(undefined , i)
            this.refreshBankAccounts()
            
        }
    }
    
    RemoveAcount() {
        fetch(
            serverPath() + "api/logout", 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(resp => resp.json())
            .then(() => {
                window.location.href = "/"
            })
    }

    DownloadFromGUS() {
        const isNip = (Number) => {
            try {
                if (Number.toString().length >= 10) {
                    let x = parseInt(Number)
                    return true
                } else return false
            } catch (E) {
                return false
            }
        }
        if (isNip(this.state.Company.NIP)) {
            let apig = new GusApi()
            apig.DoLogin().then(
                () => {
                    apig.DoSerach(this.state.Company.NIP).then(
                        data => {
                        
                            if (data.Miejscowosc !== undefined && data.Miejscowosc !== "") {
                                this.state.Company.CompanyRoadCity = data.Miejscowosc
                                if (this.CompanyRoadCity_input.current !== undefined) {
                                    this.CompanyRoadCity_input.current.state.input.current.value = data.Miejscowosc
                                    this.CompanyRoadCity_input.current.TextChange(data.Miejscowosc)
                                }
                            }
                            
                            if (data.KodPocztowy !== undefined && data.KodPocztowy !== "") {
                                this.state.Company.CompanyZipCode = data.KodPocztowy
                                if (this.CompanyZipCode_input.current !== undefined) {
                                    this.CompanyZipCode_input.current.state.input.current.value = data.KodPocztowy
                                    this.CompanyZipCode_input.current.TextChange(data.KodPocztowy)
                                }
                            }

                            if (data.NrNieruchomosci !== undefined && data.NrNieruchomosci !== "") {
                                this.state.Company.CompanyRoadNumber = data.NrNieruchomosci
                                if (this.CompanyRoadNumber_input.current !== undefined) {
                                    this.CompanyRoadNumber_input.current.state.input.current.value = data.NrNieruchomosci
                                    this.CompanyRoadNumber_input.current.TextChange(data.NrNieruchomosci)
                                }
                            }

                            if (data.Ulica !== undefined && data.Ulica !== "") {
                                this.state.Company.CompanyRoad = data.Ulica
                                if (this.CompanyRoad_input.current !== undefined) {
                                    this.CompanyRoad_input.current.state.input.current.value = data.Ulica
                                    this.CompanyRoad_input.current.TextChange(data.Ulica)
                                }
                            }

                            if (data.Nazwa !== undefined && data.Nazwa !== "") {
                                this.state.Company.CompanyName = data.Nazwa
                                if (this.CompanyName_input.current !== undefined) {
                                    this.CompanyName_input.current.state.input.current.value = data.Nazwa
                                    this.CompanyName_input.current.TextChange(data.Nazwa)
                                }
                            }
                            this.SaveCompanyData()
                            this.state.CompanyMode = false
                            this.forceUpdate()
                            // console.log(this.state.Company)
                            apig.DoLogout()
                        }
                    )
                }
            )
            }

    }

    ChangeEditMode(Type) {
        if (Type === 1) {
            if (this.state.PasswordMode) {
                this.ResetPasword()
                // this.state.PasswordMode = false
            } else {
                this.state.PasswordMode = true
                this.forceUpdate()
            }
            
        } 
        else if (Type === 2) {
            if (this.state.EmailMode) {
                // this.state.EmailMode = false
                this.ResetEmail()
            } else {
                this.state.EmailMode = true
                this.forceUpdate()
            }
        } 
        else if (Type === 3) {
            if (this.state.CompanyMode) {
                this.SaveCompanyData()
                this.state.CompanyMode = false
            } else {
                this.state.CompanyMode = true
            }
            
            this.forceUpdate()
        }
    }

    RenderView() {
        if (this.state.View === 0) {
            return (
                <div>
                    <div>
                        <StaticInput style={
                            {
                                width: "250px",
                                display: "inline-block",
                                marginRight: "61px", 
                                marginBottom: "30px"
                            }
                            } text="login" Val={this.props.UserData.Login} readOnly={true}/>
                        <StaticInput style={
                            {
                                width: "250px",
                                display: "inline-block",
                                marginRight: "61px",
                                marginBottom: "30px"
                            }
                            } text="imię" Val={this.props.UserData.Name} readOnly={true}/>
                        <StaticInput style={
                            {
                                width: "250px",
                                display: "inline-block",
                                marginRight: "61px",
                                marginBottom: "30px"
                            }
                            } text="nazwisko" Val={this.props.UserData.Surrname} readOnly={true}/>
                    </div>
                    <div className="UserDate_form">
                        <h2>Hasło</h2>
                        {
                            (() => {
                                if (!this.state.PasswordMode) 
                                    return <Buttons.TransparentbuttonGear className="Password_editBt" text={"EDYTUJ"} onClick={this.ChangeEditMode.bind(this, 1)}/>
                                else return <Buttons.RedbuttonSave className="Password_editBt" text={"ZAPISZ"} onClick={this.ChangeEditMode.bind(this, 1)}/>
                            })()
                        }
                        <InputNormal ref={this.APasswordInput} style={{width: "250px", marginTop: "55px"}} onChange={e => this.state.PasswordRestet.ActualPassword = e} types={"password"} Blocked={!this.state.PasswordMode} text={"aktualne hasło"}/>
                        <InputNormal ref={this.PasswordInput} style={{width: "250px" , marginTop: "40px", opacity: !this.state.PasswordMode &&  "0.5"}} onChange={e => this.state.PasswordRestet.Password = e} types={"password"} Blocked={!this.state.PasswordMode}  text={"nowe hasło"}/>
                        <InputNormal ref={this.RPasswordInput} style={{width: "250px" , marginTop: "40px" , opacity: !this.state.PasswordMode &&  "0.5"}} types={"password"} onChange={e => this.state.PasswordRestet.rPassword = e} Blocked={!this.state.PasswordMode}  text={"powtórz hasło"}/>
                    </div>
                    <div className="UserDate_form">
                        <h2>Adres e-mail</h2>
                        {
                            (()=> {
                                if (!this.state.EmailMode) 
                                    return  <Buttons.TransparentbuttonGear className="email_editBt" text={"EDYTUJ"} onClick={this.ChangeEditMode.bind(this, 2)}/>
                                else return <Buttons.RedbuttonSave className="email_editBt" text={"ZAPISZ"} onClick={this.ChangeEditMode.bind(this, 2)}/>
                            })()
                        }
                        <InputNormal className="huge_intput_user" types={"email"} Blocked={!this.state.EmailMode} Value={this.props.UserData.Email} text={"aktualny adres e-mail"}/>
                        <InputNormal ref={this.EmailInput} className="huge_intput_user" style={{ opacity: !this.state.EmailMode &&  "0.5"}} onChange={e => this.state.EmailReset.Email = e} types={"email"} Blocked={!this.state.EmailMode} text={"nowy adres e-mail"}/>
                        <InputNormal ref={this.REmailInput} className="huge_intput_user" style={{ opacity: !this.state.EmailMode &&  "0.5"}} onChange={e => this.state.EmailReset.rEmail = e} types={"email"} Blocked={!this.state.EmailMode} text={"powtórz adres e-mail"}/>
                    </div>
                    <div style={{display: "block"}} className="UserDate_form">
                        <h2>Usuń konto</h2>
                        <Buttons.Transparentbutton onClick={this.RemoveAcount.bind(this)}  style={
                            {
                                position: "relative",
                                marginTop: "40px",
                                textAlign: "center"
                            }
                        } text={"USUŃ KONTO"}/>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{padding: "0 0 100px 0"}} className="WorkViewNormal">
                    <div style={{marginTop: "0"}} className="UserDate_form">
                        <InputNormal style={{width: "250px", opacity: !this.state.CompanyMode &&  "0.5"}} text={"NIP"} Blocked={true} onChange={e => this.state.Company.NIP = e} Value={this.props.UserData.NIP}/>
                        {
                            this.state.CompanyMode && <Buttons.Redbutton onClick={this.DownloadFromGUS.bind(this)} style={
                                {
                                    position: "absolute",
                                    left: "300px",
                                    top: "5px",
                                    width: "250px"
                                }
                            } text={"Aktualizuj dane z GUS"}/>
                        }
                        {

                            (() => {
                                if (!this.state.CompanyMode) 
                                    return <Buttons.TransparentbuttonGear  style={
                                            {
                                                position: "absolute",
                                                left: "370px",
                                                top: "5px"
                                            }
                                        } text={"EDYTUJ"} onClick={this.ChangeEditMode.bind(this, 3)}/>
                                else return <Buttons.RedbuttonSave style={
                                    {
                                        position: "absolute",
                                        left: "601px",
                                        top: "5px"
                                    }
                                } text={"ZAPISZ"} onClick={this.ChangeEditMode.bind(this, 3)}/>
                            })()
                        }

                        
                        <InputNormal ref={this.CompanyName_input} className="huge_intput_user" style={{marginTop: "55px", opacity: !this.state.CompanyMode &&  "0.5"}} Blocked={!this.state.CompanyMode}  onChange={e => {this.state.Company.CompanyName = e}} text={"nazwa firmy"} Value={this.props.UserData.CompanyName}/>
                        <InputNormal ref={this.CompanyRoad_input} className="huge_intput_user" style={{ opacity: !this.state.CompanyMode &&  "0.5"}} Blocked={!this.state.CompanyMode}  onChange={e => this.state.Company.CompanyRoad = e} text={"ulica"} Value={this.props.UserData.CompanyRoad}/>
                        <InputNormal ref={this.CompanyRoadNumber_input} className="huge_intput_user" style={{ opacity: !this.state.CompanyMode &&  "0.5"}} Blocked={!this.state.CompanyMode} onChange={e => this.state.Company.CompanyRoadNumber = e} text={"numer budynku"} Value={this.props.UserData.CompanyRoadNumber}/>
                        <InputNormal ref={this.CompanyRoadCity_input} className="huge_intput_user" style={{ opacity: !this.state.CompanyMode &&  "0.5"}} Blocked={!this.state.CompanyMode} onChange={e => this.state.Company.CompanyRoadCity = e} text={"miasto"} Value={this.props.UserData.CompanyRoadCity}/>
                        <InputNormal ref={this.CompanyZipCode_input} className="huge_intput_user" style={{ opacity: !this.state.CompanyMode &&  "0.5"}} Blocked={!this.state.CompanyMode} onChange={e => this.state.Company.CompanyZipCode = e} text={"kod pocztowy"} Value={this.props.UserData.CompanyZipCode}/>
                    </div>
                    {/* <div style={{display: "block"}} className="UserDate_form">
                        <h2>Dane bankowe</h2>
                    </div>
                    <table  style={{marginTop: "0", minWidth: "1100px"}}>
                        <thead>
                            <tr>
                                <th onClick={this.SortItems.bind(this,1)}>
                                    <a style={{textAlign: "left", paddingLeft: '30px'}}>Nazwa banku
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </th>
                                <th onClick={this.SortItems.bind(this,2)}>
                                    <a>
                                        Numer rachunku
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>    
                                    </a>
                                </th>
                                <th onClick={this.SortItems.bind(this,3)}>
                                    <a>
                                        Waluta
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>    
                                    </a>
                                </th>
                                <th onClick={this.SortItems.bind(this,4)}>
                                    <a>
                                        Domyślny
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </th>
                                <th style={{width: "180px"}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.RenderAcoutns()
                            }
                            <tr style={{backgroundColor: this.state.ShowAdd ? "" : "transparent"  }} className={this.state.ShowAdd ? "WorkViewNormal_selected" : ""}>
                                <th colSpan={"5"}>
                                    <table style={{width: "100%"}}>
                                    <tbody>
                                        {
                                            !this.state.ShowAdd && <tr colSpan="5">
                                                <th colSpan={"5"}>
                                                    <Buttons.Redbutton onClick={ev => this.ShowAddMenu(ev)} style={{float: "right",marginTop: "20px"}} text={"DODAJ NOWY"}/>
                                                </th>
                                            </tr>
                                        }
                                        <tr className="SendedDocuments_row_additionals" style={{display: this.state.ShowAdd ? "" : "none" }} colSpan="5">
                                            <td style={{paddingLeft: "11px", paddingTop: "15px"}} className="WorkViewNormal_selected_types" colSpan={"5"}>
                                                <InputNormal ref={this.BankNameInput} className="UserDate_inputNormal" onChange={e => this.state.Bank.BankName = e} style={{width: "400px", display: "inline-block", marginRight: "60px"}} text={"Nazwa banku"}/>
                                                <InputNormal ref={this.BankNumberInput} className="UserDate_inputNormal" onChange={e => this.state.Bank.BankNumber = e} style={{width: "400px", display: "inline-block"}} text={"Numer rachunku"}/>
                                            </td>
                                        </tr>
                                        <tr className="SendedDocuments_row_additionals" style={{display:  this.state.ShowAdd ? "" : "none"}} colSpan="5">
                                            <td style={{paddingLeft: "11px"}} className="WorkViewNormal_selected_types" colSpan={"5"}>
                                                <InputSelect className={"UserDate_inputNormal"} items={["PLN", "EUR", "USD"]} onChange={e => this.state.Bank.BankCurrency = e} style={{marginTop: "27px", width: '250px', display: "inline-block",  marginRight: "213px"}} text={"PLN"}/>
                                                <Chexbox.CheckboxBt style={{display: "inline-block", transform: "unset", top: "10px"}} onChange={e => this.state.Bank.DefaultBank = e.target.checked} className="InputNormal_Box_Search_filtres_box_checkbox" text={"Ustaw rachunek jako domyślny"}/>
                                            </td>
                                        </tr>
                                        <tr className="SendedDocuments_row_additionals" style={{display:  this.state.ShowAdd ? "" : "none"}} colSpan="5">
                                            <td style={{paddingLeft: "11px"}} className="WorkViewNormal_selected_types" colSpan={"5"}>
                                                <Buttons.RedbuttonSave style={{display: "inline-block", float: "right", marginTop: "10px"}} onClick={this.AddBankAccount.bind(this)}   text={"ZAPISZ"}/>
                                                <Buttons.TransparentbuttonClose style={{color: "white", display: "inline-block", float: "right", marginTop: "10px", marginRight: "26px", paddingLeft: "30px"}} onClick={this.CloseAddMenu.bind(this)} className={"UserDate_transparent_buttons"} text={"ZAMKNIJ"}/>
                                            </td>      
                                        </tr>
                                    </tbody>
                                </table>
                                </th>
                            </tr>
                        </tbody>
                    </table> */}
                    
                </div>
            )
        }

    }

    render() {
        return (
            <div style={{paddingBottom: "100px"}} className="WorkViewNormal">
                <div className="WorkViewNormal_header">
                    <h1 onClick={e => this.ChangeView(e)} style={
                        {
                            width: "fit-content",
                            display: "inline",
                            userSelect: "none",
                            cursor: "pointer",
                            transition: ".3s",
                            whiteSpace: "nowrap"
                        }
                    }>Dane użytkownika</h1>
                    <h1 className="WorkViewNormal_header_user_right" style={{
                        opacity: ".5"
                    }} onClick={e => this.ChangeView(e)}>Dane firmy</h1>
                </div>
                {
                    this.RenderView()
                }
            </div>
        )
    }

}


export default UserProfile