import "../CSS/Checkbox.css"
import React, {useRef} from 'react';

export default {

    

    CheckboxBt (props) {
        
        return (
            <label style={props.style} className={"container " + props.className}>{props.text}
                <input type="checkbox" ref={props.innerref} onClick={props.onClick} onChange={props.onChange} defaultChecked={props.checked || false}/>                
                <span className="checkmark"></span>
                <span className="checkmark_border"></span>
            </label>
        )
    },




}   