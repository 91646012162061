import React from "react"
import { Navigate } from "react-router-dom"
import Authorize from "./Authorize"

export class ProtectedRoute extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            Logged: false,
            Checked: false,
            UserData: null
        }

    }

    componentDidMount() {
        Authorize.CheckAuthorize().then(
            data => {
                this.state.UserData = data
                if (data !== null) this.setState({Logged: true,UserData: data, Checked: true})
                else this.setState({Logged: false,Checked: true,UserData: null})
                
            }
        ).catch(() => {
            this.setState({Logged: false,Checked: true,UserData: null})
        })
    }

    render() {
        if (this.state.Checked) {
            if (this.state.Logged) 
                return (React.cloneElement(this.props.children, {UserData : this.state.UserData, history: this.props.history}))
            else return (<Navigate replace to={'/'}/>) 
        } else return (<h1 style={{display: 'block', position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", textAlign: "center"}}>Autoryzacja w toku</h1>)
    }

}