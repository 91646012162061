import React,{Component} from "react";
import ReactDOM from 'react-dom';
import "../CSS/Login.css"
import Logo from "../Images/img.png"
import PowerBy from "../Images/img_1.png"
import LoginTextInputs,{InputLogin, InputPassword} from "../Inputs/LoginTextInputs";
import Buttons from "../Inputs/Buttons";
import Chexbox from "../Inputs/Chexbox";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Authorize from "../Utilis/Authorize";

class LoginPage extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            Login: '',
            Password: '',
            Auto: false,
            LoginRef: React.createRef(),
            PasswordRef: React.createRef(),
        }
    }

    componentDidMount() {
        if (this.state.LoginRef.current !== null) {
            ReactDOM.findDOMNode(this.state.LoginRef.current).addEventListener("keydown", (event) => {
                if (event.key == "Enter") {
                    if (this.state.PasswordRef.current !== null) this.state.PasswordRef.current.state.input.current.focus() 
                }
            })
        }
        if (this.state.PasswordRef.current !== null) {
            ReactDOM.findDOMNode(this.state.PasswordRef.current).addEventListener("keydown", (event) => {
                if (event.key == "Enter") {
                    this.LoginClick()
                }
            })
        }
        Authorize.CheckAuthorize().then(
            resp => {
                if (resp !== null) {
                    window.location.href = '/main/dashboard'
                }
            }
        )
    }

    LoginClick() {
        // console.log(this.state.Login, this.state.Password)
        Authorize.Authorize(this.state.Login, this.state.Password, this.state.Auto)
            .then(e => {
                if (e) {
                    window.location.href = '/main/dashboard'
                } else {
                    if (this.state.LoginRef.current !== undefined) {
                        this.state.LoginRef.current.state.err = "Nie poprawne dane!"
                    }
                    if (this.state.PasswordRef.current !== undefined) {
                        this.state.PasswordRef.current.state.err = "Nie poprawne dane!"
                    }
                    this.forceUpdate()
                }
            })
    }        

    render() {
        return (<SimpleBar autoHide={true} style={{width: "100%", height: "100vh", overflowX: "hidden"}}>
            <div className={"Login_BackGround"}>
                <svg width="3133" height="4124" viewBox="0 0 3133 4124" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.01" d="M1689.14 2497.57C1791.63 2532.05 2039.22 2690.9 2209.69 3050.44C2380.16 3409.99 2607.93 3540.37 2700.51 3560.62C2752.22 3574.64 2897.75 3649.98 3066.14 3839.21" stroke="#E3E7E6" stroke-width="0.0201342"/>
                    <path opacity="0.0100671" d="M1670.56 2472.02C1771.75 2509.98 2013.53 2677.12 2171.17 3041.99C2328.81 3406.87 2551.79 3544.9 2643.58 3568.31C2694.76 3584.09 2837.51 3664.33 2999.08 3859.08" stroke="#E1E6E6" stroke-width="0.0402685"/>
                    <path opacity="0.0151007" d="M1652.7 2446.15C1752.47 2487.54 1988.17 2662.76 2132.81 3032.51C2277.46 3402.25 2495.4 3547.76 2586.29 3574.3C2636.87 3591.82 2776.68 3676.86 2931.26 3876.88" stroke="#E0E6E5" stroke-width="0.0604027"/>
                    <path opacity="0.0201342" d="M1635.58 2419.99C1733.81 2464.76 1963.16 2647.83 2094.66 3021.99C2226.16 3396.14 2438.81 3548.94 2528.7 3578.57C2578.62 3597.81 2715.33 3687.55 2862.74 3892.58" stroke="#DFE5E4" stroke-width="0.0805369"/>
                    <path opacity="0.0251678" d="M1619.2 2393.55C1715.78 2441.64 1938.52 2632.35 2056.74 3010.44C2174.97 3388.54 2382.08 3548.44 2470.86 3581.13C2520.07 3602.06 2653.52 3696.38 2793.6 3906.17" stroke="#DEE5E4" stroke-width="0.100671"/>
                    <path opacity="0.0302013" d="M1603.57 2366.87C1698.4 2418.22 1914.26 2616.32 2019.1 2997.89C2123.93 3379.46 2325.28 3546.26 2412.84 3581.97C2461.28 3604.56 2591.31 3703.34 2723.92 3917.64" stroke="#DCE4E3" stroke-width="0.120805"/>
                    <path opacity="0.0352349" d="M1588.7 2339.97C1681.66 2394.52 1890.41 2599.76 1981.76 2984.33C2073.11 3368.9 2268.45 3542.4 2354.7 3581.07C2402.31 3605.31 2528.78 3708.42 2653.76 3926.95" stroke="#DBE4E3" stroke-width="0.14094"/>
                    <path opacity="0.0402685" d="M1574.6 2312.88C1665.58 2370.56 1866.99 2582.69 1944.77 2969.78C2022.55 3356.88 2211.67 3536.85 2296.5 3578.45C2343.23 3604.29 2465.99 3711.61 2583.21 3934.11" stroke="#DAE3E2" stroke-width="0.161074"/>
                    <path opacity="0.045302" d="M1561.28 2285.63C1650.17 2346.36 1844 2565.12 1908.15 2954.26C1972.3 3343.4 2154.98 3529.62 2238.3 3574.09C2284.09 3601.51 2403.01 3712.91 2512.35 3939.1" stroke="#D8E3E1" stroke-width="0.181208"/>
                    <path opacity="0.0503356" d="M1548.73 2258.24C1635.44 2321.95 1821.48 2547.06 1871.95 2937.77C1922.41 3328.48 2098.45 3520.73 2180.16 3568.01C2224.96 3596.98 2339.9 3712.31 2441.24 3941.91" stroke="#D7E2E1" stroke-width="0.201342"/>
                    <path opacity="0.0553691" d="M1536.97 2230.74C1621.39 2297.36 1799.43 2528.54 1836.19 2920.34C1872.94 3312.13 2042.14 3510.17 2122.14 3560.21C2165.9 3590.68 2276.72 3709.81 2369.96 3942.54" stroke="#D6E2E0" stroke-width="0.221477"/>
                    <path opacity="0.0604027" d="M1525.99 2203.15C1608.03 2272.59 1777.88 2509.58 1800.91 2901.98C1823.94 3294.37 1986.1 3497.96 2064.3 3550.7C2106.97 3582.64 2213.56 3705.42 2298.6 3940.99" stroke="#D4E1DF" stroke-width="0.241611"/>
                    <path opacity="0.0654362" d="M1515.8 2175.51C1595.37 2247.69 1756.83 2490.18 1766.14 2882.7C1775.44 3275.23 1930.39 3484.11 2006.7 3539.48C2048.23 3572.85 2150.46 3699.13 2227.22 3937.26" stroke="#D3E1DF" stroke-width="0.261745"/>
                    <path opacity="0.0704698" d="M1506.41 2147.84C1583.41 2222.67 1736.31 2470.37 1731.91 2862.54C1727.51 3254.71 1875.07 3468.63 1949.41 3526.57C1989.74 3561.33 2087.51 3690.95 2155.91 3931.34" stroke="#D2E1DE" stroke-width="0.281879"/>
                    <path opacity="0.0755034" d="M1497.8 2120.18C1572.15 2197.56 1716.32 2450.17 1698.25 2841.51C1680.18 3232.84 1820.2 3451.54 1892.47 3511.97C1931.57 3548.08 2024.76 3680.9 2084.73 3923.26" stroke="#D1E0DD" stroke-width="0.302013"/>
                    <path opacity="0.0805369" d="M1489.99 2092.54C1561.59 2172.39 1696.88 2429.59 1665.2 2819.62C1633.51 3209.65 1765.83 3432.87 1835.95 3495.72C1873.77 3533.13 1962.27 3668.97 2013.78 3913.01" stroke="#CFE0DD" stroke-width="0.322148"/>
                    <path opacity="0.0855705" d="M1482.97 2064.95C1551.75 2147.17 1678.01 2408.67 1632.78 2796.91C1587.55 3185.16 1712.02 3412.62 1779.91 3477.82C1816.4 3516.49 1900.13 3655.19 1943.11 3900.61" stroke="#CEDFDC" stroke-width="0.342282"/>
                    <path opacity="0.090604" d="M1476.73 2037.45C1542.61 2121.94 1659.71 2387.4 1601.01 2773.4C1542.32 3159.4 1658.81 3390.82 1724.4 3458.29C1759.52 3498.17 1838.38 3639.56 1872.81 3886.07" stroke="#CDDFDC" stroke-width="0.362416"/>
                    <path opacity="0.0956376" d="M1471.28 2010.06C1534.18 2096.71 1641.99 2365.83 1569.94 2749.11C1497.89 3132.39 1606.28 3367.5 1669.48 3437.15C1703.19 3478.2 1777.09 3622.11 1802.94 3869.4" stroke="#CBDEDB" stroke-width="0.38255"/>
                    <path opacity="0.100671" d="M1466.6 1982.81C1526.47 2071.52 1624.87 2343.96 1539.58 2724.06C1454.29 3104.17 1554.45 3342.69 1615.2 3414.43C1647.47 3456.59 1716.33 3602.85 1733.59 3850.63" stroke="#CADEDA" stroke-width="0.402685"/>
                    <path opacity="0.105705" d="M1462.7 1955.72C1519.45 2046.38 1608.36 2321.82 1509.96 2698.29C1411.56 3074.76 1503.4 3316.4 1561.62 3390.15C1592.41 3433.38 1656.16 3581.81 1664.83 3829.78" stroke="#C9DDDA" stroke-width="0.422819"/>
                    <path opacity="0.110738" d="M1459.57 1928.82C1513.15 2021.33 1592.46 2299.44 1481.1 2671.82C1369.75 3044.2 1453.16 3288.67 1508.79 3364.35C1538.06 3408.58 1596.64 3559.01 1596.72 3806.87" stroke="#C8DDD9" stroke-width="0.442953"/>
                    <path opacity="0.115772" d="M1457.2 1902.14C1507.54 1996.38 1577.18 2276.83 1453.04 2644.68C1328.89 3012.53 1403.79 3259.52 1456.77 3337.04C1484.49 3382.23 1537.82 3534.48 1529.34 3781.94" stroke="#C6DCD8" stroke-width="0.463087"/>
                    <path opacity="0.120805" d="M1455.58 1875.7C1502.63 1971.57 1562.54 2254.01 1425.78 2616.89C1289.02 2979.77 1355.34 3229.01 1405.6 3308.26C1431.74 3354.36 1479.78 3508.24 1462.76 3754.99" stroke="#C5DCD8" stroke-width="0.483221"/>
                    <path opacity="0.125839" d="M1454.7 1849.54C1498.41 1946.91 1548.53 2231.01 1399.35 2588.49C1250.18 2945.97 1307.85 3197.15 1355.34 3278.05C1379.87 3324.99 1422.56 3480.32 1397.04 3726.08" stroke="#C4DBD7" stroke-width="0.503356"/>
                    <path opacity="0.130872" d="M1454.56 1823.67C1494.88 1922.43 1535.17 2207.86 1373.78 2559.51C1212.4 2911.16 1261.37 3163.98 1306.03 3246.43C1328.93 3294.16 1366.23 3450.75 1332.26 3695.23" stroke="#C2DBD6" stroke-width="0.52349"/>
                    <path opacity="0.135906" d="M1455.14 1798.12C1492.02 1898.15 1522.45 2184.56 1349.08 2529.98C1175.72 2875.39 1215.94 3129.54 1257.73 3213.44C1278.96 3261.91 1310.84 3419.58 1268.47 3662.48" stroke="#C1DAD6" stroke-width="0.543624"/>
                    <path opacity="0.14094" d="M1456.43 1772.92C1489.84 1874.1 1510.38 2161.16 1325.28 2499.92C1140.17 2838.68 1171.61 3093.88 1210.47 3179.13C1230.02 3228.27 1256.44 3386.83 1205.75 3627.86" stroke="#C0DAD5" stroke-width="0.563758"/>
                    <path opacity="0.145973" d="M1458.42 1748.09C1488.32 1850.31 1498.97 2137.66 1302.38 2469.38C1105.78 2801.09 1128.42 3057.02 1164.31 3143.52C1182.15 3193.28 1203.1 3352.54 1144.16 3591.42" stroke="#BEDAD5" stroke-width="0.583893"/>
                    <path opacity="0.151007" d="M1461.1 1723.66C1487.46 1826.79 1488.22 2114.1 1280.4 2438.38C1072.58 2762.66 1086.4 3019.02 1119.28 3106.66C1135.4 3156.98 1150.85 3316.75 1083.75 3553.2" stroke="#BDD9D4" stroke-width="0.604027"/>
                    <path opacity="0.15604" d="M1464.45 1699.65C1487.24 1803.56 1478.13 2090.5 1259.36 2406.96C1040.6 2723.43 1045.59 2979.91 1075.44 3068.59C1089.81 3119.42 1099.76 3279.5 1024.6 3513.25" stroke="#BCD9D3" stroke-width="0.624161"/>
                    <path opacity="0.161074" d="M1468.45 1676.08C1487.65 1780.65 1468.69 2066.87 1239.28 2375.15C1009.86 2683.44 1006.04 2939.74 1032.81 3029.36C1045.42 3080.62 1049.86 3240.84 966.754 3471.6" stroke="#BBD8D3" stroke-width="0.644295"/>
                    <path opacity="0.166107" d="M1473.1 1652.97C1488.68 1758.09 1459.92 2043.25 1220.16 2342.99C980.389 2642.73 967.778 2898.56 991.444 2989.01C1002.28 3040.65 1001.22 3200.81 910.271 3428.32" stroke="#B9D8D2" stroke-width="0.66443"/>
                    <path opacity="0.171141" d="M1478.36 1630.35C1490.33 1735.88 1451.81 2019.66 1202.01 2310.51C952.212 2601.37 930.835 2856.42 951.371 2947.58C960.424 2999.54 953.866 3159.46 855.208 3383.44" stroke="#B8D7D1" stroke-width="0.684564"/>
                    <path opacity="0.176174" d="M1484.24 1608.24C1492.57 1714.07 1444.35 1996.12 1184.85 2277.75C925.349 2559.38 895.244 2813.35 912.629 2905.13C919.89 2957.34 907.852 3116.82 801.617 3337.03" stroke="#B7D7D1" stroke-width="0.704698"/>
                    <path opacity="0.181208" d="M1490.7 1586.66C1495.39 1692.65 1437.55 1972.65 1168.69 2244.74C899.818 2516.82 861.035 2769.41 875.252 2861.7C880.713 2914.11 863.218 3072.96 749.55 3289.14" stroke="#B5D6D0" stroke-width="0.724832"/>
                    <path opacity="0.186242" d="M1497.73 1565.63C1498.78 1671.66 1431.41 1949.28 1153.52 2211.51C875.64 2473.74 828.237 2724.66 839.272 2817.34C842.928 2869.88 820.005 3027.93 699.055 3239.81" stroke="#B4D6CF" stroke-width="0.744966"/>
                    <path opacity="0.191275" d="M1505.31 1545.17C1502.72 1651.12 1425.91 1926.03 1139.37 2178.11C852.829 2430.19 796.877 2679.14 804.718 2772.1C806.568 2824.71 778.25 2981.76 650.18 3189.12" stroke="#B3D5CF" stroke-width="0.765101"/>
                    <path opacity="0.196309" d="M1513.42 1525.3C1507.2 1631.03 1421.05 1902.92 1126.23 2144.56C831.4 2386.2 766.978 2632.9 771.62 2726.04C771.662 2778.65 737.992 2934.53 602.97 3137.12" stroke="#B1D5CE" stroke-width="0.785235"/>
                    <path opacity="0.201342" d="M1522.04 1506.03C1512.2 1611.43 1416.83 1879.97 1114.1 2110.91C811.367 2341.85 738.564 2585.99 740.003 2679.2C738.24 2731.76 699.265 2886.28 557.468 3083.86" stroke="#B0D4CD" stroke-width="0.805369"/>
                    <path opacity="0.206376" d="M1531.14 1487.39C1517.7 1592.33 1413.24 1857.22 1102.99 2077.19C792.74 2297.16 711.655 2538.48 709.893 2631.64C706.329 2684.08 662.102 2837.06 513.713 3029.42" stroke="#AFD4CD" stroke-width="0.825503"/>
                    <path opacity="0.211409" d="M1540.71 1469.38C1523.68 1573.75 1410.28 1834.67 1092.9 2043.44C775.529 2252.2 686.27 2490.41 681.312 2583.41C675.952 2635.68 626.534 2786.94 471.746 2973.85" stroke="#AED4CC" stroke-width="0.845638"/>
                    <path opacity="0.216443" d="M1550.73 1452.04C1530.14 1555.7 1407.93 1812.36 1083.84 2009.69C759.741 2207.02 662.427 2441.83 654.281 2534.57C647.133 2586.6 592.589 2735.97 431.601 2917.22" stroke="#ACD3CC" stroke-width="0.865772"/>
                    <path opacity="0.221477" d="M1561.16 1435.36C1537.04 1538.2 1406.19 1790.3 1075.79 1975.98C745.382 2161.65 640.14 2392.81 628.819 2485.18C619.893 2536.91 560.296 2684.21 393.313 2859.59" stroke="#ABD3CB" stroke-width="0.885906"/>
                    <path opacity="0.22651" d="M1571.99 1419.37C1544.36 1521.27 1405.04 1768.51 1068.75 1942.34C732.456 2116.17 619.422 2343.4 604.942 2435.29C594.251 2486.65 529.677 2631.71 356.914 2801.04" stroke="#AAD2CA" stroke-width="0.90604"/>
                    <path opacity="0.231544" d="M1583.19 1404.08C1552.1 1504.91 1404.49 1747.02 1062.73 1908.82C720.965 2070.61 600.285 2293.65 582.665 2384.95C570.223 2435.89 500.757 2578.55 322.432 2741.62" stroke="#A8D2CA" stroke-width="0.926175"/>
                    <path opacity="0.236577" d="M1594.73 1389.5C1560.22 1489.15 1404.5 1725.85 1057.71 1875.44C710.909 2025.02 582.738 2243.62 562.002 2334.22C547.825 2384.68 473.556 2524.77 289.894 2681.41" stroke="#A7D1C9" stroke-width="0.946309"/>
                    <path opacity="0.241611" d="M1606.6 1375.64C1568.71 1473.99 1405.08 1705.01 1053.69 1842.24C702.287 1979.47 566.788 2193.37 542.963 2283.17C527.069 2333.09 448.091 2470.44 259.324 2620.48" stroke="#A6D1C8" stroke-width="0.966443"/>
                    <path opacity="0.246644" d="M1618.77 1362.51C1577.55 1459.46 1406.22 1684.53 1050.66 1809.26C695.096 1933.99 552.439 2142.95 525.556 2231.84C507.967 2281.16 424.38 2415.63 230.745 2558.9" stroke="#A5D0C8" stroke-width="0.986577"/>
                    <path opacity="0.251678" d="M1631.2 1350.13C1586.7 1445.55 1407.89 1664.42 1048.61 1776.53C689.331 1888.64 539.696 2092.42 509.789 2180.3C490.526 2228.97 402.435 2360.39 204.175 2496.73" stroke="#A3D0C7" stroke-width="1.00671"/>
                    <path opacity="0.256711" d="M1643.88 1338.51C1596.16 1432.29 1410.08 1644.71 1047.53 1744.09C684.984 1843.48 528.56 2041.84 495.666 2128.6C474.754 2176.56 382.27 2304.78 179.631 2434.05" stroke="#A2CFC6" stroke-width="1.02685"/>
                    <path opacity="0.261745" d="M1656.78 1327.64C1605.9 1419.68 1412.79 1625.41 1047.42 1711.97C682.047 1798.53 519.028 1991.26 483.19 2076.81C460.655 2124 363.893 2248.88 157.128 2370.93" stroke="#A1CFC6" stroke-width="1.04698"/>
                    <path opacity="0.266779" d="M1669.87 1317.54C1615.89 1407.73 1415.99 1606.54 1048.25 1680.21C680.511 1753.87 511.099 1940.74 472.361 2024.97C448.231 2071.34 347.312 2192.74 136.677 2307.44" stroke="#9FCEC5" stroke-width="1.06711"/>
                    <path opacity="0.271812" d="M1683.12 1308.21C1626.11 1396.45 1419.67 1588.11 1050.01 1648.83C680.362 1709.54 504.768 1890.34 463.178 1973.16C437.483 2018.64 332.532 2136.42 118.288 2243.65" stroke="#9ECEC5" stroke-width="1.08725"/>
                    <path opacity="0.276846" d="M1696.52 1299.67C1636.54 1385.85 1423.81 1570.15 1052.7 1617.86C681.588 1665.58 500.028 1840.11 455.638 1921.41C428.409 1965.97 319.556 2080 101.967 2179.65" stroke="#9DCEC4" stroke-width="1.10738"/>
                    <path opacity="0.281879" d="M1710.02 1291.9C1647.15 1375.93 1428.4 1552.67 1056.28 1587.35C684.172 1622.04 496.871 1790.11 449.734 1869.8C421.006 1913.38 308.384 2023.52 87.7181 2115.49" stroke="#9BCDC3" stroke-width="1.12752"/>
                    <path opacity="0.286913" d="M1723.6 1284.93C1657.93 1366.71 1433.41 1535.68 1060.75 1557.33C688.098 1578.98 495.284 1740.39 445.46 1818.38C415.268 1860.93 299.015 1967.06 75.5431 2051.26" stroke="#9ACDC3" stroke-width="1.14765"/>
                    <path opacity="0.291946" d="M1737.24 1278.74C1668.84 1358.18 1438.84 1519.2 1066.09 1527.81C693.346 1536.43 495.257 1691 442.806 1767.21C411.186 1808.68 291.446 1910.68 65.4409 1987.02" stroke="#99CCC2" stroke-width="1.16779"/>
                    <path opacity="0.29698" d="M1750.91 1273.34C1679.86 1350.34 1444.66 1503.24 1072.28 1498.84C699.897 1494.44 496.774 1642.01 441.76 1716.34C408.752 1756.68 285.67 1854.44 57.4081 1922.84" stroke="#98CCC1" stroke-width="1.18792"/>
                    <path opacity="0.302013" d="M1764.58 1268.74C1690.96 1343.22 1450.85 1487.82 1079.29 1470.45C707.729 1453.07 499.82 1593.47 442.31 1665.84C407.953 1704.99 281.679 1798.41 51.4385 1858.81" stroke="#96CBC1" stroke-width="1.20805"/>
                    <path opacity="0.307047" d="M1778.21 1264.93C1702.13 1336.79 1457.39 1472.95 1087.11 1442.65C716.818 1412.35 504.374 1545.42 444.439 1615.74C408.775 1653.68 279.463 1742.64 47.5236 1794.99" stroke="#95CBC0" stroke-width="1.22819"/>
                    <path opacity="0.312081" d="M1791.8 1261.9C1713.34 1331.08 1464.27 1458.64 1095.71 1415.48C727.138 1372.32 510.419 1497.92 448.13 1566.12C411.203 1602.79 279.009 1687.2 45.6523 1731.45" stroke="#94CABF" stroke-width="1.24832"/>
                    <path opacity="0.317114" d="M1805.3 1259.67C1724.56 1326.07 1471.47 1444.91 1105.07 1388.97C738.664 1333.03 517.931 1451.03 453.364 1517.02C415.218 1552.39 280.303 1632.15 45.8112 1668.27" stroke="#92CABF" stroke-width="1.26846"/>
                    <path opacity="0.322148" d="M1818.69 1258.21C1735.76 1321.78 1478.95 1431.75 1115.16 1363.14C751.368 1294.52 526.887 1404.79 460.121 1468.49C420.802 1502.52 283.328 1577.55 47.9843 1605.51" stroke="#91C9BE" stroke-width="1.28859"/>
                    <path opacity="0.327181" d="M1831.95 1257.54C1746.93 1318.19 1486.71 1419.19 1125.97 1338.01C765.219 1256.84 537.261 1359.25 468.376 1420.6C427.932 1453.24 288.065 1523.46 52.1528 1543.24" stroke="#90C9BE" stroke-width="1.30872"/>
                    <path opacity="0.332215" d="M1845.04 1257.64C1758.04 1315.3 1494.72 1407.23 1137.45 1313.62C780.187 1220.01 549.027 1314.46 478.106 1373.39C436.585 1404.6 294.494 1469.93 58.2965 1481.54" stroke="#8FC8BD" stroke-width="1.32886"/>
                    <path opacity="0.337248" d="M1857.93 1258.51C1769.06 1313.12 1502.96 1395.88 1149.6 1289.98C796.24 1184.08 562.155 1270.47 489.283 1326.9C446.736 1356.66 302.591 1417.04 66.3915 1420.46" stroke="#8DC8BC" stroke-width="1.34899"/>
                    <path opacity="0.342282" d="M1870.61 1260.13C1779.96 1311.64 1511.41 1385.15 1162.38 1267.12C813.345 1149.08 576.616 1227.33 501.88 1281.2C458.358 1309.47 312.332 1364.82 76.4129 1360.09" stroke="#8CC8BC" stroke-width="1.36913"/>
                    <path opacity="0.347315" d="M1883.05 1262.51C1790.74 1310.86 1520.04 1375.04 1175.75 1245.05C831.466 1115.06 592.377 1185.07 515.867 1236.33C471.421 1263.08 323.689 1313.35 88.3324 1300.47" stroke="#8BC7BB" stroke-width="1.38926"/>
                    <path opacity="0.352349" d="M1895.22 1265.64C1801.35 1310.76 1528.83 1365.57 1189.7 1223.8C850.569 1082.04 609.403 1143.76 531.212 1192.34C485.896 1217.53 336.634 1262.67 102.12 1241.69" stroke="#89C7BA" stroke-width="1.4094"/>
                    <path opacity="0.357383" d="M1907.08 1269.5C1811.78 1311.35 1537.77 1356.72 1204.19 1203.39C870.615 1050.06 627.662 1103.42 547.882 1149.27C501.75 1172.88 351.137 1212.84 117.744 1183.8" stroke="#88C6BA" stroke-width="1.42953"/>
                    <path opacity="0.362416" d="M1918.63 1274.08C1821.99 1312.62 1546.82 1348.52 1219.19 1183.83C891.567 1019.14 647.115 1064.1 565.843 1107.16C518.949 1129.17 367.164 1163.91 135.169 1126.86" stroke="#87C6B9" stroke-width="1.44966"/>
                    <path opacity="0.36745" d="M1929.83 1279.37C1831.98 1314.55 1555.97 1340.96 1234.68 1165.14C913.386 989.328 667.726 1025.84 585.057 1066.07C537.459 1086.44 384.682 1115.94 154.359 1070.94" stroke="#85C5B8" stroke-width="1.4698"/>
                    <path opacity="0.372483" d="M1940.66 1285.36C1841.72 1317.15 1565.19 1334.05 1250.61 1147.35C936.03 960.643 689.453 988.677 605.487 1026.03C557.241 1044.75 403.653 1068.97 175.275 1016.09" stroke="#84C5B8" stroke-width="1.48993"/>
                    <path opacity="0.377517" d="M1951.09 1292.04C1851.17 1320.4 1574.45 1327.78 1266.96 1130.45C959.46 933.114 712.258 952.653 627.095 987.089C578.257 1004.13 424.042 1023.05 197.876 962.384" stroke="#83C4B7" stroke-width="1.51007"/>
                    <path opacity="0.38255" d="M1961.1 1299.38C1860.32 1324.29 1583.75 1322.17 1283.69 1114.47C983.632 906.767 736.098 917.801 649.839 949.28C600.469 964.63 445.807 978.238 222.12 909.864" stroke="#82C4B7" stroke-width="1.5302"/>
                    <path opacity="0.387584" d="M1970.67 1307.39C1869.15 1328.81 1593.05 1317.2 1300.78 1099.41C1008.51 881.626 760.931 884.155 673.678 912.643C623.834 926.284 468.909 934.571 247.962 858.591" stroke="#80C3B6" stroke-width="1.55034"/>
                    <path opacity="0.392617" d="M1979.78 1316.03C1877.64 1333.95 1602.33 1312.88 1318.18 1085.3C1034.03 857.714 786.711 851.749 698.568 877.214C648.309 889.131 493.304 892.096 275.356 808.617" stroke="#7FC3B5" stroke-width="1.57047"/>
                    <path opacity="0.397651" d="M1988.4 1325.3C1885.76 1339.69 1611.56 1309.21 1335.87 1072.13C1060.17 835.052 813.394 820.613 724.466 843.029C673.851 853.21 518.949 850.856 304.254 759.995" stroke="#7EC2B5" stroke-width="1.5906"/>
                    <path opacity="0.402685" d="M1996.51 1335.17C1893.49 1346.03 1620.73 1306.18 1353.81 1059.92C1086.88 813.66 840.934 790.778 751.326 810.12C700.416 818.554 545.798 810.893 334.605 712.776" stroke="#7CC2B4" stroke-width="1.61074"/>
                    <path opacity="0.407718" d="M2004.09 1345.63C1900.82 1352.95 1629.81 1303.8 1371.96 1048.68C1114.11 793.555 869.283 762.271 779.101 778.519C727.955 785.198 573.803 772.247 366.357 667.006" stroke="#7BC1B3" stroke-width="1.63087"/>
                    <path opacity="0.412752" d="M2011.12 1356.66C1907.71 1360.43 1638.78 1302.05 1390.3 1038.4C1141.81 774.755 898.392 735.118 807.743 748.255C756.423 753.174 602.918 734.955 399.458 622.732" stroke="#7AC1B3" stroke-width="1.65101"/>
                    <path opacity="0.417785" d="M2017.58 1368.24C1914.16 1368.46 1647.62 1300.94 1408.78 1029.11C1169.94 757.274 928.214 709.344 837.206 719.358C785.771 722.512 633.093 699.055 433.854 580" stroke="#78C1B2" stroke-width="1.67114"/>
                    <path opacity="0.422819" d="M2023.45 1380.35C1920.15 1377.03 1656.3 1300.46 1427.37 1020.79C1198.45 741.125 958.698 684.971 867.438 691.853C815.949 693.239 664.276 664.58 469.487 538.851" stroke="#77C0B1" stroke-width="1.69128"/>
                    <path opacity="0.427852" d="M2028.72 1392.97C1925.64 1386.11 1664.8 1300.59 1446.05 1013.46C1227.29 726.318 989.795 662.021 898.389 665.765C846.908 665.384 696.417 631.563 506.3 499.325" stroke="#76C0B1" stroke-width="1.71141"/>
                    <path opacity="0.432886" d="M2033.36 1406.08C1930.64 1395.69 1673.1 1301.35 1464.76 1007.11C1256.42 712.865 1021.45 640.512 930.009 641.116C878.596 638.971 729.462 600.036 544.234 461.461" stroke="#75BFB0" stroke-width="1.73154"/>
                    <path opacity="0.437919" d="M2037.37 1419.65C1935.11 1405.75 1681.18 1302.71 1483.48 1001.74C1285.78 700.772 1053.62 620.462 962.246 617.928C910.96 614.022 763.357 570.027 583.23 425.296" stroke="#73BFB0" stroke-width="1.75168"/>
                    <path opacity="0.442953" d="M2040.72 1433.66C1939.05 1416.27 1689.02 1304.67 1502.18 997.358C1315.34 690.046 1086.24 601.886 995.047 596.22C943.95 590.559 798.049 541.563 623.225 390.862" stroke="#72BEAF" stroke-width="1.77181"/>
                    <path opacity="0.447987" d="M2043.39 1448.09C1942.44 1427.24 1696.59 1307.22 1520.81 993.956C1345.03 680.691 1119.27 584.797 1028.36 576.008C977.511 568.601 833.482 514.669 664.158 358.192" stroke="#71BEAE" stroke-width="1.79195"/>
                    <path opacity="0.45302" d="M2045.38 1462.92C1945.26 1438.63 1703.88 1310.35 1539.34 991.53C1374.81 672.71 1152.64 569.207 1062.13 557.308C1011.59 548.166 869.598 489.368 705.965 327.316" stroke="#6FBDAE" stroke-width="1.81208"/>
                    <path opacity="0.458054" d="M2046.67 1478.12C1947.5 1450.43 1710.86 1314.05 1557.75 990.077C1404.63 666.104 1186.32 555.127 1096.3 540.134C1046.13 529.269 906.342 465.682 748.582 298.26" stroke="#6EBDAD" stroke-width="1.83221"/>
                    <path opacity="0.463087" d="M2047.26 1493.67C1949.14 1462.61 1717.52 1318.31 1575.98 989.59C1434.45 660.872 1220.24 542.563 1130.83 524.498C1081.08 511.923 943.655 443.629 791.944 271.049" stroke="#6DBCAC" stroke-width="1.85235"/>
                    <path opacity="0.468121" d="M2047.11 1509.54C1950.17 1475.15 1723.84 1323.11 1594.02 990.061C1464.21 657.012 1254.35 531.521 1165.65 510.407C1116.38 496.14 981.479 423.227 835.985 245.707" stroke="#6CBCAC" stroke-width="1.87248"/>
                    <path opacity="0.473154" d="M2046.24 1525.7C1950.59 1488.03 1729.79 1328.45 1611.83 991.483C1493.86 654.52 1288.6 522.007 1200.71 497.871C1151.98 481.93 1019.76 404.489 880.639 222.254" stroke="#6ABBAB" stroke-width="1.89262"/>
                    <path opacity="0.478188" d="M2044.62 1542.14C1950.37 1501.23 1735.36 1334.3 1629.36 993.845C1523.37 653.389 1322.92 514.021 1235.95 486.894C1187.83 469.3 1058.43 387.43 925.837 200.707" stroke="#69BBAA" stroke-width="1.91275"/>
                    <path opacity="0.483221" d="M2042.25 1558.82C1949.5 1514.73 1740.53 1340.66 1646.6 997.138C1552.67 653.612 1357.28 507.564 1271.33 477.481C1223.85 458.255 1097.43 372.059 971.514 181.081" stroke="#68BBAA" stroke-width="1.93289"/>
                    <path opacity="0.488255" d="M2039.12 1575.72C1947.98 1528.5 1745.28 1347.52 1663.51 1001.35C1581.73 655.18 1391.61 502.635 1306.78 469.634C1260.01 448.801 1136.71 358.386 1017.6 163.391" stroke="#66BAA9" stroke-width="1.95302"/>
                    <path opacity="0.493289" d="M2035.22 1592.81C1945.81 1542.52 1749.6 1354.84 1680.05 1006.46C1610.49 658.082 1425.87 499.23 1342.25 463.351C1296.25 440.937 1176.2 346.416 1064.03 147.647" stroke="#65BAA9" stroke-width="1.97315"/>
                    <path opacity="0.498322" d="M2030.54 1610.06C1942.96 1556.76 1753.47 1362.63 1696.19 1012.47C1638.92 662.305 1459.98 497.343 1377.68 458.631C1332.5 434.664 1215.85 336.155 1110.73 133.857" stroke="#64B9A8" stroke-width="1.99329"/>
                    <path opacity="0.503356" d="M2025.09 1627.45C1939.43 1571.21 1756.87 1370.86 1711.91 1019.35C1666.95 667.836 1493.92 496.966 1413.02 455.471C1368.71 429.98 1255.59 327.604 1157.63 122.028" stroke="#62B9A7" stroke-width="2.01342"/>
                    <path opacity="0.508389" d="M2018.85 1644.95C1935.21 1585.84 1759.79 1379.52 1727.17 1027.09C1694.55 674.658 1527.61 498.092 1448.22 453.863C1404.83 426.88 1295.37 320.763 1204.67 112.162" stroke="#61B8A7" stroke-width="2.03356"/>
                    <path opacity="0.513423" d="M2011.83 1662.54C1930.31 1600.63 1762.21 1388.58 1741.94 1035.67C1721.67 682.753 1561.02 500.709 1483.22 453.801C1440.8 425.359 1335.12 315.631 1251.77 104.26" stroke="#60B8A6" stroke-width="2.05369"/>
                    <path opacity="0.518456" d="M2004.01 1680.18C1924.71 1615.55 1764.12 1398.04 1756.2 1045.07C1748.27 692.104 1594.08 504.803 1517.97 455.274C1476.57 425.408 1374.79 312.204 1298.88 98.3227" stroke="#5FB7A5" stroke-width="2.07383"/>
                    <path opacity="0.52349" d="M1995.41 1697.84C1918.41 1630.57 1765.51 1407.87 1769.91 1055.28C1774.31 702.689 1626.74 510.361 1552.41 458.271C1512.07 427.017 1414.31 310.476 1345.91 94.3447" stroke="#5DB7A5" stroke-width="2.09396"/>
                    <path opacity="0.528524" d="M1986.01 1715.51C1911.4 1645.67 1766.36 1418.05 1783.05 1066.27C1799.73 714.487 1658.96 517.366 1586.49 462.778C1547.27 430.174 1453.62 310.437 1392.8 92.3203" stroke="#5CB6A4" stroke-width="2.11409"/>
                    <path opacity="0.533557" d="M1975.83 1733.15C1903.69 1660.84 1766.66 1428.57 1795.59 1078.02C1824.51 727.474 1690.69 525.799 1620.16 468.78C1582.1 434.866 1492.68 312.079 1439.48 92.241" stroke="#5BB6A3" stroke-width="2.13423"/>
                    <path opacity="0.538591" d="M1964.85 1750.74C1895.28 1676.03 1766.41 1439.4 1807.5 1090.51C1848.59 741.624 1721.86 535.64 1653.36 476.259C1616.51 441.077 1531.41 315.388 1485.89 94.0959" stroke="#59B5A3" stroke-width="2.15436"/>
                    <path opacity="0.543624" d="M1953.09 1768.24C1886.15 1691.23 1765.58 1450.52 1818.76 1103.72C1871.95 756.913 1752.45 546.869 1686.05 485.198C1650.44 448.789 1569.76 320.351 1531.96 97.8719" stroke="#58B5A2" stroke-width="2.1745"/>
                    <path opacity="0.548658" d="M1940.54 1785.63C1876.32 1706.42 1764.17 1461.92 1829.35 1117.61C1894.53 773.311 1782.39 559.461 1718.18 495.574C1683.85 457.983 1607.68 326.951 1577.62 103.553" stroke="#57B5A2" stroke-width="2.19463"/>
                    <path opacity="0.553691" d="M1927.22 1802.88C1865.78 1721.56 1762.18 1473.57 1839.24 1132.18C1916.3 790.79 1811.65 573.392 1749.69 507.366C1716.69 468.638 1645.11 335.17 1622.8 111.123" stroke="#56B4A1" stroke-width="2.21477"/>
                    <path opacity="0.558725" d="M1913.12 1819.97C1854.54 1736.64 1759.58 1485.45 1848.41 1147.38C1937.23 809.32 1840.17 588.634 1780.54 520.549C1748.9 480.731 1681.98 344.987 1667.44 120.56" stroke="#54B4A0" stroke-width="2.2349"/>
                    <path opacity="0.563758" d="M1898.25 1836.87C1842.59 1751.62 1756.38 1497.54 1856.83 1163.2C1957.28 828.868 1867.91 605.16 1810.67 535.098C1780.43 494.237 1718.25 356.381 1711.48 131.842" stroke="#53B3A0" stroke-width="2.25503"/>
                    <path opacity="0.568792" d="M1882.62 1853.55C1829.94 1766.49 1752.57 1509.82 1864.49 1179.61C1976.41 849.403 1894.83 622.941 1840.05 550.986C1811.24 509.131 1753.87 369.327 1754.85 144.945" stroke="#52B39F" stroke-width="2.27517"/>
                    <path opacity="0.573825" d="M1866.24 1869.99C1816.6 1781.22 1748.14 1522.27 1871.37 1196.58C1994.59 870.89 1920.88 641.944 1868.63 568.182C1841.28 525.384 1788.77 383.799 1797.5 159.841" stroke="#50B29E" stroke-width="2.2953"/>
                    <path opacity="0.578859" d="M1849.11 1886.15C1802.58 1795.79 1743.09 1534.86 1877.44 1214.08C2011.79 893.294 1946.03 662.137 1896.35 586.657C1870.5 542.967 1822.91 399.77 1839.35 176.503" stroke="#4FB29E" stroke-width="2.31544"/>
                    <path opacity="0.583893" d="M1831.26 1902.02C1787.87 1810.16 1737.41 1547.57 1882.69 1232.07C2027.98 916.578 1970.23 683.487 1923.19 606.378C1898.86 561.848 1856.24 417.211 1880.35 194.9" stroke="#4EB19D" stroke-width="2.33557"/>
                    <path opacity="0.588926" d="M1812.67 1917.57C1772.48 1824.33 1731.1 1560.38 1887.11 1250.54C2043.13 940.706 1993.44 705.957 1949.09 627.312C1926.32 581.995 1888.71 436.089 1920.44 214.997" stroke="#4CB19C" stroke-width="2.3557"/>
                    <path opacity="0.59396" d="M1793.38 1932.77C1756.43 1838.25 1724.15 1573.27 1890.68 1269.46C2057.2 965.64 2015.62 729.511 1974.02 649.423C1952.82 603.374 1920.26 456.373 1959.56 236.761" stroke="#4BB09C" stroke-width="2.37584"/>
                    <path opacity="0.598993" d="M1773.39 1947.59C1739.72 1851.92 1716.57 1586.22 1893.37 1288.78C2070.18 991.339 2036.75 754.111 1997.93 672.677C1978.34 625.949 1950.85 478.027 1997.66 260.154" stroke="#4AB09B" stroke-width="2.39597"/>
                    <path opacity="0.604027" d="M1752.72 1962.03C1722.36 1865.31 1708.35 1599.19 1895.19 1308.48C2082.04 1017.76 2056.78 779.718 2020.8 697.033C2002.82 649.684 1980.44 501.015 2034.68 285.137" stroke="#49AF9A" stroke-width="2.41611"/>
                    <path opacity="0.60906" d="M1731.37 1976.04C1704.35 1878.39 1699.49 1612.18 1896.12 1328.53C2092.75 1044.88 2075.68 806.291 2042.57 722.455C2026.24 674.539 2008.97 525.3 2070.56 311.67" stroke="#47AF9A" stroke-width="2.43624"/>
                    <path opacity="0.614094" d="M1709.36 1989.61C1685.73 1891.15 1689.99 1625.15 1896.14 1348.89C2102.3 1072.63 2093.42 833.789 2063.22 748.901C2048.54 700.476 2036.41 550.843 2105.26 339.711" stroke="#46AF99" stroke-width="2.45638"/>
                    <path opacity="0.619128" d="M1686.72 2002.72C1666.48 1903.55 1679.85 1638.09 1895.25 1369.54C2110.65 1100.98 2109.97 862.169 2082.7 776.33C2069.71 727.453 2062.72 577.602 2138.73 369.214" stroke="#45AE99" stroke-width="2.47651"/>
                    <path opacity="0.624161" d="M1663.45 2015.34C1646.63 1915.59 1669.08 1650.97 1893.44 1390.43C2117.8 1129.9 2125.3 891.388 2101 804.699C2089.69 755.429 2087.84 605.537 2170.91 400.134" stroke="#43AE98" stroke-width="2.49664"/>
                    <path opacity="0.629195" d="M1639.58 2027.45C1626.2 1927.24 1657.68 1663.76 1890.7 1411.54C2123.72 1159.33 2139.38 921.401 2118.08 833.966C2108.47 784.359 2111.75 634.602 2201.76 432.424" stroke="#42AD97" stroke-width="2.51678"/>
                    <path opacity="0.634228" d="M1615.12 2039.03C1605.18 1938.47 1645.65 1676.46 1887.02 1432.84C2128.4 1189.22 2152.18 952.163 2133.9 864.085C2126.01 814.201 2134.41 664.754 2231.23 466.034" stroke="#41AD97" stroke-width="2.53691"/>
                    <path opacity="0.639262" d="M1590.09 2050.06C1583.61 1949.27 1632.99 1689.02 1882.41 1454.29C2131.82 1219.55 2163.69 983.628 2148.45 895.01C2142.27 844.909 2155.78 695.947 2259.28 500.914" stroke="#3FAC96" stroke-width="2.55705"/>
                    <path opacity="0.644295" d="M1564.51 2060.52C1561.48 1959.63 1619.72 1701.45 1876.84 1475.85C2133.97 1250.25 2173.88 1015.75 2161.69 926.696C2157.23 876.435 2175.83 728.134 2285.87 537.012" stroke="#3EAC95" stroke-width="2.57718"/>
                    <path opacity="0.649329" d="M1538.4 2070.39C1538.83 1969.51 1605.83 1713.7 1870.34 1497.49C2134.84 1281.29 2182.72 1048.48 2173.6 959.095C2170.87 908.735 2194.52 761.266 2310.96 574.274" stroke="#3DAB95" stroke-width="2.59732"/>
                    <path opacity="0.654362" d="M1511.78 2079.66C1515.67 1978.9 1591.33 1725.76 1862.88 1519.19C2134.42 1312.62 2190.21 1081.77 2184.16 992.16C2183.16 941.758 2211.83 795.294 2334.51 612.646" stroke="#3CAB94" stroke-width="2.61745"/>
                    <path opacity="0.659396" d="M1484.67 2088.3C1492.01 1987.79 1576.24 1737.6 1854.47 1540.9C2132.7 1344.19 2196.32 1115.56 2193.35 1025.84C2194.08 975.458 2227.72 830.169 2356.48 652.071" stroke="#3AAA93" stroke-width="2.63758"/>
                    <path opacity="0.66443" d="M1457.1 2096.3C1467.87 1996.16 1560.55 1749.22 1845.11 1562.59C2129.67 1375.96 2201.04 1149.82 2201.15 1060.09C2203.6 1009.78 2242.17 865.838 2376.84 692.494" stroke="#39AA93" stroke-width="2.65772"/>
                    <path opacity="0.669463" d="M1429.09 2103.65C1443.28 2003.99 1544.28 1760.58 1834.81 1584.23C2125.34 1407.87 2204.34 1184.5 2207.53 1094.85C2211.7 1044.68 2255.15 902.25 2395.57 733.855" stroke="#38A992" stroke-width="2.67785"/>
                    <path opacity="0.674497" d="M1400.66 2110.33C1418.24 2011.27 1527.44 1771.67 1823.56 1605.78C2119.69 1439.89 2206.24 1219.53 2212.49 1130.08C2218.38 1080.11 2266.65 939.353 2412.62 776.095" stroke="#36A992" stroke-width="2.69799"/>
                    <path opacity="0.67953" d="M1371.83 2116.32C1392.79 2017.97 1510.03 1782.46 1811.38 1627.21C2112.73 1471.97 2206.7 1254.87 2216.01 1165.73C2223.61 1116.01 2276.63 977.092 2427.97 819.154" stroke="#35A891" stroke-width="2.71812"/>
                    <path opacity="0.684564" d="M1342.63 2121.61C1366.93 2024.09 1492.07 1792.94 1798.26 1648.49C2104.45 1504.05 2205.72 1290.47 2218.09 1201.74C2227.37 1152.34 2285.08 1015.41 2441.6 862.972" stroke="#34A890" stroke-width="2.73826"/>
                    <path opacity="0.689597" d="M1313.08 2126.19C1340.69 2029.61 1473.58 1803.08 1784.22 1669.59C2094.85 1536.1 2203.3 1326.29 2218.7 1238.06C2229.67 1189.03 2291.99 1054.26 2453.48 907.487" stroke="#33A890" stroke-width="2.75839"/>
                    <path opacity="0.694631" d="M1283.22 2130.05C1314.1 2034.52 1454.55 1812.87 1769.25 1690.47C2083.95 1568.07 2199.43 1362.25 2217.84 1274.65C2230.48 1226.04 2297.33 1093.59 2463.6 952.636" stroke="#31A78F" stroke-width="2.77852"/>
                    <path opacity="0.699664" d="M1253.05 2133.18C1287.17 2038.81 1435.01 1822.29 1753.38 1711.1C2071.75 1599.9 2194.12 1398.33 2215.5 1311.44C2229.8 1263.32 2301.09 1133.33 2471.93 998.357" stroke="#30A78E" stroke-width="2.79866"/>
                    <path opacity="0.704698" d="M1222.61 2135.56C1259.93 2042.47 1414.97 1831.32 1736.61 1731.44C2058.24 1631.57 2187.34 1434.46 2211.69 1348.39C2227.62 1300.8 2303.27 1173.43 2478.45 1044.59" stroke="#2FA68E" stroke-width="2.81879"/>
                    <path opacity="0.709732" d="M1191.93 2137.18C1232.4 2045.48 1394.44 1839.95 1718.95 1751.48C2043.45 1663.01 2179.12 1470.59 2206.39 1385.44C2223.93 1338.45 2303.84 1213.83 2483.16 1091.26" stroke="#2DA68D" stroke-width="2.83893"/>
                    <path opacity="0.714765" d="M1161.04 2138.05C1204.59 2047.83 1373.45 1848.15 1700.41 1771.17C2027.38 1694.18 2169.45 1506.67 2199.61 1422.54C2218.74 1376.21 2302.81 1254.49 2486.03 1138.31" stroke="#2CA58C" stroke-width="2.85906"/>
                    <path opacity="0.719799" d="M1129.95 2138.15C1176.54 2049.53 1351.99 1855.92 1681.01 1790.48C2010.04 1725.04 2158.33 1542.66 2191.35 1459.65C2212.05 1414.01 2300.17 1295.33 2487.07 1185.68" stroke="#2BA58C" stroke-width="2.87919"/>
                    <path opacity="0.724832" d="M1098.69 2137.48C1148.27 2050.55 1330.09 1863.23 1660.77 1809.39C1991.44 1755.55 2145.77 1578.49 2181.6 1496.7C2203.84 1451.82 2295.91 1336.31 2486.26 1233.29" stroke="#29A48B" stroke-width="2.89933"/>
                    <path opacity="0.729866" d="M1067.3 2136.02C1119.8 2050.89 1307.77 1870.07 1639.69 1827.86C1971.61 1785.66 2131.78 1614.13 2170.39 1533.64C2194.14 1489.57 2290.04 1377.37 2483.6 1281.09" stroke="#28A48B" stroke-width="2.91946"/>
                    <path opacity="0.734899" d="M1035.8 2133.79C1091.15 2050.54 1285.05 1876.42 1617.8 1845.87C1950.55 1815.33 2116.38 1649.52 2157.7 1570.43C2182.94 1527.22 2282.55 1418.45 2479.08 1329.01" stroke="#27A38A" stroke-width="2.9396"/>
                    <path opacity="0.739933" d="M1004.22 2130.76C1062.36 2049.51 1261.93 1882.28 1595.11 1863.39C1928.28 1844.51 2099.56 1684.6 2143.55 1607.01C2170.24 1564.71 2273.44 1459.49 2472.71 1376.99" stroke="#26A389" stroke-width="2.95973"/>
                    <path opacity="0.744966" d="M972.575 2126.95C1033.43 2047.78 1238.45 1887.62 1571.64 1880.39C1904.83 1873.16 2081.34 1719.34 2127.95 1643.33C2156.06 1601.99 2262.73 1500.44 2464.49 1424.95" stroke="#24A289" stroke-width="2.97987"/>
                    <path opacity="0.75" d="M940.908 2122.34C1004.41 2045.34 1214.61 1892.44 1547.41 1896.84C1880.21 1901.24 2061.74 1753.68 2110.91 1679.34C2140.41 1639.01 2250.41 1541.24 2454.41 1472.84" stroke="#23A288" stroke-width="3"/>
                </svg>
                <Buttons.DarkButton onClick={() => {window.open("https://awizacja.emarba.com/", "_blank")}} style={{position: 'absolute', left: '28px', top: '31px'}} text={'AWIZACJE DOSTAW'}/>
                <div className={"Login_Window"}>
                    <img className={"Login_Window_image"} src={Logo} />
                    <div className={"Login_Window_inputs"}>
                        <Buttons.Redbutton className={"loginEnglishBt"} text={'ENGLISH'}/>
                        <InputLogin ref={this.state.LoginRef} style={{width: "360px"}} onChange={e => this.state.Login = e} text={"Login"}  className={'Login_Window_inputs_intput'} id={"test"}/>
                        <InputPassword ref={this.state.PasswordRef}  style={{width: "360px"}} onChange={e => this.state.Password = e} text={"Hasło"} types={"password"}  className={'Login_Window_inputs_intput'} />
                        <Chexbox.CheckboxBt style={{width: '82%'}} onChange={e => this.state.Auto = e.target.checked} text={'zapamiętaj mnie'}/>
                        <Buttons.DarkGreenButton onClick={this.LoginClick.bind(this)} style={{marginTop: '23px'}} text={'ZALOGUJ'}/>
                        <span onClick={() => {window.location.href = "/reset_password"}} className={"ForgotPassowordBT"}>przypomnij hasło</span>
                        <Buttons.RedLink to={"/register"} text={'REJESTRUJ'}/>
                    </div>
                    <img onClick={() => {window.open("https://mdrproject.pl/", "_blank")}} className={"Login_Window_powerby"} src={PowerBy}/>
                </div>
            </div></SimpleBar>
        )
    }

}

export default LoginPage;

