import React,{ Component } from "react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Dashboard from "./Pages/dashboard";
import TaskBar from "./Utilis/taskbar";
import "../CSS/Views/mainview.css"
import UserProfile from "./Pages/UserProfile";
import SendedDocuments from "./Pages/SendedDocuments";
import AllDocuments from "./Pages/AllDocuments";
import UsersList from "./Pages/Users";
import Authorize from "../Utilis/Authorize";
import QueueInvoices from "./Pages/QueueInvoices";

class MainPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            MobileBar: false
        }
        this.TaskBarBt = React.createRef()
        this.ScrollBar = React.createRef()
        this.onBottom = this.onScrollFunction.bind(this)
        this.veryfiySesInterval = undefined
    }

    componentDidMount() {
        this.veryfiySesInterval = setInterval(this.veryfiySes.bind(this), 300000) // 300000 
        if (this.ScrollBar.current != null) {
            this.ScrollBar.current.addEventListener('scroll', this.onBottom)
        }
    }

    componentWillUnmount() {
        if (this.veryfiySesInterval !== undefined) clearInterval(this.veryfiySesInterval)
    }

    veryfiySes() {
        Authorize.CheckAuthorize().then(data => {
            if (data !== null) {
                this.props.UserData = data
            } else {
                window.location.reload()
            }
        })    
    }

    Views() {
        switch (window.location.pathname) {
            case "/main/dashboard":
                return <Dashboard UserData={this.props.UserData}/>
            case "/main/profile":
                return <UserProfile UserData={this.props.UserData}/>
            case "/main/sended":
                return <SendedDocuments UserData={this.props.UserData} scroll={this.ScrollBar}/>
            case "/main/documents":
                return <AllDocuments scroll={this.ScrollBar}/>
            case "/main/users":
                return <UsersList scroll={this.ScrollBar}/>      
            case "/main/queue":
                return <QueueInvoices scroll={this.ScrollBar}/>      
        } 
    }

    onScrollFunction(e) {
        // console.log(e.target.scrollHeight, e.target.scrollTop , e.target.clientHeight)
        if (e.target.scrollTop > 20)
        {
            if (window.innerWidth < 1100){
                this.state.MobileBar = true 
                this.forceUpdate()
            }
        } else {
            if (this.state.MobileBar) {
                this.state.MobileBar = false 
                this.forceUpdate()
            }
        }
    }

    render() {

        return (
            <div className="MainViewBack">
                <div className={"MainViewBack_showTaskBar " + (this.state.MobileBar ? "MainViewBack_showTaskBar_onScroll" : "")}>
                    <button ref={this.TaskBarBt}>
                        <svg width="20" height="14" viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg">
                            <line y1="13" x2="20" y2="13" stroke="#23A287" stroke-width="2"/>
                            <line y1="7" x2="20" y2="7" stroke="#23A287" stroke-width="2"/>
                            <line y1="1" x2="20" y2="1" stroke="#23A287" stroke-width="2"/>
                        </svg>
                        MENU
                    </button>
                </div>
                <TaskBar btref={this.TaskBarBt} UserData={this.props.UserData}/>
                <SimpleBar scrollableNodeProps={{ ref: this.ScrollBar }} autoHide={false} style={{width: "100%", height: "99%"}}>
                    {
                        this.Views()
                    }
                </SimpleBar>
            </div>
        )

    }

}

export default MainPage;