import serverPath from "./ServerPath"
const ClientID = "c36d47bf616344c58625"


class GusApi {

    constructor() {
        this.state = {
            SessID: ""
        }
    }

    async DoLogin() {
        await fetch(
                serverPath() + "api/proxy/gus", {
                method: "POST",
                headers: new Headers({
                    "Access-Control-Allow-Origin": "*",
                    'content-type': 'application/soap+xml; harset=utf-8',
                }),
                credentials: "include",
                body: `
<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ns="http://CIS/BIR/PUBL/2014/07">
<soap:Header xmlns:wsa="http://www.w3.org/2005/08/addressing">
<wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>
<wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/Zaloguj</wsa:Action>
</soap:Header>
<soap:Body>
<ns:Zaloguj>
<ns:pKluczUzytkownika>${ClientID}</ns:pKluczUzytkownika>
</ns:Zaloguj>
</soap:Body>
</soap:Envelope>     
            `.trim()
            }   
        ).then(data => data.text()).then(data => {
            if (data !== "") {
                let dats = data.substring(data.indexOf("\r\n\r\n")).trim()
                dats = dats.substring(0, dats.indexOf("\r\n")).trim()
                let parser = new DOMParser()
                let xmlDoc = parser.parseFromString(dats,"text/xml")
                this.state.SessID = xmlDoc.getElementsByTagName("ZalogujResult")[0].childNodes[0].nodeValue
            }
        })
    }

    async DoLogout() {
        await fetch(
            serverPath() + "api/proxy/gus", {
            method: "POST",
            headers: new Headers({
                "Access-Control-Allow-Origin": "*",
                'content-type': 'application/soap+xml; harset=utf-8',
            }),
            credentials: "include",
            body: `
<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope"
xmlns:ns="http://CIS/BIR/PUBL/2014/07">
<soap:Header xmlns:wsa="http://www.w3.org/2005/08/addressing">
<wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>
<wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/Wyloguj</wsa:Action>
</soap:Header>
<soap:Body>
<ns:Wyloguj>
<ns:pIdentyfikatorSesji>${this.state.SessID}</ns:pIdentyfikatorSesji>
</ns:Wyloguj>
</soap:Body>
</soap:Envelope>
            `.trim()
            }   
        )
    }

    async DoSerach(Nip) {
        return await fetch(
            serverPath() + "api/proxy/gus", {
            method: "POST",
            headers: new Headers({
                "Access-Control-Allow-Origin": "*",
                'content-type': 'application/soap+xml; harset=utf-8',
                "sid": this.state.SessID
            }),
            credentials: "include",
            body: `
<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope"
xmlns:ns="http://CIS/BIR/PUBL/2014/07" xmlns:dat="http://CIS/BIR/PUBL/2014/07/DataContract">
<soap:Header xmlns:wsa="http://www.w3.org/2005/08/addressing">
<wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>
<wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DaneSzukajPodmioty</wsa:Action>
</soap:Header>
<soap:Body>
<ns:DaneSzukajPodmioty>
<ns:pParametryWyszukiwania>
<dat:Nip>${Nip}</dat:Nip>
</ns:pParametryWyszukiwania>
</ns:DaneSzukajPodmioty>
</soap:Body>
</soap:Envelope>
            `.trim()
            }   
        ).then(data => data.text()).then(
            data => {
                let dats = data.substring(data.indexOf("\r\n\r\n")).trim()
                dats = dats.substring(0, dats.indexOf("\r\n")).trim()
                let parser = new DOMParser()
                let xmlDoc = parser.parseFromString(dats,"text/xml")
                xmlDoc = parser.parseFromString(xmlDoc.getElementsByTagName("DaneSzukajPodmiotyResult")[0].childNodes[0].nodeValue.toString(), "text/xml")
                let output = {}
                for(let item of xmlDoc.firstElementChild.firstElementChild.children) {
                    output[item.localName] = item.innerHTML 
                }
                return output
            }
        )
    }

}

export default GusApi