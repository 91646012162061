import "../CSS/loginInputs.css"
import "../CSS/Normalnput.css"
import React,{Component} from "react";

export class InputLogin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Val: "",
            Anim: false,
            load: "",
            err: undefined,
            input: React.createRef()
        }
        this.Input = React.createRef() 
        this.state.err = this.props.err
       
    }

    componentDidMount() {
        if (this.props.Value !== undefined) this.TextChange(this.props.Value)
    }

    clearInputs() {
        this.state.input.current.value = ""
        this.state.Val = ""
        this.state.Anim = false
        this.state.load = ""
        this.state.err = undefined
        this.forceUpdate()
    }

    TextChange(val) {
        // if (val !== "") {
            this.state.Val = val
            this.state.Anim = val !== ""
            this.state.load = "InputNormal_Box_DeActive"
            this.forceUpdate()
        // }

    }

    LoadTextChange(val, event) {
        if (val !== "") this.TextChange(val) 
        if (this.props.onChange !== undefined) this.props.onChange(val)
    }

    render() {
        return (
            <div style={this.props.style} className={"InputNormal_Box LoginInputsBox " + this.props.className }>
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0021 11C11.0021 7.96243 13.4654 5.5 16.5042 5.5C19.5429 5.5 22.0062 7.96243 22.0062 11C22.0062 14.0376 19.5429 16.5 16.5042 16.5C15.0449 16.5 13.6454 15.9205 12.6136 14.8891C11.5818 13.8576 11.0021 12.4587 11.0021 11ZM27.357 25.5062L25.3763 21.5325C24.6764 20.1312 23.243 19.2469 21.6761 19.25H11.3322C9.76535 19.2469 8.33192 20.1312 7.63206 21.5325L5.65131 25.5062C5.43677 25.9318 5.45799 26.4381 5.70738 26.8443C5.95677 27.2504 6.39878 27.4986 6.87552 27.5H26.1328C26.6096 27.4986 27.0516 27.2504 27.301 26.8443C27.5503 26.4381 27.5716 25.9318 27.357 25.5062Z" fill="white"/>
                </svg>
                <label style={{left:"50px", width:"80%",color: (this.state.err !== undefined && this.state.Val !== "") ? "#D94F5B" : "white"}} className={this.state.Anim ? " InputNormal_Box_Active" : this.state.load}>{ (this.state.err !== undefined && this.state.Val !== "")? this.state.err  : this.props.text}</label>
                {
                    (this.state.Val === "" && this.state.err != undefined) && <label style={{left:"50px", width:"80%"}} className={"InputNormal_err_label"}>{this.state.err}</label>            
                }
                <input style={{left: "50px", width: "80%", color: "white"}} ref={this.state.input} type={this.props.types}
                    defaultValue={this.props.Value || ""}
                    onFocus={e => {
                        if (this.props.Blocked) e.target.blur()
                        else {
                            this.state.err = undefined
                            this.state.Anim = true
                            this.forceUpdate()
                        }
                    }}
                    onBlur={e =>  !this.props.Blocked && this.TextChange(e.target.value)}
                    onChange={e => {if (!this.props.Blocked)  this.LoadTextChange(e.target.value, e)}}
                    readOnly={(this.props.readOnly || this.state.Blocked) ? true : false }  
                    />
            </div>
        )
    }

}

export class InputPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Val: "",
            Anim: false,
            load: "",
            err: undefined,
            input: React.createRef()
        }
        this.Input = React.createRef() 
        this.state.err = this.props.err
       
    }

    componentDidMount() {
        if (this.props.Value !== undefined) this.TextChange(this.props.Value)
    }

    clearInputs() {
        this.state.input.current.value = ""
        this.state.Val = ""
        this.state.Anim = false
        this.state.load = ""
        this.state.err = undefined
        this.forceUpdate()
    }

    TextChange(val) {
        // if (val !== "") {
            this.state.Val = val
            this.state.Anim = val !== ""
            this.state.load = "InputNormal_Box_DeActive"
            this.forceUpdate()
        // }

    }

    LoadTextChange(val, event) {
        if (val !== "") this.TextChange(val) 
        if (this.props.onChange !== undefined) this.props.onChange(val)
    }

    render() {
        return (
            <div style={this.props.style} className={"InputNormal_Box LoginInputsBox " + this.props.className }>
                <svg style={{marginRight: '-2px'}}  width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9007 3.25H23.1775C22.8569 3.25142 22.5497 3.38513 22.3221 3.62231L13.5474 12.8402C12.6977 12.4412 11.7773 12.2356 10.8466 12.2368C7.13434 12.2368 4.125 15.3981 4.125 19.2978C4.125 23.1975 7.13434 26.3588 10.8466 26.3588C14.5588 26.3588 17.5681 23.1975 17.5681 19.2978C17.566 18.3259 17.3747 17.3647 17.0059 16.4734L18.0814 15.3436C18.3072 15.1045 18.4344 14.7817 18.4358 14.4449V13.5206C18.436 13.2653 18.5327 13.0206 18.7047 12.8402L19.0224 12.5064C19.1941 12.3257 19.4271 12.2241 19.6701 12.2239H21.8455C22.1829 12.2239 22.4565 11.9365 22.4565 11.582V9.32248C22.4567 9.0672 22.5534 8.82245 22.7254 8.64205H22.7865C22.8901 8.49274 23.051 8.39884 23.2264 8.38529H25.5118C25.8492 8.38529 26.1228 8.0979 26.1228 7.74338V4.53382C26.1228 3.82479 25.5756 3.25 24.9007 3.25ZM9.55112 23.2263C8.20123 23.2263 7.10692 22.0767 7.10692 20.6586C7.10692 19.2406 8.20123 18.091 9.55112 18.091C10.901 18.091 11.9953 19.2406 11.9953 20.6586C11.9953 21.3396 11.7378 21.9927 11.2794 22.4742C10.8211 22.9558 10.1994 23.2263 9.55112 23.2263Z" fill="white"/>
                </svg>
                <label style={{left:"50px", width:"80%",color: (this.state.err !== undefined && this.state.Val !== "") ? "#D94F5B" : "white"}} className={this.state.Anim ? " InputNormal_Box_Active" : this.state.load}>{ (this.state.err !== undefined && this.state.Val !== "")? this.state.err  : this.props.text}</label>
                {
                    (this.state.Val === "" && this.state.err != undefined) && <label style={{left:"50px", width:"80%"}} className={"InputNormal_err_label"}>{this.state.err}</label>            
                }
                <input style={{left: "50px", width: "80%", color: "white"}} ref={this.state.input} type={this.props.types}
                    defaultValue={this.props.Value || ""}
                    onFocus={e => {
                        if (this.props.Blocked) e.target.blur()
                        else {
                            this.state.err = undefined
                            this.state.Anim = true
                            this.forceUpdate()
                        }
                    }}
                    onBlur={e =>  !this.props.Blocked && this.TextChange(e.target.value)}
                    onChange={e => {if (!this.props.Blocked)  this.LoadTextChange(e.target.value, e)}}
                    readOnly={(this.props.readOnly || this.state.Blocked) ? true : false }  
                    />
            </div>
        )
    }

}


export default {

    LoginInput(props) {
        return <div style={props.style} className={"LoginInputsBox " + props.className}>
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0021 11C11.0021 7.96243 13.4654 5.5 16.5042 5.5C19.5429 5.5 22.0062 7.96243 22.0062 11C22.0062 14.0376 19.5429 16.5 16.5042 16.5C15.0449 16.5 13.6454 15.9205 12.6136 14.8891C11.5818 13.8576 11.0021 12.4587 11.0021 11ZM27.357 25.5062L25.3763 21.5325C24.6764 20.1312 23.243 19.2469 21.6761 19.25H11.3322C9.76535 19.2469 8.33192 20.1312 7.63206 21.5325L5.65131 25.5062C5.43677 25.9318 5.45799 26.4381 5.70738 26.8443C5.95677 27.2504 6.39878 27.4986 6.87552 27.5H26.1328C26.6096 27.4986 27.0516 27.2504 27.301 26.8443C27.5503 26.4381 27.5716 25.9318 27.357 25.5062Z" fill="white"/>
                </svg>
                <input value={props.value !== undefined ? props.value : null} onChange={props.onChange !== undefined ? props.onChange : null} type={"text"} placeholder={"Login"}/>
            </div>

    },

   PasswordInput(props) {
        return (
            <div style={props.style} className={"LoginInputsBox " + props.className}>
                <svg style={{marginRight: '-2px'}}  width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9007 3.25H23.1775C22.8569 3.25142 22.5497 3.38513 22.3221 3.62231L13.5474 12.8402C12.6977 12.4412 11.7773 12.2356 10.8466 12.2368C7.13434 12.2368 4.125 15.3981 4.125 19.2978C4.125 23.1975 7.13434 26.3588 10.8466 26.3588C14.5588 26.3588 17.5681 23.1975 17.5681 19.2978C17.566 18.3259 17.3747 17.3647 17.0059 16.4734L18.0814 15.3436C18.3072 15.1045 18.4344 14.7817 18.4358 14.4449V13.5206C18.436 13.2653 18.5327 13.0206 18.7047 12.8402L19.0224 12.5064C19.1941 12.3257 19.4271 12.2241 19.6701 12.2239H21.8455C22.1829 12.2239 22.4565 11.9365 22.4565 11.582V9.32248C22.4567 9.0672 22.5534 8.82245 22.7254 8.64205H22.7865C22.8901 8.49274 23.051 8.39884 23.2264 8.38529H25.5118C25.8492 8.38529 26.1228 8.0979 26.1228 7.74338V4.53382C26.1228 3.82479 25.5756 3.25 24.9007 3.25ZM9.55112 23.2263C8.20123 23.2263 7.10692 22.0767 7.10692 20.6586C7.10692 19.2406 8.20123 18.091 9.55112 18.091C10.901 18.091 11.9953 19.2406 11.9953 20.6586C11.9953 21.3396 11.7378 21.9927 11.2794 22.4742C10.8211 22.9558 10.1994 23.2263 9.55112 23.2263Z" fill="white"/>
                </svg>

                <input value={props.value !== undefined ? props.value : null} onChange={props.onChange !== undefined ? props.onChange : null} type={"password"} placeholder={"Hasło"}/>
            </div>
        )
    },


}