const keywords = {
    NumberInovice: [
        "nr fakury",
        "nr zamówienia",
        "numer zamówienia",
        "numer faktury",
        "nr",
    ],
    GrossAmountInovice: [],
    CurrencyInovice: [
        "EURO",
        "USD",
        "PLN",
        "EUR"
    ],
    ExhibitionDate: [
        "Data wystawienia",
        "Date of issue"
    ],
    NotionDate : [
        "Data płatności",
        "termin płatności"
    ],
}

function findData(text) {
    console.log(text)
    const result = {};

    for (const [key, wordList] of Object.entries(keywords)) {
      for (const word of wordList) {
        const regex = new RegExp(`\\b${word}\\b([^\\n]*)`, 'i');
        const match = text.match(regex);
        if (match) {
          const value = match[1].trim();
          result[key] = value;
          break;
        }
      }
    }


    console.log(result)
}

async function ReadPDf(file) {
    const pdfJS = await import('pdfjs-dist/build/pdf');
    pdfJS.GlobalWorkerOptions.workerSrc =window.location.origin + '/pdf.worker.min.js';
    const reader = new FileReader()
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      const Loadpdf = pdfJS.getDocument(new Uint8Array(reader.result));
      Loadpdf.promise.then((pdf) => {
        const pagePromises = [];
        let extractedText = '';        
        for (let i = 1; i <= pdf.numPages; i++) {
            pagePromises.push(
              pdf.getPage(i).then((page) => {
                return page.getTextContent().then((textContent) => {
                  const pageStrings = textContent.items.map((item) => item.str);
                  console.log(textContent.items)
                  extractedText += pageStrings.join(' ');
                });
              })
            );
        }
        Promise.all(pagePromises).then(() => {
            // Set the extracted text in the component state
            findData(extractedText)
        });
      })
    };
}


export {
    ReadPDf
}