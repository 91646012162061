import React,{ Component } from "react";
import "../../CSS/taskbar.css"
import marba from "../../Images/marba.png"
import power from "../../Images/img_1.png"
import { Link } from "react-router-dom";
import Buttons from "../../Inputs/Buttons";
import serverPath from "../../Utilis/ServerPath";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

class TaskBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Mobile: false,
            TouchStart: null
        }
        this.TaskBarWrap = React.createRef() 
        this.HandlClickOutside = this.ClickOutside.bind(this)
        this.HandlResize = this.onResize.bind(this)
    }

    componentDidMount() {
        if (this.props.btref !== undefined && this.props.btref.current !== null) {
            this.props.btref.current.onclick = this.ShowMobileBar.bind(this)
        }
        if (window.innerWidth < 1100) {
            document.addEventListener("mousedown", this.HandlClickOutside)
        }
        window.addEventListener("resize", this.HandlResize)
    }   

    componentWillUnmount() {
        document.removeEventListener("mouseleave", this.HandlClickOutside)
        window.removeEventListener("resize", this.HandlResize)
    }

    componentDidUpdate() {
        if (this.props.btref !== undefined && this.props.btref.current !== null) {
            this.props.btref.current.onclick = this.ShowMobileBar.bind(this)
        }
    }
    
    onTouchStart(event) {
        if (this.state.Mobile) this.state.TouchStart = event.touches[0].clientX
    }

    HandleTouch(e) {
        const touchDown = this.state.TouchStart

        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
        console.log(diff)
        if (diff > 5) {
            if (this.state.Mobile) {
                this.state.Mobile = false
                if (this.TaskBarWrap.current !== null) {
                    this.TaskBarWrap.current.classList.remove("TaskBar_open_class")
                    this.TaskBarWrap.current.classList.add("TaskBar_close_class")
                }
                this.forceUpdate()
            }
        }
    
        // if (diff < -5) {
        //     prev()
        // }
    
        this.state.TouchStart = null
    }

    onResize(event) {
        if (window.innerWidth < 1100) {
            document.addEventListener("mousedown", this.HandlClickOutside)
        } else {
            if (this.state.Mobile) {
                this.state.Mobile = false
                document.removeEventListener("mouseleave", this.HandlClickOutside)
                this.forceUpdate()
            }
            if (this.TaskBarWrap.current !== null) {
                if (this.TaskBarWrap.current.classList.contains("TaskBar_open_class")) 
                    this.TaskBarWrap.current.classList.remove("TaskBar_open_class")
                if (this.TaskBarWrap.current.classList.contains("TaskBar_close_class")) 
                    this.TaskBarWrap.current.classList.remove("TaskBar_close_class")
            }
        }
    }

    ClickOutside(event) {
        if (this.TaskBarWrap.current !== null && !this.TaskBarWrap.current.contains(event.target)) {
            if (this.state.Mobile) {
                document.removeEventListener("mouseleave", this.HandlClickOutside)
                this.state.Mobile = false
                if (this.TaskBarWrap.current !== null) {
                    this.TaskBarWrap.current.classList.remove("TaskBar_open_class")
                    this.TaskBarWrap.current.classList.add("TaskBar_close_class")
                }
                this.forceUpdate()
            }
        }
    }

    ShowMobileBar() {
        if (!this.state.Mobile) {
            this.state.Mobile = true
            setTimeout(() => document.addEventListener("mousedown", this.HandlClickOutside), 5000)
            if (this.TaskBarWrap.current !== null) {
                this.TaskBarWrap.current.classList.remove("TaskBar_close_class")
                this.TaskBarWrap.current.classList.add("TaskBar_open_class")
            }
            this.forceUpdate()
        } else {
            this.state.Mobile = false
            if (this.TaskBarWrap.current !== null) {
                this.TaskBarWrap.current.classList.remove("TaskBar_open_class")
                this.TaskBarWrap.current.classList.add("TaskBar_close_class")
                setTimeout(() => this.TaskBarWrap.current.classList.remove("TaskBar_close_class"), 5000)
            }
            this.forceUpdate()
        }
    }

    ClickClose() {
        if (this.state.Mobile) {
            this.state.Mobile = false
            if (this.TaskBarWrap.current !== null) {
                this.TaskBarWrap.current.classList.remove("TaskBar_open_class")
                this.TaskBarWrap.current.classList.add("TaskBar_close_class")
                setTimeout(() => this.TaskBarWrap.current.classList.remove("TaskBar_close_class"), 5000)
            }
            this.forceUpdate()
        }
    }

    Logout() {
        fetch(
            serverPath() + "api/logout", 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(resp => resp.json())
            .then(() => {
                window.location.href = "/"
            })
    }

    render() {
        return (
            <div ref={this.TaskBarWrap} onTouchStart={this.onTouchStart.bind(this)} onTouchMove={this.HandleTouch.bind(this)} className={"TaskBar_main "}>
                <img className="TaskBar_main_logo" src={marba}/>
                <SimpleBar style={{width: "100%", height: "70%", overflowX: "hidden"}}>
                    <ul className="TaskBar_main_selector">
                        <li className={window.location.pathname === "/main/dashboard"  && "TaskBar_main_selected"}>
                            <Link onClick={this.ClickClose.bind(this)} to={"/main/dashboard"}>Dodaj dokument</Link>
                            <div className="TaskBar_main_selector_after"></div>
                        </li>
                        <li className={window.location.pathname === "/main/profile"  && "TaskBar_main_selected"}>
                            <Link onClick={this.ClickClose.bind(this)} to={"/main/profile"}>Profil użytkownika</Link>
                            <div className="TaskBar_main_selector_after"></div>
                        </li>
                        <li className={window.location.pathname === "/main/sended"  && "TaskBar_main_selected"}>
                            <Link onClick={this.ClickClose.bind(this)} to={"/main/sended"}>Wysłane dokumenty</Link>
                            <div className="TaskBar_main_selector_after"></div>
                        </li>
                        {
                            this.props.UserData.Rank > 1 && <li className={window.location.pathname === "/main/users"  && "TaskBar_main_selected"}> 
                                <Link onClick={this.ClickClose.bind(this)} to={"/main/users"}>Użytkownicy</Link>
                                <div className="TaskBar_main_selector_after"></div>
                            </li>
                        }
                        {
                            this.props.UserData.Rank > 1 && <li className={window.location.pathname === "/main/documents"  && "TaskBar_main_selected"}>
                                <Link onClick={this.ClickClose.bind(this)} to={"/main/documents"}>Podgląd dokumentów</Link>
                                <div className="TaskBar_main_selector_after"></div>
                            </li>
                        }
                        {
                            this.props.UserData.Rank > 1 && <li className={window.location.pathname === "/main/queue"  && "TaskBar_main_selected"}>
                                <Link onClick={this.ClickClose.bind(this)} to={"/main/queue"}>Kolejka dokumentów</Link>
                                <div className="TaskBar_main_selector_after"></div>
                            </li>
                        }
                        <li>
                            <Buttons.Redbutton onClick={this.Logout.bind(this)} style={{position: "relative", left: "50%", transform: "translate(-50%, 0)", width: "80%"}} text={"WYLOGUJ"}/>
                        </li>
                    </ul>
                </SimpleBar>
                <img onClick={() => {window.open("https://mdrproject.pl/", "_blank")}} className="TaskBar_main_logomdr" src={power}/>
            </div>
        )
    }

}

export default TaskBar