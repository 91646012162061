import React,{Component} from "react"
import "../../CSS/Views/mainview.css"
import InputSearch from "../../Inputs/Search"
import Buttons from "../../Inputs/Buttons"
import InputNormal from "../../Inputs/InputNormal"
import serverPath from "../../Utilis/ServerPath"
import UAParser from "ua-parser-js"
import GusApi from "../../Utilis/SoapGUS"
import ScaleText from "react-scale-text";

class UsersList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            Mobile: false,
            Query: "",
            filtes: "",
            Search: false,
            Page: 0,
            CanLoad: false,
            binded: false,
            UserCount: "0",
            Items: [


            ],
            Raports: [

            ],
            SortType: [0,0,0,0,0]
        }

        this.loadfunc = this.LoadModrFunc.bind(this)
        this.respons = this.responsiveMode.bind(this)
        this.tableRef = React.createRef()
    }

    responsiveMode() {
        if (this.tableRef ) {
            if (this.tableRef.current.clientWidth < 810) {
                if (!this.state.Mobile) {
                    this.state.Mobile = true
                    this.forceUpdate()
                }
            } else {
                if (this.state.Mobile) {
                    this.state.Mobile = false
                    this.forceUpdate()
                }
            }  
        }

    }

    componentDidMount() {
        this.GetUsers()
        this.GetUsersCount()
        // this.GetUsersRaport()
        if (this.props.scroll !== undefined && this.props.scroll.current !== null) {
            console.log("bs")
            this.props.scroll.current.addEventListener('scroll', this.loadfunc)
            this.state.binded = true
        }
        window.addEventListener("resize", this.respons)
        this.respons()
    }

    componentWillUnmount() {
        if (this.props.scroll !== undefined && this.props.scroll.current !== null) {
            this.props.scroll.current.removeEventListener('scroll', this.loadfunc)
        }
        window.removeEventListener("resize", this.respons)
    }
 
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.binded && this.props.scroll.current !== null) {
            this.props.scroll.current.addEventListener('scroll', this.loadfunc)
            this.state.binded = true
        } 
    }

    LoadModrFunc(e) {
        if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight+30)
        { 
            if (this.state.CanLoad) {
                this.state.Page += 1
                if (!this.state.Search) this.GetUsers()
                else this.StartSearch(this.state.Query, this.state.filtes)
            }
        }
    }

    refreshTable() {
        this.setState({
            Mobile: this.state.Mobile,
            Query: "",
            filtes: "",
            Search: false,
            Page: 0,
            CanLoad: false,
            binded: false,
            UserCount: "0",
            Items: [

            ],
            Raports: [

            ],
            SortType: [0,0,0,0,0]
        })
        // this.forceUpdate()
        this.GetUsers()
        this.GetUsersCount()
        this.GetUsersRaport()
    }

    async AcceptRaport(id) {
        const resp = await fetch(
            serverPath() + "api/users/raports/accept?ID=" + id, 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)
        // console.log(resp)
        if (resp !== null && resp['CODE'] == "OK") {
            this.state.Raports = []
            this.refreshTable()
        }
    }

    async DeclineRaport(id) {
        const resp = await fetch(
            serverPath() + "api/users/raports/decline?ID=" + id, 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)
        // console.log(resp)
        if (resp !== null && resp['CODE'] == "OK") {
            // this.state.Raports = []
            this.refreshTable()
        }
    }

    async GetUsers() {
        const resp = await fetch(
            serverPath() + "api/users?page=" + this.state.Page.toString(), 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)
        // console.log(resp)
        if (resp !== null) {
            if (Object.entries(resp).length >= 25) this.state.CanLoad = true
            else this.state.CanLoad = false
            this.state.Items = this.state.Items.concat(resp)
        }
        this.forceUpdate()
        // else return false
    }


    async StartSearch(val, filtres) {
        if (val !== this.state.Query || filtres !== this.state.filtes) {
            this.state.Query = val
            this.state.filtes = filtres
            this.state.Search = false
        }
        if (!this.state.Search) {this.state.Page = 0
        this.state.CanLoad = false}
        await fetch(
            serverPath() + `api/users/search?query=${encodeURIComponent(val)}&filtres=${filtres}&page=${this.state.Page}`, 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify(this.state.Output)
            }
        ).then(data => data.json()).then(data => {
            if (!this.state.Search) this.state.Items = data
            else this.state.Items = this.state.Items.concat(data)
            this.state.Search = true
            if (Object.entries(data).length >= 25) this.state.CanLoad = true
            else this.state.CanLoad = false
            this.forceUpdate()
        })
    }

    async GetUsersRaport() {
        const resp = await fetch(
            serverPath() + "api/users/raports", 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)
        // console.log(resp)
        if (resp !== null) this.state.Raports = resp
        this.forceUpdate()
        // else return false
    }

    async GetUsersCount() {
        const resp = await fetch(
            serverPath() + "api/users/count", 
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)
        // console.log(resp)
        if (resp !== null) this.state.UserCount = resp["Users"]
        this.forceUpdate()
        // else return false
    }

    async SaveEditUser(id) {
        await fetch(
            serverPath() + "api/users/save", {
            method: "POST",
            headers: new Headers({
                "Access-Control-Allow-Origin": "*",
            }),
            credentials: "include",
            body: JSON.stringify(this.state.Items[id])
        }   
        ).then(()=> {
            this.ShowEditable(id)
        })
    }

    async RemoveUser(id) {
        await fetch(
            serverPath() + "api/users/remove", {
            method: "POST",
            headers: new Headers({
                "Access-Control-Allow-Origin": "*",
            }),
            credentials: "include",
            body: JSON.stringify({ID:this.state.Items[id].ID})
        }   
        ).then(()=> {
            this.refreshTable()
        })
    }

    RandomPassword(id) {
        const makeid = (length) => {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * 
         charactersLength));
           }
           return result;
        }
        let pass = makeid(8)
        this.state.Items[id].Password = pass
        this.state.Items[id].RPassword = pass
        this.forceUpdate()
    }

    GusUserUpdate(id) {
        const isNip = (Number) => {
            try {
                if (Number.toString().length >= 10) {
                    let x = parseInt(Number)
                    return true
                } else return false
            } catch (E) {
                return false
            }
        }
        if (isNip(this.state.Items[id].NIP)) {
            let apig = new GusApi()
            apig.DoLogin().then(
                () => {
                    apig.DoSerach(this.state.Items[id].NIP).then(
                        data => {
                        
                            if (data.Miejscowosc !== undefined && data.Miejscowosc !== "") {
                                this.state.Items[id].CompanyCity = data.Miejscowosc
                            }
                            
                            if (data.KodPocztowy !== undefined && data.KodPocztowy !== "") {
                                this.state.Items[id].CompanyZipCode = data.KodPocztowy
                            }

                            if (data.NrNieruchomosci !== undefined && data.NrNieruchomosci !== "") {
                                this.state.Items[id].CompanyRoadNumber = data.NrNieruchomosci
                            }

                            if (data.Ulica !== undefined && data.Ulica !== "") {
                                this.state.Items[id].CompanyRoad = data.Ulica

                            }

                            if (data.Nazwa !== undefined && data.Nazwa !== "") {
                                this.state.Items[id].CompanyName = data.Nazwa
                            }
                            this.forceUpdate()
                            // console.log(this.state.Company)
                            apig.DoLogout()
                        }
                    )
                }
            )
            }

    }

    SortItems(Type) {
        switch (Type) {
            case 1:            
                if (this.state.SortType[0] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.Contrachent.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Contrachent.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                            return -1;
                            }
                            if (nameA > nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[0] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.Contrachent.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Contrachent.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                            return -1;
                            }
                            if (nameA < nameB) {
                            return 1;
                            }
                        
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[0] = 1
                }
                this.forceUpdate()
                break
            case 2:
                if (this.state.SortType[1] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.Name.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Name.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }
                          
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[1] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.Name.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Name.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                              return -1;
                            }
                            if (nameA < nameB) {
                              return 1;
                            }
                          
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[1] = 1
                }
                this.forceUpdate()
                break
            case 3:
                if (this.state.SortType[4] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.Surrname.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Surrname.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[4] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.Surrname.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Surrname.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                                return -1;
                            }
                            if (nameA < nameB) {
                                return 1;
                            }
                            
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[4] = 1
                }
                this.forceUpdate()
                break
            case 4:
                if (this.state.SortType[2] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  parseFloat(b.DokumnetsCount.replaceAll(" ", "").replaceAll(",", ".")) - parseFloat(a.DokumnetsCount.replaceAll(" ", "").replaceAll(",", "."))
                    )
                    this.state.SortType[2] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => parseFloat(a.DokumnetsCount.replaceAll(" ", "").replaceAll(",", ".")) - parseFloat(b.DokumnetsCount.replaceAll(" ", "").replaceAll(",", "."))
                    )
                    this.state.SortType[2] = 1
                }
                this.forceUpdate()
                break
            case 5:
                if (this.state.SortType[3] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.LastLogged.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.LastLogged.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }
                          
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[3] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.LastLogged.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.LastLogged.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                              return -1;
                            }
                            if (nameA < nameB) {
                              return 1;
                            }
                          
                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[3] = 1
                }
                this.forceUpdate()
                break
        }
    }

    ShowEditable(id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
        .querySelectorAll("tr")
        .forEach( e => {
            if (!e.classList.contains("SendedDocuments_row_additionals_bt")) {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                } else {
                    e.style.display = "none"
                }
            }
        })
        // SendedDocuments_row_additionals_bt_editmode
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals_bt_editmode")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "block"
                } else {
                    e.style.display = "none"
                }
            })
    }

    CheckEditable(id) {
        let edit = false
        if (document.querySelector("#SendedDocuments_row_id_" + id.toString()) == null || document.querySelector("#SendedDocuments_row_id_" + id.toString()) == undefined)
            return edit
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
        .querySelectorAll("tr")
        .forEach( e => {
            if (!e.classList.contains("SendedDocuments_row_additionals_bt")) {
                if (e.style.display === "none") {
                    // e.style.display = "table-row"
                    edit = false
                    return false
                } else {
                    // e.style.display = "none"
                    edit = true
                    return true
                }
            }
        })
        // SendedDocuments_row_additionals_bt_editmode
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals_bt_editmode")
            .forEach( e => {
                if (e.style.display === "none") {
                    // e.style.display = "block"
                    edit = false
                    return false
                } else {
                    // e.style.display = "none"
                    edit = true
                    return true
                }
            })

        return edit
    }

    ShowMore(event, id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .classList.add("WorkViewNormal_selected")
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                }
            })
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals_bt")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                }
            })
    }

    ShowMoreRaport(event, id) {
        document.querySelector("#WaitingUsers_row_id_" + id.toString())
            .classList.add("WorkViewNormal_selected")
        document.querySelector("#WaitingUsers_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                }
            })
        document.querySelector("#WaitingUsers_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals_bt")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                }
            })
    }

    HideMoreRaport(event, id) {
        document.querySelector("#WaitingUsers_row_id_" + id.toString())
            .classList.remove("WorkViewNormal_selected")
        document.querySelector("#WaitingUsers_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals")
            .forEach( e => {
                if (e.style.display === "table-row") {
                    e.style.display = "none"
                }
            })
        document.querySelector("#WaitingUsers_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals_bt")
            .forEach( e => {
                if (e.style.display === "table-row") {
                    e.style.display = "none"
                }
            })
    }
    
    Hide(event,id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .classList.remove("WorkViewNormal_selected")
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll("tr")
            .forEach( e => {
                if (e.classList.length == 0) {
                    e.style.display = "table-row"
                } else {
                    if (e.style.display === "table-row") {
                        e.style.display = "none"
                    }
                }

            })
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals_bt")
            .forEach( e => {
                if (e.style.display === "table-row") {
                    e.style.display = "none"
                }
            })
        try {
            document.querySelector("#SendedDocuments_row_id_" + id.toString())
                .querySelectorAll(".SendedDocuments_row_additionals_bt_editmode")
                .forEach( (e, index) => {
                    if (index === 0 ){
                        if (e.style.display === "none") throw ""
                    }
                    if (e.style.display === "block") {
                        e.style.display = "none"
                    } else {
                        e.style.display = "block"
                    }
                })
        }
        catch (e) {

        }

    }

    RenderData() {
        const tab = []
        for (let i = 0; i < this.state.Items.length; i++) {
            tab.push(
                <tr id={"SendedDocuments_row_id_" + i.toString()} className="">
                <th style={{ padding: "0"}} colSpan={"6"}>
                    <table  style={{width: "100%"}}>
                        <tbody>
                            <tr colSpan="6">
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{textAlign: "left", paddingLeft: "11px", fontWeight: "normal", float: "left"}}>
                                            {
                                                this.state.Items[i].CompanyName
                                            }
                                        </a>
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].Name
                                            }
                                        </a>
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].Surrname
                                            }
                                        </a>                                        
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].DocumentCount
                                                
                                            }
                                        </a>                                        
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].LastLogin
                                            }
                                        </a>                                        
                                    </ScaleText>
                                </th>
                                <th onClick={e => this.ShowMore(e, i)} style={{width: '40px'}}>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_116_731)">
                                        <path d="M10.0019 10C10.0019 7.23858 12.2413 5 15.0038 5C17.7663 5 20.0057 7.23858 20.0057 10C20.0057 12.7614 17.7663 15 15.0038 15C13.6772 15 12.4049 14.4732 11.4669 13.5355C10.5289 12.5979 10.0019 11.3261 10.0019 10ZM24.87 23.1875L23.0693 19.575C22.4331 18.3011 21.13 17.4972 19.7056 17.5H10.302C8.87759 17.4972 7.57447 18.3011 6.93823 19.575L5.13755 23.1875C4.94252 23.5744 4.9618 24.0347 5.18853 24.4039C5.41525 24.7731 5.81707 24.9987 6.25047 25H23.7571C24.1905 24.9987 24.5923 24.7731 24.819 24.4039C25.0458 24.0347 25.0651 23.5744 24.87 23.1875Z" fill="#23A287"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_116_731">
                                        <rect width="30" height="30" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </th>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}} colSpan="6">
                                <td style={{paddingLeft: "11px"}} className="WorkViewNormal_selected_types" colSpan={"2"}>
                                    <ScaleText maxFontSize={16}>
                                        <b>Login:</b>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].Login
                                            }
                                        </a>
                                    </ScaleText>
                                </td>
                                <td  style={{
                                    // display: 'flex',
                                    // flexDirection: "row",
                                    // justifyContent: "flex-start"
                                }} className="WorkViewNormal_selected_types" colSpan={"1"}>
                                    <div style={{
                                        position: 'relative',
                                        display: "inline-block",
                                        textAlign: "left",
                                        width: "100px",
                                        left: "50%",
                                        transform: "translate(-50%, 0)",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <b>Adres e-mail:</b>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].Email
                                            }
                                        </a>
                                    </div>

                                </td>
                            </tr>
                            {
                                this.state.Items[i].LoginHistory.length > 0 && <tr className="SendedDocuments_row_additionals" style={{display: "none"}}>
                                <th className="WorkViewNormal_selected_files" colSpan={"6"}>
                                    <b>Logowania:</b>
                                    <div className="WorkViewNormal_selected_files_list">
                                        {
                                            (() => {
                                                const tab = []
                                                
                                                for(let d = 0; d < this.state.Items[i].LoginHistory.length; d++) {
                                                    let parser = new UAParser.UAParser()
                                                    parser.setUA(this.state.Items[i].LoginHistory[d].UserAgent)
                                                    let result = parser.getResult()
                                                    tab.push(
                                                        <div>
                                                            <span style={{textDecoration: "unset", padding: "0"}}>
                                                                {this.state.Items[i].LoginHistory[d].DATE + " "}({result.browser.name + ", " + result.os.name})
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                return tab
                                            })()
                                        }                                        
                                    </div>
                                </th>
                                </tr>
                            }
                            
                            {
                            !this.state.Mobile && <tr className="SendedDocuments_row_additionals_editable" style={{display: !this.CheckEditable(i) ? "none" : ""}}> 
                                <th style={{verticalAlign: "top", paddingTop: "20px"}} colSpan={"3"}>
                                    <InputNormal onChange={e => this.state.Items[i].Login = e} Value={this.state.Items[i].Login} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"login"}/>
                                    <InputNormal onChange={e => this.state.Items[i].Name = e} Value={this.state.Items[i].Name} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"imie"}/>
                                    <InputNormal onChange={e => this.state.Items[i].Surrname = e} Value={this.state.Items[i].Surrname} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"nazwisko"}/>
                                    <InputNormal onChange={e => this.state.Items[i].Email = e} Value={this.state.Items[i].Email} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"email"}/>
                                    <span style={
                                        {
                                            position: "relative",
                                            display: "inherit"
                                        }
                                    }>Hasło:</span>
                                    <div style={
                                        {
                                            width: "fit-content",
                                            display: "inline-block",
                                            position: "absolute",
                                            left: "70px",
                                            bottom: "0",
                                            
                                        }
                                    }>
                                        <InputNormal onChange={e => this.state.Items[i].Password = e} Value={this.state.Items[i].Password} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "250px"}} type={"password"} text={"nowe hasło"}/>
                                        <InputNormal onChange={e => this.state.Items[i].RPassword = e} Value={this.state.Items[i].RPassword}  className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "250px"}} type={"password"} text={"powtórz hasło"}/>
                                    </div>
                                    <span onClick={this.RandomPassword.bind(this, i)} style={{
                                        display: "inherit",
                                        position: "absolute",
                                        left: "320px",
                                        bottom: "50px",
                                        userSelect: "none",
                                        cursor: "pointer"
                                    }}>Generuj hasło</span>
                                </th>
                                <th style={{verticalAlign: "top", paddingTop: "20px"}} colSpan={"3"}>
                                    <InputNormal onChange={e => this.state.Items[i].NIP = e} Value={this.state.Items[i].NIP} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "180px", color: "white"}} text={"NIP"}/>
                                    <Buttons.Redbutton onClick={this.GusUserUpdate.bind(this, i)} style={
                                        {
                                            
                                            position: "absolute",
                                            left: "201px",
                                            top: "30px",
                                            width: "fit-content"
                                        }
                                    } text={"Aktualizuj dane z GUS"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyName = e} Value={this.state.Items[i].CompanyName} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"nazwa firmy"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyRoad = e} Value={this.state.Items[i].CompanyRoad} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"ulica"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyRoadNumber = e} Value={this.state.Items[i].CompanyRoadNumber} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"numer lokalu"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyCity = e} Value={this.state.Items[i].CompanyCity} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"miasto"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyZipCode = e} Value={this.state.Items[i].CompanyZipCode} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"kod pocztowy"}/>
                                </th>
                            </tr>
        }{
                            this.state.Mobile && <tr className="SendedDocuments_row_additionals_editable" style={{display: !this.CheckEditable(i) ? "none" : ""}}> 
                                <th style={{verticalAlign: "top", paddingTop: "20px"}} colSpan={"3"}>
                                    <InputNormal onChange={e => this.state.Items[i].Login = e} Value={this.state.Items[i].Login} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"login"}/>
                                    <InputNormal onChange={e => this.state.Items[i].Name = e} Value={this.state.Items[i].Name} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"imie"}/>
                                    <InputNormal onChange={e => this.state.Items[i].Surrname = e} Value={this.state.Items[i].Surrname} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"nazwisko"}/>
                                    <InputNormal onChange={e => this.state.Items[i].Email = e} Value={this.state.Items[i].Email} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"email"}/>
                                    <span style={
                                        {
                                            position: "relative",
                                            display: "inherit"
                                        }
                                    }>Hasło:</span>
                                    <div style={
                                        {
                                            width: "fit-content",
                                            display: "inline-block",
                                            position: "relative",
                                            left: "70px",
                                            bottom: "0",
                                            
                                        }
                                    }>
                                        <InputNormal onChange={e => this.state.Items[i].Password = e} Value={this.state.Items[i].Password} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "250px"}} type={"password"} text={"nowe hasło"}/>
                                        <InputNormal onChange={e => this.state.Items[i].RPassword = e} Value={this.state.Items[i].RPassword}  className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "250px"}} type={"password"} text={"powtórz hasło"}/>
                                    </div>
                                    <span onClick={this.RandomPassword.bind(this, i)} style={{
                                        display: "inherit",
                                        position: "absolute",
                                        left: "340px",
                                        bottom: "50px",
                                        userSelect: "none",
                                        cursor: "pointer"
                                    }}>Generuj hasło</span>
                                </th>
                            </tr>
                            }{
                            this.state.Mobile && <tr className="SendedDocuments_row_additionals_editable" style={{display: !this.CheckEditable(i) ? "none" : ""}}> 
                                <th style={{verticalAlign: "top", paddingTop: "20px"}} colSpan={"3"}>
                                    <InputNormal onChange={e => this.state.Items[i].NIP = e} Value={this.state.Items[i].NIP} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "180px", color: "white"}} text={"NIP"}/>
                                    <Buttons.Redbutton onClick={this.GusUserUpdate.bind(this, i)} style={
                                        {
                                            
                                            position: "absolute",
                                            left: "201px",
                                            top: "30px",
                                            width: "fit-content"
                                        }
                                    } text={"Aktualizuj dane z GUS"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyName = e} Value={this.state.Items[i].CompanyName} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"nazwa firmy"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyRoad = e} Value={this.state.Items[i].CompanyRoad} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"ulica"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyRoadNumber = e} Value={this.state.Items[i].CompanyRoadNumber} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"numer lokalu"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyCity = e} Value={this.state.Items[i].CompanyCity} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"miasto"}/>
                                    <InputNormal onChange={e => this.state.Items[i].CompanyZipCode = e} Value={this.state.Items[i].CompanyZipCode} className={"SendedDocuments_row_additionals_editable_inputs"} style={{width: "400px"}} text={"kod pocztowy"}/>
                                </th>
                            </tr>
                            }
                            <tr className="SendedDocuments_row_additionals_bt" style={{display: "none"}}>
                                <th colSpan={"6"}>
                                    <Buttons.Transparentbutton style={{
                                        float: "left",
                                        color: "white",
                                        display : "none",
                                        marginLeft: "10px",
                                    }} onClick={this.RemoveUser.bind(this, i)} className={"UserDate_transparent_empty_bt SendedDocuments_row_additionals_bt_editmode"} text={"USUŃ KONTO"}/>
                                    <button style={{ position: "relative", float:"right"}} onClick={e => this.Hide(e , i)} className="WorkViewNormal_selected_hide_bt">
                                        zwiń 
                                        <svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.0574 37.5L12.1826 37.5C11.6648 37.5 11.2451 37.0803 11.2451 36.5625L11.2451 6.24375L2.92082 14.5875C2.7448 14.765 2.5052 14.8648 2.25525 14.8648C2.00529 14.8648 1.76569 14.765 1.58967 14.5875L0.27728 13.2562C0.0998148 13.0802 -1.0098e-05 12.8406 -1.00871e-05 12.5906C-1.00762e-05 12.3407 0.0998148 12.101 0.27728 11.925L11.7701 0.412499C12.0335 0.148701 12.391 0.000327613 12.7638 -5.89064e-07L13.4762 -5.57922e-07C13.8482 0.00431948 14.2042 0.152092 14.4699 0.4125L25.9627 11.925C26.1402 12.101 26.24 12.3407 26.24 12.5906C26.24 12.8406 26.1402 13.0802 25.9627 13.2562L24.6316 14.5875C24.4588 14.7639 24.2223 14.8633 23.9754 14.8633C23.7285 14.8633 23.492 14.7639 23.3192 14.5875L14.9948 6.24375L14.9948 21.4031L14.9948 36.5625C14.9948 37.0803 14.5751 37.5 14.0574 37.5Z" fill="white"/>
                                        </svg>
                                    </button> 
                                    <Buttons.RedbuttonSave style={{
                                        marginLeft: "32px",
                                        float:"right",
                                        display: 'none'
                                    }} className={"SendedDocuments_row_additionals_bt_editmode"} onClick={this.SaveEditUser.bind(this, i)} text={"ZAPISZ"}/>
                                    <Buttons.TransparentbuttonGear style={
                                        {
                                            float: "right",
                                            textAlign: "center",
                                            padding: "unset"
                                        }
                                    }  onClick={this.ShowEditable.bind(this, i)} className={"UserDate_transparent_buttons SendedDocuments_row_additionals_bt_editmode"} text={"EDYTUJ"}/>
                                    <Buttons.TransparentbuttonClose style={
                                        {
                                            float: "right",
                                            textAlign: "center",
                                            display: 'none',
                                            paddingLeft: "30px"
                                        }
                                    }  onClick={this.ShowEditable.bind(this, i)} className={"UserDate_transparent_buttons SendedDocuments_row_additionals_bt_editmode"} text={"ZAMKNIJ"}/>
                                </th>
                            </tr>
                        </tbody>
                    </table>

                </th>
            </tr>
            )
        }

        if (tab.length == 0) {
            tab.push(<tr className="">
                <th colSpan={"5"}> 
                    <h2 style={{textAlign: "center", marginTop: "20px"}}>Brak danych</h2>
                </th>
            </tr>)
        }


        return tab
    }  

    RenderRaports() {
        const tab = []
        for(let i = 0; i < this.state.Raports.length; i++) {
            tab.push(
                <tr id={"WaitingUsers_row_id_" + i.toString()} className="">
                <th style={{transition: "1s"}} colSpan={"6"}>
                    <table tyle={{transition: "5s"}} style={{width: "100%"}}>
                        <tbody>
                            <tr colSpan="6">
                                <th>
                                    <a style={{textAlign: "left", paddingLeft: "11px", fontSize: "3vw"}}>
                                        {
                                            this.state.Raports[i].CompanyName
                                        }
                                    </a>
                                </th>
                                <th>
                                    <a>
                                        {
                                            this.state.Raports[i].Name
                                        }
                                    </a>
                                </th>
                                <th>
                                    <a>
                                        {
                                            this.state.Raports[i].Surrname
                                        }
                                    </a>
                                </th>
                                <th>
                                    <Buttons.AcceptButton onClick={this.AcceptRaport.bind(this, this.state.Raports[i].ID)} text={"AKCEPTUJ"}/>
                                </th>
                                <th>
                                    <Buttons.CancelButton onClick={this.DeclineRaport.bind(this, this.state.Raports[i].ID)} text={"ODRZUĆ"}/>
                                </th>
                                <th onClick={e => this.ShowMoreRaport(e, i)} style={{width: '40px'}}>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_116_731)">
                                        <path d="M10.0019 10C10.0019 7.23858 12.2413 5 15.0038 5C17.7663 5 20.0057 7.23858 20.0057 10C20.0057 12.7614 17.7663 15 15.0038 15C13.6772 15 12.4049 14.4732 11.4669 13.5355C10.5289 12.5979 10.0019 11.3261 10.0019 10ZM24.87 23.1875L23.0693 19.575C22.4331 18.3011 21.13 17.4972 19.7056 17.5H10.302C8.87759 17.4972 7.57447 18.3011 6.93823 19.575L5.13755 23.1875C4.94252 23.5744 4.9618 24.0347 5.18853 24.4039C5.41525 24.7731 5.81707 24.9987 6.25047 25H23.7571C24.1905 24.9987 24.5923 24.7731 24.819 24.4039C25.0458 24.0347 25.0651 23.5744 24.87 23.1875Z" fill="#23A287"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_116_731">
                                        <rect width="30" height="30" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </th>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}} colSpan="6">
                                <td style={{paddingLeft: "11px", verticalAlign: 'top'}} className="WorkViewNormal_selected_types" colSpan={"2"}>
                                    <b>Login:</b>
                                    <a>
                                        {
                                            this.state.Raports[i].Login
                                        }
                                    </a>
                                </td>
                                <td  style={{whiteSpace: "nowrap"}} className="WorkViewNormal_selected_types" colSpan={"1"}>
                                    <div style={{
                                        position: 'relative',
                                        display: "inline-block",
                                        textAlign: "left",
                                        width: "100px",
                                        left: "50%",
                                        transform: "translate(-50%, 0)",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <b>Adres e-mail:</b>
                                        <a>
                                            {
                                                this.state.Raports[i].Email
                                            }
                                        </a>
                                        <br/>
                                        <b>NIP:</b>
                                        <a>
                                            {
                                                this.state.Raports[i].NIP
                                            }
                                        </a>
                                        <br/>
                                        <b>Ulica:</b>
                                        <a>
                                            {
                                                this.state.Raports[i].CompanyRoad
                                            }
                                        </a>
                                        <br/>
                                        <b>Ulica:</b>
                                        <a>
                                            {
                                                this.state.Raports[i].CompanyCity
                                            }
                                        </a>
                                    </div>
                                    

                                </td>
                                <td style={{verticalAlign: "bottom"}}  className="WorkViewNormal_selected_types" colSpan={"3"}>
                                    <div style={{
                                        position: 'relative',
                                        display: "inline-block",
                                        textAlign: "left",
                                        width: "100px",
                                        left: "50%",
                                        transform: "translate(-50%, 0)",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <b>Ulica:</b>
                                        <a>
                                            {
                                                this.state.Raports[i].CompanyRoadNumber
                                            }
                                        </a>
                                        <br/>
                                        <b>Kod pocztowy:</b>
                                        <a>
                                            {
                                                this.state.Raports[i].CompanyZipCode
                                            }
                                        </a>
                                    </div>

                                </td>
                            </tr>
                            <tr className="SendedDocuments_row_additionals_bt" style={{display: "none"}}>
                                <th colSpan={"6"}>
                                    <button style={{ position: "relative", float:"right"}} onClick={e => this.HideMoreRaport(e , i)} className="WorkViewNormal_selected_hide_bt">
                                        zwiń 
                                        <svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.0574 37.5L12.1826 37.5C11.6648 37.5 11.2451 37.0803 11.2451 36.5625L11.2451 6.24375L2.92082 14.5875C2.7448 14.765 2.5052 14.8648 2.25525 14.8648C2.00529 14.8648 1.76569 14.765 1.58967 14.5875L0.27728 13.2562C0.0998148 13.0802 -1.0098e-05 12.8406 -1.00871e-05 12.5906C-1.00762e-05 12.3407 0.0998148 12.101 0.27728 11.925L11.7701 0.412499C12.0335 0.148701 12.391 0.000327613 12.7638 -5.89064e-07L13.4762 -5.57922e-07C13.8482 0.00431948 14.2042 0.152092 14.4699 0.4125L25.9627 11.925C26.1402 12.101 26.24 12.3407 26.24 12.5906C26.24 12.8406 26.1402 13.0802 25.9627 13.2562L24.6316 14.5875C24.4588 14.7639 24.2223 14.8633 23.9754 14.8633C23.7285 14.8633 23.492 14.7639 23.3192 14.5875L14.9948 6.24375L14.9948 21.4031L14.9948 36.5625C14.9948 37.0803 14.5751 37.5 14.0574 37.5Z" fill="white"/>
                                        </svg>
                                    </button> 
                                </th>
                            </tr>                           
                        </tbody>
                    </table>

                </th>
            </tr>
            )
        }
        return tab
    }

    render() {
        return (
            <div style={{paddingBottom: "120px"}} className="WorkViewNormal">
                <div className="WorkViewNormal_header">
                    <h1>Użytkownicy</h1>
                    <div className="DocumentSended_counters">
                        <a>{this.state.UserCount}</a>
                        <span style={{marginRight: "55px"}}>użytkowników ogółem</span>
                    </div>
                    <InputSearch Filtres={[
                        {
                            Text: "Kontrachent",
                            ID: 1
                        },
                        {
                            Text: "Imie",
                            ID: 2
                        },
                        {
                            Text: "Nazwisko",
                            ID: 2
                        },
                        {
                            Text: "E-mail",
                            ID: 3
                        }
                    ]} OnSearch={(q,f) => this.StartSearch(q,f)} className={"WorkViewNormal_header_search"} text={"Szukaj"}/>
                </div>
                {
                    this.state.Raports.length > 0 && <div style={{display: "block"}} className="UserDate_form">
                        <h2>Oczekujący</h2>
                    </div>
                }
                {   
                    this.state.Raports.length > 0 && <table style={{marginTop: "0"}}>
                        <thead>
                            <tr>
                                <th onClick={this.SortItems.bind(this,1)}>
                                    <ScaleText>
                                        <a style={{textAlign: "left", paddingLeft: '30px'}}>Kontrachent
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_95_457)">
                                                <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </ScaleText>
                                </th>
                                <th onClick={this.SortItems.bind(this,2)}>
                                    <ScaleText>
                                        <a>
                                            Imie
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_95_457)">
                                                <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>    
                                        </a>                                    
                                    </ScaleText>
                                </th>
                                <th onClick={this.SortItems.bind(this,3)}>
                                    <ScaleText>
                                        <a>
                                            Nazwisko
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_95_457)">
                                                <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>    
                                        </a>
                                    </ScaleText>
                                </th>
                                <th></th>
                                <th></th>
                                <th style={{width: "40px"}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.RenderRaports()
                            }
                        </tbody>
                    </table>
                }
                <table ref={this.tableRef}>
                    <thead>
                        <tr>
                            <th onClick={this.SortItems.bind(this,1)}>
                                <ScaleText maxFontSize={16}>
                                    <a style={{textAlign: "left", paddingLeft: '30px'}}>Kontrachent
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </ScaleText>
                            </th>
                            <th onClick={this.SortItems.bind(this,2)}>
                                <ScaleText maxFontSize={16}>
                                    <a>
                                        Imie
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>    
                                    </a>
                                </ScaleText>
                            </th>
                            <th onClick={this.SortItems.bind(this,3)}>
                                <ScaleText maxFontSize={16}>
                                    <a>
                                        Nazwisko
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>    
                                    </a>
                                </ScaleText>
                            </th>
                            <th onClick={this.SortItems.bind(this,4)}>
                                <ScaleText maxFontSize={16}> 
                                    <a>
                                        Dokumeny
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </ScaleText>
                            </th>
                            <th onClick={this.SortItems.bind(this,5)}>
                                <ScaleText maxFontSize={16}>
                                    <a>
                                        Ostatnio zalogowany
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_95_457">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </ScaleText>

                            </th>
                            <th style={{width: "40px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.RenderData()
                        }
                    </tbody>
                </table>
            </div>
        )
    }

}


export default UsersList