import React,{ Component } from "react";
import "../CSS/DocFiles.css"
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { ReadPDf } from "../Utilis/ReadPDF";

class DocFIles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dragEv: false,
            files: [],
            fileinput: null
        }
        this.state.fileinput = React.createRef()
        // this.props = props
    }  

    async SendFiles(path) {

        const form = new FormData()
        for (let i = 0; i < this.state.files.length; i++) {
            form.append(this.state.files[i].name, this.state.files[i])
        }

        const resp = await fetch(path, {
            method:"POST",
            // mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin':'*'
                // 'Content-Type': 'multipart/form-data'
            },
            body: form,
            credentials: 'include'
        }).then(data => data.json()).catch(null)

        return resp
    }

    ondrag(e) {
        // e.preventDefault();
        this.state.dragEv = true
        this.forceUpdate()
    }

    ondraglev(e) {
        // e.preventDefault();
        this.state.dragEv = false
        this.forceUpdate()
    }

    ondrop(e) {
        e.preventDefault()
        this.state.dragEv = false
        this.state.files.push(...e.dataTransfer.files)
        ReadPDf(e.dataTransfer.files[0])
        this.forceUpdate()
    }

    onAddFile(e) {
        e.preventDefault();
        this.state.dragEv = false
        this.state.files.push(...e.target.files)
        ReadPDf(e.target.files[0])
        this.forceUpdate()
    }

    removeFromFiles(index) {
        this.state.files.splice(index, 1)
        this.forceUpdate()
    }

    RenderFiles() {
        const tab = []
        if (this.state.files.length > 0) {
            for(let i = 0; i < this.state.files.length; i++)
            tab.push(
                <div className="DocFiles_main_list_item">
                    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.6303 25.3C13.6766 28.24 8.89922 28.24 5.94544 25.3C3.01819 22.3718 3.01819 17.6282 5.94544 14.7L16.5678 4.14998C18.7874 1.94936 22.3681 1.94936 24.5877 4.14998C25.6514 5.19973 26.25 6.63126 26.25 8.12498C26.25 9.6187 25.6514 11.0502 24.5877 12.1L14.8412 21.775C14.1359 22.4888 13.1739 22.8905 12.17 22.8905C11.1661 22.8905 10.204 22.4888 9.49873 21.775C8.792 21.0713 8.39475 20.1155 8.39475 19.1187C8.39475 18.1219 8.792 17.1661 9.49873 16.4625L15.6795 10.3375C15.7969 10.2192 15.9568 10.1526 16.1236 10.1526C16.2904 10.1526 16.4503 10.2192 16.5678 10.3375L17.2434 11C17.3618 11.1173 17.4285 11.2771 17.4285 11.4437C17.4285 11.6104 17.3618 11.7701 17.2434 11.8875L11.0627 18.0125C10.5398 18.6396 10.5831 19.5619 11.1624 20.1374C11.7417 20.7128 12.665 20.7509 13.2897 20.225L23.0363 10.5625C23.685 9.91942 24.0498 9.04422 24.0498 8.13123C24.0498 7.21824 23.685 6.34304 23.0363 5.69998C21.6796 4.35248 19.4884 4.35248 18.1317 5.69998L7.48436 16.25C5.41644 18.3231 5.41644 21.6768 7.48436 23.75C9.58637 25.8098 12.9519 25.8098 15.0539 23.75L22.1229 16.7375C22.372 16.4896 22.7748 16.4896 23.0238 16.7375L23.6869 17.4C23.8079 17.5134 23.8765 17.6717 23.8765 17.8375C23.8765 18.0032 23.8079 18.1616 23.6869 18.275L16.6303 25.3Z"/>
                    </svg>
                    <a style={{marginLeft: "30px"}}>{this.state.files[i].name.substr(0, this.state.files[i].name.lastIndexOf('.') + 1)}</a>
                    <a>.{this.state.files[i].name.substr(this.state.files[i].name.lastIndexOf('.') + 1, this.state.files[i].name.length)}</a>
                    <svg width="30" height="30" viewBox="0 0 30 30" onClick={this.removeFromFiles.bind(this, i)} className="DocFiles_main_list_removebt" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_145_965)">
                        <path d="M25 5.625V6.875C25 7.22018 24.7202 7.5 24.375 7.5H5.625C5.27982 7.5 5 7.22018 5 6.875V5.625C5 5.27982 5.27982 5 5.625 5H11.25V3.75C11.25 3.05964 11.8096 2.5 12.5 2.5H17.5C18.1904 2.5 18.75 3.05964 18.75 3.75V5H24.375C24.7202 5 25 5.27982 25 5.625ZM7.3375 25.175C7.42956 26.4869 8.52234 27.5032 9.8375 27.5H20.1875C21.5027 27.5032 22.5954 26.4869 22.6875 25.175L23.75 10H6.25L7.3375 25.175Z" fill="#D94F5B"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_145_965">
                        <rect width="30" height="30" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        }
        return tab
    }

    OpenFileBrowser() {
        this.state.fileinput.current.click()
    }

    render() {
        return (
            <div style={this.props.style} 
                    onDragEnter={() => {this.ondrag()}} 
                    onDragOver={() => {this.ondrag()}} 
                    onDragLeave={() => {this.ondraglev()}} 
                    // onDrop={e => this.ondrop(e)}
                    onClick={()=>{this.state.files.length === 0 && this.OpenFileBrowser()}}
                    className={"DocFiles_main " + (this.state.dragEv ? "DocFiles_main_drag" : "")}>
                {
                    this.state.dragEv && <div onDrop={e => this.ondrop(e)} className="DocFiles_main_area"><input type={"file"} multiple onChange={e => {this.onAddFile(e)}}/></div>
                }
                
                {
                    this.state.files.length === 0 && <div className="DocFiles_main_start">
                        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="46" cy="46" r="46" fill="#23A287"/>
                            <rect x="37" y="16" width="20" height="60" fill="white"/>
                            <rect x="77" y="36" width="20" height="60" transform="rotate(90 77 36)" fill="white"/>
                        </svg>
                        <b>dodaj dokument</b>
                        <br></br>
                        <a>(lub przeciągnij tutaj)</a>
                    </div>
                }
                {
                    this.state.files.length > 0 && <SimpleBar autoHide={false} style={{width: "100%", height: "93%", zIndex: '2'}}><div className="DocFiles_main_list">{this.RenderFiles()}</div></SimpleBar>
                }
                {
                    this.state.files.length > 0 && <div onClick={this.OpenFileBrowser.bind(this)} className="DocFiles_main_next">                        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="46" cy="46" r="46" fill="#23A287"/>
                            <rect x="37" y="16" width="20" height="60" fill="white"/>
                            <rect x="77" y="36" width="20" height="60" transform="rotate(90 77 36)" fill="white"/>
                        </svg>
                    <a>dodaj kolejne (lub przeciągnij)</a></div>
                }
                <input ref={this.state.fileinput} type={"file"} multiple onChange={e => {this.onAddFile(e)}}/>
            </div>
        )
    }

}

export default DocFIles;