import "../CSS/Normalnput.css"
import React,{Component} from "react";

class StaticInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Val: "",
            Anim: true,
            load: "InputNormal_Box_DeActive",
            err: undefined,
        }
        this.state.err = this.props.err
       
    }

    TextChange(val) {
        this.state.Val = val
        this.state.Anim = val !== ""
        this.state.load = "InputNormal_Box_DeActive"
        this.forceUpdate()
    }

    LoadTextChange(val) {
        if (val !== "") this.TextChange(val)
    }

    render() {
        return (
            <div style={this.props.style} className={"InputNormal_Box " + this.props.className }>
                <label className={this.state.Anim ? " InputNormal_Box_Active" : this.state.load}>{ this.state.err !== undefined ? this.state.err  : this.props.text}</label>
                {
                    (this.state.Val === "" && this.state.err != undefined) && <label className={"InputNormal_err_label"}>{this.state.err}</label>            
                }
                <input type={this.props.types}
                    // onFocus={() => {
                    //     this.state.err = undefined
                    //     this.state.Anim = true
                    //     this.forceUpdate()
                    // }}
                    // onBlur={e => this.TextChange(e.target.value)}
                    // onChange={e => this.LoadTextChange(e.target.value)}
                    readOnly={true}
                    value={this.props.Val}
                    />
            </div>
        )
    }

}

export default StaticInput