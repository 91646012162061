import {BrowserRouter, Routes, Route} from "react-router-dom";
import LoginPage from "./View/LoginPage";
import RegisterPage from "./View/RegisterPage";
import MainPage from "./View/MainPage";
import ForgotPassword from "./View/forgotPassword";
import { ProtectedRoute } from "./Utilis/ProtectRoute";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={'/'} element={<LoginPage/>} />
        <Route path={'/register'} element={<RegisterPage/>} />
        <Route path={'/reset_password'} element={<ForgotPassword/>} />
        <Route path={'/main'} exact>
          <Route path="dashboard" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
          <Route path="profile" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
          <Route path="sended" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
          <Route path="documents" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
          <Route path="users" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
          <Route path="queue" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
